import { GridColDef } from "@mui/x-data-grid-pro-v5";
import { ChecboxCell } from "../table-cells/checkbox-cell";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  editable: false,
  align: "center",
  headerAlign: "center",
};

export const getSubtierOnboardingFormTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "supplier_title",
      headerName: "Subtier",
      align: "left",
      headerAlign: "left",
    },
    {
      ...defaultColumnConfig,
      field: "is_upply",
      headerName: "Isupply",
      renderCell: ChecboxCell,
    },
    {
      ...defaultColumnConfig,
      field: "am9000",
      headerName: "Revision",
      renderCell: ChecboxCell,
    },
    {
      ...defaultColumnConfig,
      field: "emcm",
      headerName: "A2EMM-001 (EMCM)",
      renderCell: ChecboxCell,
    },
    {
      ...defaultColumnConfig,
      field: "aqpl",
      headerName: "AQPL Airbus.com",
      renderCell: ChecboxCell,
    },
    {
      ...defaultColumnConfig,
      field: "A220asl",
      headerName: "A220 ASL Airbus.com",
      renderCell: ChecboxCell,
    },
    {
      ...defaultColumnConfig,
      field: "normaster",
      headerName: "Normaster",
      renderCell: ChecboxCell,
    },
  ];

  return columns;
};

import { Box, Button, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

export const StyledTitle = styled(CustomTypography)({
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "rgba(0, 0, 0, 0.87)",
});

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "128px",
  height: "40px",
  borderRadius: "8px",
  backgroundColor: `${theme.palette.background.paper}`,
  border: "1px solid rgba(0, 0, 0, 0.25)",
  color: `${theme.palette.text.primary}`,
  "&:hover": {
    backgroundColor: "rgba(245, 245, 245, 1)",
  },
}));

export const StyledButtonTypography = styled(CustomTypography)({
  lineHeight: "20px",
  letterSpacing: "0.25px",
});

import React from "react";
import { Link } from "@mui/material";
import * as S from "./form-field-label.styled";

type Props = {
  label: string;
  linklabel?: string;
  link?: string;
};

export const FormFieldLabel: React.FC<Props> = ({ label, linklabel, link }) => {
  return (
    <S.StyledTypography variant="body1">
      {label}
      {linklabel ? (
        <>
          {" "}
          <Link
            href={link}
            target="_blank"
            rel="noopener"
            variant="body1"
            underline="none"
          >
            {linklabel}
          </Link>
          {"?"}
        </>
      ) : null}
    </S.StyledTypography>
  );
};

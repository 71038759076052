import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { A2psProcessControlForm } from "../forms/a2ps-process-control-form/a2ps-process-control-form";
import { DesignAndBuiltForm } from "../forms/design-and-built-form/design-and-built-form";
import { IdentificationForm } from "../forms/identification-form/identification-form";
import { LeadTimeForImplementationForm } from "../forms/lead-time-for-implementation-form/lead-time-for-implementation-form";
import { OnboardingForm } from "../forms/onboarding-form/onboarding-form";
import { ScopeForm } from "../forms/scope-form/scope-form";
import { SubtiersOnboardingForm } from "../forms/subtiers-onboarding-form/subtiers-onboarding-form";
import { SubtiersForm } from "../forms/subtiers-form/subtiers-form";
import { ApprovalForm } from "../forms/approval-form/approval-form";
import { LoadingScreen } from "../loading-screen/loading-screen";
import { ErrorScreen } from "../error-screen/error-screen";
import {
  selectActiveFormStepIndex,
  selectFormDataError,
  selectFormDataLoading,
  selectSteps,
} from "../../redux/selectors";
import {
  PosEndUsersQuestionnaireForms,
  vendorManagementActions,
} from "../../redux";

const formKeyMapping = {
  Identification: "Identification",
  Approval: "Approval",
  Onboarding: "Onboarding",
  Scope: "Scope",
  A2psProcessControl: "A2PS's process control",
  LeadTimeForImplementation: "Lead time for implementation",
  DesignAndBuilt: "Design and built",
  Subtiers: "Subtiers",
  SubtiersOnboarding: "Subtiers onboarding",
};

const availableForms = {
  Identification: IdentificationForm,
  Approval: ApprovalForm,
  Onboarding: OnboardingForm,
  Scope: ScopeForm,
  "A2PS's process control": A2psProcessControlForm,
  "Lead time for implementation": LeadTimeForImplementationForm,
  "Design and built": DesignAndBuiltForm,
  Subtiers: SubtiersForm,
  "Subtiers onboarding": SubtiersOnboardingForm,
};

export const PosEndUsersQuestionnaire: React.FC = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const requestFormDataLoading = useSelector(selectFormDataLoading);
  const requestFormDataError = useSelector(selectFormDataError);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const steps = useSelector(selectSteps);

  const { forms, formNames } = useMemo(() => {
    const visibleForms: React.ComponentType<any>[] = [];
    const visibleFormNames: PosEndUsersQuestionnaireForms[] = [];

    Object.entries(formKeyMapping).forEach(([enumKey, formKey]) => {
      const step = steps.find((step) => step.step_name === formKey);
      if (step?.is_visible) {
        visibleForms.push(availableForms[formKey]);
        visibleFormNames.push(
          PosEndUsersQuestionnaireForms[
            enumKey as keyof typeof PosEndUsersQuestionnaireForms
          ]
        );
      }
    });

    return { forms: visibleForms, formNames: visibleFormNames };
  }, [steps]);

  const ActiveFormComponent = forms[activeFormStepIndex];

  useEffect(() => {
    if (companyId) {
      dispatch(
        vendorManagementActions.fetchFormDataRequest({
          formName: formNames[activeFormStepIndex],
          companyId: companyId,
        })
      );
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(false)
      );
    }
  }, [activeFormStepIndex, companyId, dispatch]);

  if (requestFormDataError) {
    return <ErrorScreen message={requestFormDataError.message} />;
  }

  return (
    <>
      {requestFormDataLoading ? (
        <LoadingScreen />
      ) : ActiveFormComponent ? (
        <ActiveFormComponent />
      ) : null}
    </>
  );
};

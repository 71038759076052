import { enqueueSnackbar } from "notistack";
import { Questionnaire } from "../redux";

export const showIncompleteStepsMessageForScopeForm = (
  questionnaireName: Questionnaire,
  completedSteps: { [k: number]: boolean },
  stepIndex: number
) => {
  let message = null;
  if (questionnaireName === Questionnaire.PosEndUsers) {
    if (completedSteps[stepIndex + 1] && completedSteps[stepIndex + 2]) {
      message =
        "Due to this change, 'A2PS's process control' and 'Lead time for implementation' steps are now incomplete.";
    } else if (completedSteps[stepIndex + 1]) {
      message =
        "Due to this change, 'A2PS's process control' step is now incomplete.";
    } else if (completedSteps[stepIndex + 2]) {
      message =
        "Due to this change, 'Lead time for implementation' step is now incomplete.";
    }
  } else if (questionnaireName === Questionnaire.PomManufacturer) {
    if (completedSteps[stepIndex + 1] && completedSteps[stepIndex + 2]) {
      message =
        "Due to this change, 'Lead time for implementation' and 'Lead time for production' steps are now incomplete.";
    } else if (completedSteps[stepIndex + 1]) {
      message =
        "Due to this change, 'Lead time for implementation' step is now incomplete.";
    } else if (completedSteps[stepIndex + 2]) {
      message =
        "Due to this change, 'Lead time for production' step is now incomplete.";
    }
  } else if (questionnaireName === Questionnaire.PomDistributor) {
    if (completedSteps[stepIndex + 1] && completedSteps[stepIndex + 2]) {
      message =
        "Due to this change, 'Lead time for implementation' and 'Buffer stock strategy' steps are now incomplete.";
    } else if (completedSteps[stepIndex + 1]) {
      message =
        "Due to this change, 'Lead time for implementation' step is now incomplete.";
    } else if (completedSteps[stepIndex + 2]) {
      message =
        "Due to this change, 'Buffer stock strategy' step is now incomplete.";
    }
  }

  return enqueueSnackbar(message, {
    variant: "warning",
    autoHideDuration: 5000,
  });
};

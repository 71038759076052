import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalsActions } from "@next/redux/modalsSlices";
import { Field, Form, Formik } from "formik";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CustomButton from "@next/components/custom-button";
import * as yup from "yup";
import { GenericTextInput } from "components/utils/input/text";
import { workspaceNextActions } from "../redux";
import { selectInviteSupplierContactLoading } from "../redux/selectors";
import { GenericUser } from "@next/modules/profile/redux";
import { InviteSupplierContactModalData } from "@next/modals/shared-invite-supplier-contact-modal";
import { SharedModalTypes } from "@next/modals/types";
import { LANGUAGE } from "@next/hooks/useLanguageToggle";

export const InviteSupplierContactForm: React.FC<
  InviteSupplierContactModalData
> = ({ supplierId, onSuccess, email }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(selectInviteSupplierContactLoading);

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        workspaceNextActions.inviteSupplierContactRequest({
          ...values,
          supplierId,
          onSuccess: (user: GenericUser) => {
            onSuccess(user);
            dispatch(
              modalsActions.closeModal(
                SharedModalTypes.INVITE_SUPPLIER_CONTACT_MODAL
              )
            );
          },
        })
      );
    },
    [dispatch, supplierId, onSuccess]
  );

  return (
    <Formik
      validationSchema={yup.object().shape({
        first_name: yup.string().required("thisFieldIsRequired"),
        last_name: yup.string().required("thisFieldIsRequired"),
        language: yup
          .string()
          .oneOf(Object.values(LANGUAGE), "Invalid language")
          .required("thisFieldIsRequired"),
        email: yup
          .string()
          .email(t("workspaceNext:rfqDrawer:error:invalidEmail"))
          .required(t("common:error:thisFieldIsRequired")),
      })}
      initialValues={{ email, first_name: "", last_name: "", language: "" }}
      onSubmit={onSubmit}
    >
      {({ touched, errors }) => (
        <Form>
          <Box mb={2}>
            <Typography variant="body1">
              {t("workspaceNext:supplierContactsList:inviteModal:content")}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GenericTextInput
                name="email"
                label={t(
                  "workspaceNext:supplierContactsList:inviteModal:email"
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <GenericTextInput
                name="first_name"
                label={t(
                  "workspaceNext:supplierContactsList:inviteModal:firstName"
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <GenericTextInput
                name="last_name"
                label={t(
                  "workspaceNext:supplierContactsList:inviteModal:lastName"
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                error={touched.language && Boolean(errors.language)}
              >
                <InputLabel id="invite-contact-language-label">
                  {t("workspaceNext:supplierContactsList:inviteModal:language")}
                </InputLabel>
                <Field
                  as={Select}
                  name="language"
                  labelId="invite-contact-language-label"
                  label={t(
                    "workspaceNext:supplierContactsList:inviteModal:language"
                  )}
                  id="select-release-announcement"
                >
                  {Object.entries(LANGUAGE).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Field>
                {touched.language && errors.language && (
                  <FormHelperText>{errors.language}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                loading={loading}
                type="submit"
                color="primary"
                variant="contained"
                style={{ width: "100%" }}
              >
                {t("workspaceNext:supplierContactsList:inviteModal:submit")}
              </CustomButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

import { ScopeTableRow } from "../redux";

export const validateScopeTableRows = (rows: ScopeTableRow[]): boolean => {
  if (!rows || rows.length === 0) {
    return false;
  }
  /* return rows.every(
    (row) =>
      row.sourceOfSupply &&
      row.sourceOfSupply.length > 0 &&
      row.manufacturingSite &&
      row.manufacturingSite.length > 0
  ); */
  return true; // TO-DO: fix here after api is ready for other PomDistributor questionnaire
};

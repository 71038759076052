import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PosEndUsersQuestionnaire } from "./pos-end-users-questionnaire";
import { PomManufacturerQuestionnaire } from "./pom-manufacturer-questionnaire";
import { PomDistributorQuestionnaire } from "./pom-distributor-questionnaire";
import { ThankYouModal } from "../modals/thank-you-modal/thank-you-modal";
import {
  selectActiveFormStepIndex,
  selectCompletionStatusesOfSteps,
  selectIsAllStepsCompleted,
  selectQuestionnaireName,
} from "../../redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import {
  Questionnaire,
  QuestionnaireForms,
  VendorManagementModalTypes,
} from "../../redux";
import { useParams } from "react-router-dom";

export const Questionnaries: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const questionnaire = useSelector(selectQuestionnaireName);
  const completedSteps = useSelector(selectCompletionStatusesOfSteps);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const formNames = Object.values(QuestionnaireForms);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);

  useEffect(() => {
    if (isAllStepsCompleted) {
      dispatch(
        modalsActions.showModal({
          key: VendorManagementModalTypes.THANK_YOU_MODAL,
        })
      );
    }
  }, [completedSteps]);

  return (
    <>
      {questionnaire === Questionnaire.PosEndUsers ? (
        <PosEndUsersQuestionnaire />
      ) : null}
      {questionnaire === Questionnaire.PomManufacturer ? (
        <PomManufacturerQuestionnaire />
      ) : null}
      {questionnaire === Questionnaire.PomDistributor ? (
        <PomDistributorQuestionnaire />
      ) : null}

      <ThankYouModal />
    </>
  );
};

import { BufferStockStrategyTableRow } from "../redux";

export const validateBufferStockStrategyTableRows = (
  rows: BufferStockStrategyTableRow[]
): boolean => {
  return rows.every(
    (row) =>
      row.a0xStock !== undefined &&
      row.a0xStock !== null &&
      row.b0xStock !== undefined &&
      row.b0xStock !== null
  );
};

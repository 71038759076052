import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import { v4 as uuidv4 } from "uuid";
import { SelectDateMenu } from "../table-menu/select-date-menu/select-date-menu";
import { SelectOptionMenu } from "../table-menu/select-option-menu/select-option-menu";
import {
  SpecOfSubtier,
  Step,
  SubtierTableRow,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectActiveFormStepIndex,
  selectIsFormCompleted,
  selectIsThereUnsavedChangesInFormStep,
  selectSpecsOfSubtierSupplier,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  getSubtierReadinessFormTableColumns,
  selectComplianceMenuItems,
} from "./subtier-readiness-form-table.columns";
import { formatDate } from "helpers/formatDate";
import * as S from "./subtier-readiness-form-table.styled";

type Props = {
  subtierSupplierId: string;
};

export const SubtierReadinessFormTable: React.FC<Props> = ({
  subtierSupplierId,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const steps = useSelector(selectSteps);
  const columns = getSubtierReadinessFormTableColumns();
  const rows = useSelector(selectSpecsOfSubtierSupplier(subtierSupplierId));
  const [anchorElForDateMenu, setAnchorElForDateMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElForOptionMenu, setAnchorElForOptionMenu] =
    useState<null | HTMLElement>(null);
  const [row, setRow] = useState<SubtierTableRow>(null);
  const [field, setField] = useState<string>("");

  const handleCellClick = (
    params: GridCellParams,
    event: React.MouseEvent<HTMLElement>
  ) => {
    console.log("params.field", params.field);
    if (params.field === "poe" && params.row.compliance) {
      setRow(params.row);
      setField(params.field);
      setAnchorElForDateMenu(event.currentTarget);
    } else if (params.field === "compliance") {
      setRow(params.row);
      setField(params.field);
      setAnchorElForOptionMenu(event.currentTarget);
    } else if (params.field === "removeRowButton") {
      // TO-DO: fix here to remove the row
      const id = params.row.id;
      dispatch(
        vendorManagementActions.deleteSpecOfSubtierSupplierRequest({
          companyId: companyId,
          supplierId: subtierSupplierId,
          specificationId: id,
        })
      );

      //checkInputChange();
      return;
    }
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[activeFormStepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  const handleClickComplianceOption = (menuItem: string) => {
    const supplierId = subtierSupplierId;
    const specification_id = row.specification_id;

    const spec: SpecOfSubtier = { compliance: menuItem };

    dispatch(
      vendorManagementActions.updateSpecOfSubtierSupplierRequest({
        spec: spec,
        companyId: companyId,
        supplierId: supplierId,
        specificationId: specification_id,
      })
    );
    checkInputChange();
    setAnchorElForOptionMenu(null);
  };

  const handleClickDateSelection = (date: Date | null) => {
    const formattedDate = formatDate(date, "YYYY-MM-DD");
    const supplierId = subtierSupplierId;
    const specification_id = row.specification_id;

    const spec: SpecOfSubtier = { poe: formattedDate };

    dispatch(
      vendorManagementActions.updateSpecOfSubtierSupplierRequest({
        spec: spec,
        companyId: companyId,
        supplierId: supplierId,
        specificationId: specification_id,
      })
    );
    checkInputChange();
    setAnchorElForDateMenu(null);
  };

  return (
    <>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows}
        autoHeight
        autoPageSize
        hideFooter
        headerHeight={48}
        rowHeight={32}
        disableSelectionOnClick
        onCellClick={handleCellClick}
        getRowId={(row) => row.specification_id || uuidv4()}
      />

      <SelectOptionMenu
        anchorEl={anchorElForOptionMenu}
        setAnchorEl={setAnchorElForOptionMenu}
        handleClick={handleClickComplianceOption}
        row={row}
        field={field}
        menuItems={selectComplianceMenuItems}
      />

      <SelectDateMenu
        anchorEl={anchorElForDateMenu}
        row={row}
        setAnchorEl={setAnchorElForDateMenu}
        handleChange={handleClickDateSelection}
      />
    </>
  );
};

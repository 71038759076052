import React, { useState } from "react";
import { FormControl, FormControlLabel, Radio, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  selectActiveFormStepIndex,
  selectFormData,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectProcessControlStandards,
  selectSpecsOfScope,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { AddAirbusSpecificationField } from "./add-airbus-specification-field/add-airbus-specification-field";
import { AddInternationalStandardField } from "./add-international-standard-field/add-international-standard-field";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormTitle } from "../../form-title/form-title";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldLabel } from "../../form-field-label/form-field-label";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import {
  A2psProcessControlFormData,
  FieldMandatoryText,
  QuestionnaireForms,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { validateAirbusSpecTableRows } from "@next/modules/vendor-management/utils/validateAirbusSpecTableRows";
import { validateInternationalStandardTableRows } from "@next/modules/vendor-management/utils/validateInternationalStandardTableRows";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import * as S from "./a2ps-process-control-form.styled";

export const A2psProcessControlForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const steps = useSelector(selectSteps);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const a2psProcessControlFormData = useSelector(
    selectFormData
  ) as A2psProcessControlFormData;
  const tableData = useSelector(selectSpecsOfScope);
  const airbusSpecFieldTableRows = tableData?.results || [];
  const internalStandardFieldTableRows = useSelector(
    selectProcessControlStandards
  );
  const [isRenderOptionalFields, setIsRenderOptionalFields] = useState<boolean>(
    a2psProcessControlFormData?.has_captive_laboratory || false
  );
  const [isShowTableErrorsIfExist, setIsShowTableErrorsIfExist] =
    useState<boolean>(false);
  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setError,
  } = useForm<A2psProcessControlFormData>({
    defaultValues: a2psProcessControlFormData,
  });

  const handleRadioBtnChange = (value: boolean) => {
    checkInputChange();

    if (value) {
      setIsRenderOptionalFields(true);
    } else {
      setIsRenderOptionalFields(false);
    }
  };

  const saveChanges = (isForCompletion: boolean) => {
    const formValues = watch();
    dispatch(
      vendorManagementActions.updateFormDataRequest({
        data: formValues,
        formName: QuestionnaireForms.A2psProcessControl,
        companyId: companyId,
      })
    );

    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true);
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<A2psProcessControlFormData> = (
    data
  ) => {
    const isHaveCaptiveLaboratory = data.has_captive_laboratory;

    if (isHaveCaptiveLaboratory) {
      const isAirbusTableValidated = validateAirbusSpecTableRows(
        airbusSpecFieldTableRows as any
      );
      const isInternalStandardTableValidated =
        validateInternationalStandardTableRows(internalStandardFieldTableRows);
      if (isAirbusTableValidated && isInternalStandardTableValidated) {
        makeFormStepCompleteAndShowMessage();
      } else {
        if (!isShowTableErrorsIfExist) {
          setIsShowTableErrorsIfExist(true);
        }
        showInvalidMessage();
      }
    } else if (isHaveCaptiveLaboratory === false) {
      makeFormStepCompleteAndShowMessage();
    } else {
      setError("has_captive_laboratory", { type: "manual" });
      showInvalidMessage();
    }
  };

  const makeFormStepCompleteAndShowMessage = () => {
    saveChanges(true);
    showCompletedMessage();
    dispatch(
      vendorManagementActions.setRouteNextStep({
        isLastStep: isLastStep,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[activeFormStepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle
            title={"A2PS's process control"}
            isCompleted={isFormCompleted}
          />

          <S.StyledInnerBox>
            <FormControl
              fullWidth
              margin="none"
              error={errors.has_captive_laboratory ? true : false}
            >
              <FormFieldBox>
                <FormFieldLabel label="Do you have a captive laboratory to support A2PS's process control or qualification testing, including tests performed in production?" />

                <Controller
                  name="has_captive_laboratory"
                  control={control}
                  render={({ field }) => (
                    <>
                      <S.StyledRadioGroup
                        {...field}
                        value={field?.value === null ? "" : field.value}
                        onChange={(e) => {
                          const value =
                            e.target.value === "true" ? true : false;
                          field.onChange(value);
                          handleRadioBtnChange(value);
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </S.StyledRadioGroup>
                      {errors.has_captive_laboratory ? (
                        <FormFieldErrorTypography text={FieldMandatoryText} />
                      ) : null}
                    </>
                  )}
                />
              </FormFieldBox>
            </FormControl>

            {isRenderOptionalFields ? (
              <>
                <AddAirbusSpecificationField
                  isShowTableErrorsIfExist={isShowTableErrorsIfExist}
                />

                <AddInternationalStandardField
                  isShowTableErrorsIfExist={isShowTableErrorsIfExist}
                />
              </>
            ) : null}
          </S.StyledInnerBox>

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={
                a2psProcessControlFormData?.additional_comment || ""
              }
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};

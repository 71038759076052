import React from "react";

import {
  Box,
  Button,
  Chip,
  SvgIconProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridColumnHeaderParams,
  GridColumns,
  GridRenderCellParams,
  getGridStringOperators,
} from "@mui/x-data-grid-pro-v5";
import { t } from "assets/configi18n/i18n";
import { OrderField } from "@next/modules/project/components/order/types";
import { BuyerRFQSTableField } from "./buyer-rfqs-table.types";
import { IconText } from "@next/components/icon-text";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { AppTheme } from "layout/AppTheme";
import InfoIcon from "@mui/icons-material/Info";
import { grey } from "@mui/material/colors";
import { StateStatusTag } from "@next/components/state-status-tag";
import TextCell from "@next/components/table-cells/text-cell-v5";
import UsersCell from "@next/components/table-cells/users-cell-v5";
import LeadTimeCell from "@next/components/table-cells/lead-time-cell-v5";
import PurchaseOrderCell from "@next/components/table-cells/purchase-order-cell-v5";
import BRPExportCell from "@next/components/table-cells/brp-export-cell-v5";
import RatingCell from "@next/components/table-cells/rating-cell-v5";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";
import { formatDate } from "helpers/formatDate";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import { ProgressCircle } from "@next/components/progress-circle";
import { Link } from "react-router-dom";
import { frontendUrl } from "urls";
import { checkIsBRPCompany } from "@next/utils/checkCompanyUtils";
import { getCompany } from "services/profile/profile.selectors";
import { store } from "store";
import CustomButton from "@next/components/custom-button/custom-button";
import CheckIcon from "@mui/icons-material/Check";
import { modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "@next/modules/orders/modals/types";
import { projectActions } from "@next/modules/project/redux";
import { DUMMY_ORDER_STATUS } from "@next/constants";
import { InternalNoteCell } from "@next/components/table-cells/internal-note-cell";

const defaultColumnConfig = {
  sortable: true,
  hideSortIcons: false,
  filterable: true,
};

export const NameCell: React.FC<
  GridRenderCellParams & {
    showQuestionsCount?: boolean;
    lastSelectedRow: string | number | undefined;
  }
> = (params) => {
  const showLastOpened = params?.lastSelectedRow === params.id;

  return (
    <Box
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent={"space-between"}
      style={{ width: "100%" }}
    >
      <Box display="flex" alignItems={"center"} style={{ width: "80%" }}>
        <TextCell {...params} />

        {showLastOpened ? (
          <Box ml="10px">
            <Typography variant="caption" color="textSecondary">
              {t("project:table:row:lastOpened")}
            </Typography>
          </Box>
        ) : null}
      </Box>

      {params?.showQuestionsCount && params?.row?.unseen_questions_count ? (
        <Box>
          <Chip
            size="small"
            label={params?.row?.unseen_questions_count}
            icon={<ChatBubbleRoundedIcon style={{ color: "white" }} />}
            style={{
              backgroundColor: AppTheme.palette.primary.main,
              color: "white",
              cursor: "pointer",
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export const StateStatusCell: React.FC<GridRenderCellParams> = (params) => {
  return params?.row?.status === DUMMY_ORDER_STATUS ? null : (
    <StateStatusTag
      cancellationReason={params?.row?.cancellation_reason}
      state={params?.row?.state}
      stateInfo={params?.row?.state_info}
      status={params?.row?.status}
      quotationsCount={params?.row?.quotations_count}
      onHoldNote={params?.row?.on_hold_note}
    />
  );
};

export const IconTextMap: {
  [key in BuyerRFQSTableField]?: React.ComponentType<SvgIconProps>;
} = {
  [BuyerRFQSTableField.VIEWS]: VisibilityOutlinedIcon,
  [BuyerRFQSTableField.QUOTATION_IN_PROGRESS]: WatchLaterOutlinedIcon,
  [BuyerRFQSTableField.QUOTES]: CheckOutlinedIcon,
  [BuyerRFQSTableField.CREATED_ON]: EventAvailableOutlinedIcon,
  [BuyerRFQSTableField.DEADLINE]: FlagOutlinedIcon,
};

export const IconTextCell: React.FC<
  GridRenderCellParams & { tooltipText?: string }
> = (params: GridRenderCellParams & { tooltipText?: string }) => {
  return (
    <IconText
      Icon={IconTextMap[params?.field as BuyerRFQSTableField]}
      text={params?.value as string}
    />
  );
};

export const HeaderWithInfo: React.FC<
  GridColumnHeaderParams & { infoText: string }
> = (params) => {
  return (
    <Box display={"flex"} alignItems="center">
      <Typography variant="body2" style={{ fontWeight: 700 }}>
        {params?.colDef?.headerName}
      </Typography>
      <Tooltip title={params.infoText || ""}>
        <InfoIcon
          style={{
            marginLeft: 5,
            width: 15,
            height: 15,
            color: grey[500],
          }}
        />
      </Tooltip>
    </Box>
  );
};

export const SupplierAwardedCell: React.FC<GridRenderCellParams> = (params) => {
  return (
    <Typography variant="caption">
      {!!params.value ? params.value : "-"}
    </Typography>
  );
};

export const CreateOrderCell: React.FC<GridRenderCellParams> = (params) => {
  if (params?.row?.purchase_order?.pk) {
    const order = params?.row?.purchase_order as {
      pk: number;
      name: string;
    };

    return (
      <Link to={`${frontendUrl.orders}/${order?.pk}`} target="_blank">
        {order?.name}
      </Link>
    );
  } else {
    const onClick = () => {
      store.dispatch(
        modalsActions.showModal({
          key: OrdersModalTypes.CREATE_ORDER_MODAL,
          data: {
            rfqPk: params?.row?.pk,
            rfqName: params?.row?.rfq_display_name,
          },
        })
      );
    };

    //prevent row click if this cell is clicked
    const onSpanClick = (e: React.SyntheticEvent) => e.stopPropagation();

    return (
      <span onClick={onSpanClick}>
        <Button variant="outlined" onClick={onClick}>
          Create
        </Button>
      </span>
    );
  }
};

export const MarkAsCompleteCell: React.FC<GridRenderCellParams> = (params) => {
  const isCompleted = params.getValue(
    params.id,
    "is_contract_completed"
  ) as boolean;

  const onClick = () => {
    store.dispatch(
      projectActions.updateRFQRequest({
        pk: params?.row?.pk,
        is_contract_completed: !isCompleted,
        isContract: true,
        refetchData: true,
      })
    );
  };

  //prevent row click if this cell is clicked
  const onSpanClick = (e: React.SyntheticEvent) => e.stopPropagation();

  return (
    <span onClick={onSpanClick}>
      <CustomButton
        size="small"
        style={
          isCompleted
            ? {
                color: AppTheme.palette.success.main,
                borderColor: AppTheme.palette.success.main,
              }
            : undefined
        }
        startIcon={<CheckIcon />}
        variant="outlined"
        onClick={onClick}
      >
        {t(
          `contract:${
            params?.row?.is_contract_completed
              ? "markAsUncomplete"
              : "markAsComplete"
          }`
        )}
      </CustomButton>
    </span>
  );
};

export const ProcessingTimeCell: React.FC<GridRenderCellParams> = (params) => {
  return <Typography variant="caption">{params.value as string}</Typography>;
};

export const SuppliersCell: React.FC<GridRenderCellParams> = (params) => {
  const targetedSuppliers = params.value as Record<string, any>[];
  const marketplaceSuppliers = (params.row.marketplace_suppliers ||
    []) as Record<string, any>[];
  const suppliers = [...targetedSuppliers, ...marketplaceSuppliers];

  return (
    <CircularAvatarsList
      size="small"
      images={suppliers.map((item, ix) => ({
        name: item.name,
        url: item.picture,
        tooltip: item.name,
      }))}
    />
  );
};

export const ResponsesCell: React.FC<GridRenderCellParams> = (params) => {
  const itnbCount = params.row.intent_to_not_bid_count as number;
  const quotationsCount = params.row.quotations_count as number;
  const totalResponses = itnbCount + quotationsCount;
  const totalTargetedSuppliers = (
    params.row.targeted_suppliers as Array<Record<string, any>>
  ).length;
  const totalMarketplaceSuppliers = (
    (params.row.marketplace_suppliers || []) as Array<Record<string, any>>
  ).length;
  const totalSuppliers = totalTargetedSuppliers + totalMarketplaceSuppliers;

  return (
    <Tooltip
      title={
        <Box textAlign="center" whiteSpace="pre-line">
          {`${t("workspaceNext:buyerRfqs:quotes", {
            count: quotationsCount,
            context: quotationsCount,
          })}\n${t("workspaceNext:buyerRfqs:noQuotes", {
            count: itnbCount,
            context: itnbCount.toString(),
          })}`}
        </Box>
      }
    >
      <Box display="flex" alignItems="center" lineHeight={0}>
        <ProgressCircle
          value={
            totalSuppliers === 0
              ? 0
              : Math.round((totalResponses / totalSuppliers) * 100)
          }
        />
        <Box ml={1} mt="2px">
          <Typography variant="caption">
            {`${totalResponses}/${totalSuppliers}`}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export const PartsStatusProgress: React.FC<{
  totalParts: number;
  quotedParts: number;
}> = ({ totalParts, quotedParts }) => {
  const notQuotedParts = totalParts - quotedParts;

  return (
    <Tooltip
      title={
        <Box textAlign="center" whiteSpace="pre-line">
          {`${t("workspaceNext:buyerRfqs:partsWithQuote", {
            count: quotedParts,
          })}\n${t("workspaceNext:buyerRfqs:partsWithoutQuote", {
            count: notQuotedParts,
          })}`}
        </Box>
      }
    >
      <Box display="flex" alignItems="center" lineHeight={0}>
        <ProgressCircle
          value={
            totalParts === 0 ? 0 : Math.round((quotedParts / totalParts) * 100)
          }
        />
        <Box ml={1} mt="2px">
          <Typography variant="caption">
            {`${quotedParts}/${totalParts}`}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export const PartsStatusCell: React.FC<GridRenderCellParams> = (params) => {
  const totalParts = Number(params.row.parts_count || 0);
  const quotedParts = Number(params.row.quoted_parts_count || 0);

  if (params.row.is_no_quote_detected === false)
    return <Typography variant="caption">{totalParts}</Typography>;

  return (
    <PartsStatusProgress totalParts={totalParts} quotedParts={quotedParts} />
  );
};

export const getBuyerRFQSTableColumns = (
  hasViewerRole: boolean,
  isContractQuery: boolean,
  isOrderManagementEnabled?: boolean,
  isCancelledQuery?: boolean,
  lastSelectedRow?: string | number | undefined,
  isRatingColumnHide?: boolean,
  moduleType?: string
) => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.RFQ_DISPLAY_NAME,
      minWidth: isContractQuery ? 180 : 200,
      headerName: t("workspaceNext:buyerRfqs:table:header:name"),
      renderCell: (params) => (
        <NameCell
          {...params}
          showQuestionsCount={true}
          lastSelectedRow={lastSelectedRow}
        />
      ),
      type: "string",
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.USERS,
      width: 110,
      headerName: t("workspaceNext:buyerRfqs:table:header:users"),
      renderCell: UsersCell,
      headerAlign: "center",
      type: "string",
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.CREATED_ON,
      width: 140,
      headerName: t("workspaceNext:buyerRfqs:table:header:createdOn"),
      valueGetter: (params) => formatDate(params.value, "MMM D YYYY"),
      renderCell: IconTextCell,
      type: "date",
      hide: isContractQuery,
    },
    {
      ...defaultColumnConfig,
      field: isCancelledQuery
        ? BuyerRFQSTableField.CANCELLATION_DATE
        : isContractQuery
        ? BuyerRFQSTableField.AWARDED_DATE
        : BuyerRFQSTableField.DEADLINE,
      width: isContractQuery ? 160 : 130,
      headerName: t(
        isCancelledQuery
          ? "workspaceNext:buyerRfqs:table:header:cancelledOn"
          : isContractQuery
          ? "workspaceNext:buyerRfqs:table:header:awardedOn"
          : "workspaceNext:buyerRfqs:table:header:quotationDeadline"
      ),
      valueGetter: (params) => {
        return params.value ? formatDate(params.value, "MMM D YYYY") : null;
      },
      renderCell: IconTextCell,
      type: "date",
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.PROCESSING_TIME,
      width: 180,
      align: "center",
      headerAlign: "center",
      headerName: t("workspaceNext:buyerRfqs:table:header:processingTime"),
      renderCell: ProcessingTimeCell,
      renderHeader: (props) => (
        <HeaderWithInfo
          {...props}
          infoText={t("workspaceNext:buyerRfqs:table:header:workingDays")}
        />
      ),
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hide: isContractQuery,
    },

    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.SUPPLIERS,
      width: isContractQuery ? 180 : 120,
      headerName: t("workspaceNext:buyerRfqs:table:header:suppliers"),
      renderCell: SuppliersCell,
      sortable: false,
      hideSortIcons: true,
      type: "string",
      filterOperators: getGridStringOperators().filter(
        (item) => item.value === "contains"
      ),
      hide: !!hasViewerRole || isContractQuery,
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.INTERNAL_NOTE,
      minWidth: 270,
      headerName: t("workspaceNext:buyerRfqs:table:header:note"),
      renderCell: (params: any) =>
        InternalNoteCell({
          notes: params?.row?.notes,
          internal_note_description: params?.row?.internal_note_description,
          paramID: params?.id,
          moduleType,
        }),
      filterOperators: getGridStringOperators().filter(
        (item) => item.value === "contains"
      ),
      sortable: false,
      hideSortIcons: true,
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.AWARDED_SUPPLIER,
      width: 180,
      headerName: t("workspaceNext:buyerRfqs:table:header:supplierAwarded"),
      sortable: false,
      hideSortIcons: true,
      renderCell: SupplierAwardedCell,
      type: "string",
      filterOperators: getGridStringOperators().filter(
        (item) => item.value === "contains"
      ),
      hide: !isContractQuery,
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.RESPONSES,
      width: 135,
      headerName: t("workspaceNext:buyerRfqs:table:header:responses"),
      renderCell: ResponsesCell,
      renderHeader: (props) => (
        <HeaderWithInfo
          {...props}
          infoText={t("workspaceNext:buyerRfqs:table:header:quotesAndNoQuotes")}
        />
      ),
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hide: isContractQuery,
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.CREATE_ORDER,
      headerName: t("workspaceNext:buyerRfqs:table:header:orderTracking"),
      width: 180,
      renderCell: CreateOrderCell,
      hide: !isContractQuery || !isOrderManagementEnabled,
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.COST_AND_LEAD_TIME,
      width: 180,
      align: "left",
      headerAlign: "left",
      headerName: t(
        "workspaceNext:sellerContracts:table:header:costAndLeadTime"
      ),
      renderCell: (params) => <LeadTimeCell {...params} />,
      hide: !isContractQuery,
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.PURCHASE_ORDER,
      align: "left",
      headerAlign: "left",
      headerName: t("workspaceNext:sellerContracts:table:header:purchaseOrder"),
      renderCell: PurchaseOrderCell,
      hide: !isContractQuery || isOrderManagementEnabled,
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.SUPPLIER_SELECTION_EXPORT,
      width: 120,
      align: "center",
      headerAlign: "center",
      headerName: t("workspaceNext:sellerContracts:table:header:quoteData"),
      renderCell: BRPExportCell,
      hide:
        !isContractQuery || !checkIsBRPCompany(getCompany(store.getState()).id),
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.PARTS_STATUS,
      width: 135,
      headerName: t("workspaceNext:buyerRfqs:table:header:parts"),
      renderCell: PartsStatusCell,
      renderHeader: (props) => (
        <HeaderWithInfo
          {...props}
          infoText={t(
            "workspaceNext:buyerRfqs:table:header:partsStatusTooltip"
          )}
        />
      ),
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hide: isContractQuery,
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.STATE_AND_STATUS,
      width: isContractQuery ? 180 : 180,
      headerName: t("workspaceNext:buyerRfqs:table:header:status"),
      renderCell: StateStatusCell,
      sortable: false,
      hideSortIcons: true,
      filterOperators: getGridStringOperators().filter(
        (item) => item.value === "equals"
      ),
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.RATING,
      width: 120,
      align: "center",
      headerAlign: "center",
      headerName: t("workspaceNext:sellerContracts:table:header:rating"),
      renderCell: (params) => <RatingCell {...params} size="small" />,
      hide: !isContractQuery || isRatingColumnHide,
    },
    {
      ...defaultColumnConfig,
      field: BuyerRFQSTableField.MARK_AS_COMPLETE,
      width: 180,
      align: "left",
      headerName: " ",
      renderCell: MarkAsCompleteCell,
      hide: !isContractQuery,
    },
    {
      ...defaultColumnConfig,
      field: OrderField.EDIT_BUTTON,
      headerName: " ",
      width: 40,
      align: "center",
      renderCell: IconButtonCell,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hide: !!hasViewerRole || isContractQuery,
    },
  ];

  return _columns;
};

import React, { useCallback, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import excelIcon from "@next/assets/excelIcon.png";
import folderIcon from "@next/assets/folderIcon.png";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import { Box, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MultipleDNDFileUpload from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload";
import CustomButton from "@next/components/custom-button/custom-button";
import { modalsActions } from "@next/redux/modalsSlices";
import { useDispatch, useSelector } from "react-redux";
import JSZip from "jszip";
import { fileStateToZip } from "../bulk-file-import/bulk-file-import-upload-files.utils";
import {
  selectProjectCompanyPartsCount,
  selectProjectCompanyPartsSelectAll,
  selectProjectStats,
  selectProjectTemplates,
  selectSelectedPartRows,
} from "../../redux/selectors";
import {
  ProjectCompanyPart,
  ProjectTemplate,
  projectActions,
} from "../../redux";
import { ProjectModalTypes } from "../../modals/types";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { RfqDataToPartsModal } from "@next/modules/workspace/modals/rfq-add-filesandparts-modal/rfq-add-filesandparts-modal";
import TemplateSelector from "./template-selector";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import PartsAutoCompleteDropdown from "@next/modules/workspace/components/rfq-creation-form/parts-autocomplete-dropdown";
import { Form, Formik } from "formik";
import { FormikAutoSaver } from "@next/components/formik-auto-saver/formik-auto-saver";
import { workspaceActions } from "services/workspace/workspace.actions";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    bodyRoot: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    actionsContainer: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    fileUploadContainer: {
      display: "flex",
      gap: theme.spacing(3),
      alignItems: "center",
      width: "100%",
    },
  })
);

type Props = {
  onClickAddRow: () => void;
  onAddFile: (file: any) => void;
  rfqData: RfqDataToPartsModal;
  onAddPart: (part: ProjectCompanyPart) => void;
  isProjectEmpty: boolean;
  isRFQEditMode?: boolean;
};

export const UpperSectionFromRfqCreation: React.FC<Props> = ({
  onClickAddRow,
  onAddFile,
  rfqData,
  onAddPart,
  isProjectEmpty,
  isRFQEditMode,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectStats = useSelector(selectProjectStats);
  const selectedPartsRows = useSelector(selectSelectedPartRows);
  const projectCompanyPartsCount = useSelector(selectProjectCompanyPartsCount);
  const projectCompanyPartsSelectAll = useSelector(
    selectProjectCompanyPartsSelectAll
  );
  const projectTemplates = useSelector(selectProjectTemplates);
  const [bulkFiles, setBulkFiles] = useState<any[]>([]);
  const [fileItem, setFileItem] = useState<any[]>([]);

  const selectedPartCount = projectCompanyPartsSelectAll
    ? projectCompanyPartsCount
    : selectedPartsRows?.length;

  const filesZip = new JSZip();

  const handleBulkFilesImport = (bulkFiles: any) => {
    const files = bulkFiles.map((file) => file?.file);
    fileStateToZip(filesZip, files, (zippedFile: any) => {
      if (projectStats?.pk) {
        dispatch(projectActions.resetBomFilesUploadReport());
        dispatch(
          projectActions.uploadBomFilesRequest({
            file: zippedFile,
            projectId: projectStats?.pk,
            attach_unmatched_files: true,
          })
        );

        dispatch(
          modalsActions.showModal({
            key: ProjectModalTypes.BULK_FILE_IMPORT_MODAL,
            data: {
              filesCount: files?.length,
              onAttachmentsChange: rfqData?.onAttachmentsChange,
            },
          })
        );

        setBulkFiles([]);
      }
    });
  };

  const handleFileUpload = (files: any[]) => {
    onAddFile(files[0]?.file);
    setFileItem([]);
  };

  const onClickDelete = () => {
    confirmDialog(
      t(
        "workspaceNext:rfqDrawer:partsAndFilesModal:deleteConfirmationModal:title",
        {
          count: selectedPartCount,
        }
      ) + "?",
      t(
        "workspaceNext:rfqDrawer:partsAndFilesModal:deleteConfirmationModal:content"
      ),
      () => {
        if (selectedPartsRows.length > 0) {
          const ids = selectedPartsRows?.map((item) => item.pk);

          dispatch(
            projectActions.bulkDeleteProjectPartRequest({
              partIds: projectCompanyPartsSelectAll ? [] : (ids as number[]),
              selectAll: projectCompanyPartsSelectAll,
              projectCode: projectStats?.code,
              skipSuccessToast: true,
            })
          );
        }
      },
      t("common:delete"),
      "delete"
    );
  };

  const handleTemplateChange = useCallback(
    (template: ProjectTemplate) => {
      if (projectStats?.code) {
        dispatch(projectActions.updateProjectTemplateInState(template));
        dispatch(
          projectActions.updateProjectTemplateRequest({
            projectCode: projectStats.code,
            template: template.id,
          })
        );
      }
    },
    [projectStats?.code]
  );

  const onExport = useCallback(() => {
    dispatch(
      workspaceNextActions.exportMyPartsRequest({ code: projectStats?.code })
    );
  }, [projectStats?.code]);

  const onSubmit = useCallback(
    (values: { parts?: ProjectCompanyPart }) => {
      const { parts: part } = values;
      const updateRFQEditPart = (part) =>
        dispatch(workspaceActions.addRFQPartforEdit(part));
      if (!part) return;

      dispatch(
        projectActions.cloneProjectPartRequest({
          partPk: Number(part.pk),
          projectId: projectStats?.pk,
          skipSuccessToast: true,
          isRFQEditMode: isRFQEditMode,
          hanldeRFQEditCallBack: updateRFQEditPart,
        })
      );

      onAddPart(part);
    },
    [onAddPart]
  );

  return (
    <Box className={classes.bodyRoot}>
      {!isRFQEditMode && (
        <Typography>
          {t("workspaceNext:rfqDrawer:partsAndFilesModal:subtitle")}
        </Typography>
      )}
      <Box className={classes.actionsContainer}>
        {projectTemplates.length > 0 && !!projectStats && (
          <TemplateSelector
            value={projectStats?.template as ProjectTemplate}
            onChange={handleTemplateChange}
            options={projectTemplates}
            projectCode={projectStats?.code || ""}
            disabled={!isProjectEmpty}
            showDescription={false}
            showImportBtns={false}
            addMargins={false}
            customWidth={255}
          />
        )}
        <Box sx={{ width: "255px" }}>
          <Formik<{ parts?: ProjectCompanyPart }>
            initialValues={{ parts: undefined }}
            onSubmit={onSubmit}
          >
            <Form>
              <PartsAutoCompleteDropdown
                hideCreatePartButton={true}
                fromPM={false}
                multiple={false}
                templateId={projectStats?.template?.id}
              />
              <FormikAutoSaver clearFormAfterSubmission />
            </Form>
          </Formik>
        </Box>
        <CustomButton
          startIcon={<AddIcon />}
          variant="outlined"
          size="large"
          onClick={onClickAddRow}
        >
          <Typography variant="body2">
            {t("workspaceNext:rfqDrawer:partsAndFilesModal:addRow")}
          </Typography>
        </CustomButton>
        {!isRFQEditMode && (
          <CustomButton
            startIcon={<ArrowDownwardIcon />}
            size="large"
            onClick={onExport}
          >
            <Typography variant="body2">
              {t(
                "workspaceNext:rfqDrawer:partsAndFilesModal:downloadEmptyTemplate"
              )}
            </Typography>
          </CustomButton>
        )}
      </Box>
      {!isRFQEditMode && (
        <Box className={classes.fileUploadContainer}>
          <MultipleDNDFileUpload
            filesState={fileItem}
            setFilesState={(files) => handleFileUpload(files)}
            compactMode={true}
            disableDndMargin={true}
            showUploadList={false}
            showSecureInfo={false}
            multiple={false}
            fileFormats={["xlsx", "xls"]}
            centerInnnerContent={false}
            customText={t(
              "workspaceNext:rfqDrawer:partsAndFilesModal:supportedFiles"
            )}
            customStartIcon={excelIcon}
          />
          <MultipleDNDFileUpload
            filesState={bulkFiles}
            setFilesState={(files) => handleBulkFilesImport(files)}
            compactMode={true}
            disableDndMargin={true}
            showUploadList={false}
            showSecureInfo={false}
            centerInnnerContent={false}
            customText={t(
              "workspaceNext:rfqDrawer:partsAndFilesModal:fileSubText"
            )}
            customStartIcon={folderIcon}
          />
        </Box>
      )}
      {selectedPartsRows.length > 0 && (
        <Box sx={{ display: "flex" }}>
          <CustomButton
            variant="contained"
            color="error"
            size="large"
            onClick={onClickDelete}
          >
            <Typography variant="body2">
              {t(
                "workspaceNext:rfqDrawer:partsAndFilesModal:deleteConfirmationModal:title",
                {
                  count: selectedPartCount,
                }
              )}
            </Typography>
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

import React from "react";
import { Avatar, Box } from "@mui/material";
import AirbusLogo from "../../../../../assets/airbus-logo-light.png";
import * as S from "./thank-you-modal-body.styled";

const titleText = "Thank you for completing this questionnaire";
const bodyText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. " +
  "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. " +
  "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. " +
  "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

export const ThankYouModalBody: React.FC = () => {
  return (
    <S.StyledBox>
      <S.StyledCircleBox>
        <S.StyledCheckIcon />
      </S.StyledCircleBox>

      <S.StyledTitleTypography variant="h5">
        {titleText}
      </S.StyledTitleTypography>

      <S.BodyTypography variant="body1">{bodyText}</S.BodyTypography>

      <Box>
        <Avatar
          sx={{ width: "100%", height: "22px" }}
          src={AirbusLogo}
          variant="square"
        />
      </Box>
    </S.StyledBox>
  );
};

import React from "react";
import { Box, Link, Theme, Tooltip, Typography } from "@mui/material";
import { AvatarWithStatus } from "@next/components/avatar-with-status";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import SupplierContactsList from "../supplier-contacts-list/supplier-contacts-list";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import SupplierStatus from "../supplier-status/supplier-status";
import { workspaceNextActions } from "../../redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { useDispatch, useSelector } from "react-redux";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { selectUpdateRFQTargetProfilesLoading } from "../../redux/selectors";
import { GenericUser } from "@next/modules/profile/redux";
import { SharedModalTypes } from "@next/modals/types";
import { createStyles, makeStyles } from "@mui/styles";

interface StylesProps {
  trimName?: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) =>
  createStyles({
    supplierRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    supplierWithAvatar: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
      width: "100%",
      maxWidth: (props) => (props.trimName ? "18rem" : "100%"),
    },
    supplierDetail: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    ellipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "left",
      width: "100%",
    },
    name: {
      width: "min-content",
    },
  })
);

type SupplierWithContactsListProps = {
  supplier: any;
  showContactsList?: boolean;
  trimName?: boolean;
};

export const SupplierWithContactsList: React.FC<
  SupplierWithContactsListProps
> = ({ supplier, showContactsList, trimName }) => {
  const axyaTracking = useAxyaTracking();
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const dispatch = useDispatch();
  const classes = useStyles({
    trimName,
  });
  const rfqDetails = useSelector(getRfqDetails);
  const supplierName =
    trimName && supplier?.name?.length > 25
      ? `${supplier?.name?.slice(0, 25)}...`
      : supplier?.name;

  const onClickOpenSupplierProfile = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    openProfileInNewTab(supplier?.name, supplier?.uuid);
    axyaTracking({
      app: "axya-app",
      scout_category: "axya-supplier-profile",
      scout_feature_name:
        "supplier-profile-redirection-from-target-supplier-list",
    });
  };
  const handleUpdateRfqSupplierContactsList = (
    target_profiles: GenericUser[]
  ) => {
    const target_profiles_ids = target_profiles.map((item) => item.id);
    dispatch(
      workspaceNextActions.updateRFQTargetedProfilesRequest({
        rfqId: rfqDetails.pk,
        target_profiles: target_profiles_ids as number[],
        supplierId: supplier?.id,
        onSuccess: () => {
          dispatch(
            modalsActions.closeModal(
              SharedModalTypes.SUPPLIER_CONTACTS_LIST_MODAL
            )
          );
        },
      })
    );
  };

  return (
    <Box className={classes.supplierRow}>
      <Box className={classes.supplierWithAvatar}>
        <AvatarWithStatus
          isOnline={supplier?.is_online}
          avatarProps={{ src: supplier?.picture, variant: "rounded" }}
        />
        <Box className={classes.supplierDetail}>
          <Tooltip title={supplier?.name || ""}>
            <Typography variant="body1" className={classes.name}>
              <Link
                onClick={hasViewerRole ? undefined : onClickOpenSupplierProfile}
                component="button"
                color="secondary"
                className={classes.ellipsis}
                style={{ cursor: hasViewerRole ? "default" : "pointer" }}
              >
                {supplierName}
              </Link>
            </Typography>
          </Tooltip>
          <SupplierStatus statusInfo={supplier?.status_info} />
        </Box>
      </Box>

      <Box width="100%" display="flex" justifyContent="flex-end">
        {showContactsList ? (
          <SupplierContactsList
            supplier={supplier}
            users={supplier?.target_profiles}
            editMode
            onValidate={handleUpdateRfqSupplierContactsList}
            loadingSelector={selectUpdateRFQTargetProfilesLoading}
          />
        ) : null}
      </Box>
    </Box>
  );
};

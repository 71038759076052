import { Autocomplete, Box, Popper, TextField, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledAutoComplete = styled(Autocomplete)({
  border: "1px solid rgba(0, 0, 0, 0.25)",
  minHeight: "56px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  "& .MuiAutocomplete-endAdornment": {
    display: "none",
  },
});

export const StyledInputBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: "16px",
  paddingRight: "16px",
  gap: "8px",
});

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input::placeholder": {
    color: `${theme.palette.text.secondary}`,
  },
}));

export const StyledPopper = styled(Popper)({
  width: "524px !important",
  marginTop: "15px !important",
});

export const StyledTypography = styled(CustomTypography)({
  color: "rgba(0, 0, 0, 0.87)",
  letterSpacing: "0.3px",
  lineHeight: "24px",
});

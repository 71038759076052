import { Box, Button, styled } from "@mui/material";
import CustomIconButton from "@next/components/custom-icon-button";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledFooterBox = styled(Box)`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 10;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;

export const StyledDirectionButton = styled(CustomIconButton)({
  width: "40px",
  height: "40px",
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.25)",
});

export const BlankBox = styled(Box)({
  width: "40px",
  height: "40px",
});

export const StyledButton = styled(Button)<StyledButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 272px;
  height: 40px;
  border-radius: 8px;
  gap: 8px;
  color: #ffffff;
  background-color: ${({ theme, color }) =>
    color === "success"
      ? theme.palette.success.dark
      : theme.palette.primary.main};
  &:disabled {
    opacity: 0.5;
    background-color: ${({ theme, color }) =>
      color === "success"
        ? theme.palette.success.dark
        : theme.palette.primary.main};
    color: #ffffff;
  }
  ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      width: 200px;
      gap: 2px;
    }
    ${theme.breakpoints.down("sm")} {
      width: 150px;
    }
    ${theme.breakpoints.down("xs")} {
      width: 80px;
    }
  `}
`;

interface StyledButtonProps {
  color: "primary" | "success";
}

export const StyledInnerBox = styled(Box)({
  width: "822px",
  height: "40px",
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  alignItems: "center",
  justifyContent: "center",
});

export const SavedBox = styled(Box)({
  width: "150px",
  height: "20px",
});

export const StyledTypography = styled(CustomTypography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.25px",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

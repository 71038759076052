import { LeadTimeForProductionTableRow } from "../redux";

export const validateLeadTimeForProductionFormTableRows = (
  rows: LeadTimeForProductionTableRow[]
): boolean => {
  return rows.every(
    (row) =>
      row.initialIndustrialisationCapacity !== undefined &&
      row.initialIndustrialisationCapacity !== null &&
      row.newIndustrialisationCapacity !== undefined &&
      row.newIndustrialisationCapacity !== null &&
      row.detailChange !== undefined &&
      row.detailChange !== null &&
      row.minOrderQuantity !== undefined &&
      row.minOrderQuantity !== null &&
      row.leadTimeForProduction !== undefined &&
      row.leadTimeForProduction !== null
  );
};

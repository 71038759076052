import { Box, Typography, styled } from "@mui/material";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

interface Props {
  active: boolean;
  completed: boolean | undefined;
}

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "completed",
})<Props>(({ theme, active, completed }) => ({
  height: "40px",
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  paddingLeft: "12px",
  paddingRight: "16px",
  borderRadius: "8px",
  boxSizing: "border-box",
  backgroundColor: "#ffffff",
  zIndex: 1,
  opacity: 1,
  cursor: "pointer",
  ...(!active &&
    completed && {
      border: `1px solid ${theme.palette.divider}`,
    }),
  ...(active &&
    !completed && {
      border: `2px solid ${theme.palette.primary.main}`,
      height: "42px",
    }),
  ...(active &&
    completed && {
      border: `2px solid ${theme.palette.success.main}`,
      height: "42px",
    }),
  ...(!active &&
    !completed && {
      opacity: 0.5,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
    }),
}));

export const StyledIcon = styled(PanoramaFishEyeIcon, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "completed",
})<Props>(({ theme, active, completed }) => ({
  width: "24px",
  height: "24px",
  color: theme.palette.divider,
  ...(!active &&
    completed && {
      color: theme.palette.success.main,
    }),
  ...(active &&
    completed && {
      color: theme.palette.success.main,
    }),
}));

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "completed",
})<Props>(({ theme, active, completed }) => ({
  color: !active && !completed ? theme.palette.text.disabled : "black",
}));

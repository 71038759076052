import React, { useState } from "react";
import { GridSelectionModel } from "@mui/x-data-grid-pro-v5";
import { useSelector } from "react-redux";
import {
  selectActiveFormStepIndex,
  selectAllSpecifications,
  selectAllSpecificationsLoading,
} from "@next/modules/vendor-management/redux/selectors";
import { getAddSpecListModalTableColumns } from "./add-spec-list-modal-table.columns";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import { removeMatchingItems } from "@next/modules/vendor-management/utils/removeMatchingItems";
import { QuestionnaireFormSteps } from "@next/modules/vendor-management/redux";
import * as S from "./add-spec-list-modal-table.styled";

type Props = {
  rowsAlreadyAdded: any[];
  setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const AddSpecListModalTable: React.FC<Props> = ({
  rowsAlreadyAdded,
  setSelectedRows,
  setPage,
}) => {
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const isModalForA2psForm =
    QuestionnaireFormSteps[activeFormStepIndex] === "A2PS's process control";
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const columns = getAddSpecListModalTableColumns();
  const modalTableData = useSelector(selectAllSpecifications);
  const allRows = modalTableData ? modalTableData?.results : [];

  const rows = removeMatchingItems(
    allRows,
    rowsAlreadyAdded,
    isModalForA2psForm
  );
  const isLoading = useSelector(selectAllSpecificationsLoading);

  const handleRowSelectionModelChange = (
    newSelectionModel: GridSelectionModel
  ) => {
    setSelectionModel(newSelectionModel);
    const selectedRows = allRows.filter((row) =>
      newSelectionModel.includes(row.specification_id)
    );
    setSelectedRows(selectedRows);
  };

  return (
    <S.StyledGridBox>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows}
        pagination
        paginationMode="server"
        pageSize={PAGINATION_PAGE_SIZE}
        rowCount={modalTableData?.count ? modalTableData.count : 0}
        rowsPerPageOptions={[PAGINATION_PAGE_SIZE]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        autoHeight
        headerHeight={48}
        rowHeight={32}
        disableSelectionOnClick
        selectionModel={selectionModel}
        onSelectionModelChange={handleRowSelectionModelChange}
        checkboxSelection={true}
        loading={isLoading}
        getRowId={(row) => row.specification_id}
      />
    </S.StyledGridBox>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro-v5";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectDateMenu } from "../table-menu/select-date-menu/select-date-menu";
import { EvidenceMenu } from "../table-menu/evidence-menu/evidence-menu";
import { SelectOptionMenu } from "../table-menu/select-option-menu/select-option-menu";
import {
  Spec,
  SpecificationTableRow,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectActiveFormStepIndex,
  selectIsFormCompleted,
  selectIsThereUnsavedChangesInFormStep,
  selectQuestionnaireName,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  getLeadTimeForImplementationFormTableColumns,
  selectComplianceMenuItems,
} from "./lead-time-for-implementation-form-table.columns";
import { formatDate } from "helpers/formatDate";
import * as S from "./lead-time-for-implementation-form-table.styled";

export const LeadTimeForImplementationFormTable: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectSteps);
  const stepIndex = useSelector(selectActiveFormStepIndex);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const columns =
    getLeadTimeForImplementationFormTableColumns(questionnaireName);
  const [anchorElForDateMenu, setAnchorElForDateMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElForEvidenceMenu, setAnchorElForEvidenceMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElForOptionMenu, setAnchorElForOptionMenu] =
    useState<null | HTMLElement>(null);
  const [row, setRow] = useState<SpecificationTableRow>();
  const [field, setField] = useState<string>("");
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [cellModesModel, setCellModesModel] =
    React.useState<GridCellModesModel>({});
  const isLoadingFetchSpecs = useSelector(selectSpecsOfScopeLoading);
  const specsOfScopeData = useSelector(selectSpecsOfScope);
  const rows = specsOfScopeData?.results || [];

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId: companyId,
        isA2PSControl: false,
        pagination: { page: page, pageSize: pageSize },
      })
    );
  }, [page]);

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
      if (params.field === "poe_per_line" && params.row.compliance_status) {
        setRow(params.row);
        setField(params.field);
        setAnchorElForDateMenu(event.currentTarget);
      } else if (
        (params.field === "evidence" || params.field === "coc") &&
        params.row.compliance_status &&
        params.row.compliance_status !== "fully_compliant"
      ) {
        setRow(params.row);
        setField(params.field);
        setAnchorElForEvidenceMenu(event.currentTarget);
      } else if (params.field === "compliance_status") {
        setRow(params.row);
        setField(params.field);
        setAnchorElForOptionMenu(event.currentTarget);
      }

      if (!params.colDef.editable) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [isFormCompleted, isThereUnsavedChanges]
  );

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const specification_id = newRow?.specification_id;
    let updatedField = null;
    let updatedFieldKey = null;
    Object.keys(newRow).forEach((key) => {
      if (newRow[key] !== oldRow[key]) {
        updatedField = { [key]: newRow[key] };
        updatedFieldKey = [key];
      }
    });

    if (updatedField) {
      dispatch(
        vendorManagementActions.saveSpecOfScopeRequest({
          specificationId: specification_id,
          spec: updatedField,
          companyId: companyId,
        })
      );
      checkInputChange();
    }
    return newRow;
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  const handleClickComplianceOption = (menuItem: string) => {
    const specification_id = row?.specification_id;
    const formData = new FormData();
    formData.append("compliance_status", menuItem);
    if (
      menuItem === "fully_compliant" &&
      (row?.evidence_file !== null || row?.evidence_comment !== "")
    ) {
      formData.append("evidence_file", "");
      formData.append("evidence_comment", "");
    }

    dispatch(
      vendorManagementActions.saveSpecOfScopeRequest({
        specificationId: specification_id,
        spec: formData as Spec,
        companyId: companyId,
      })
    );
    checkInputChange();
    setAnchorElForOptionMenu(null);
  };

  const handleClickDateSelection = (date: Date | null) => {
    const formattedDate = formatDate(date, "YYYY-MM-DD");

    const specification_id = row?.specification_id;
    const spec: Spec = {
      poe_per_line: formattedDate,
    };

    dispatch(
      vendorManagementActions.saveSpecOfScopeRequest({
        specificationId: specification_id,
        spec: spec,
        companyId: companyId,
      })
    );
    checkInputChange();
    setAnchorElForDateMenu(null);
  };

  const handleSaveCommentAndFile = (comment: string, file: File | "") => {
    // TO-DO: check here after api missing points is implemented
    const specification_id = row?.specification_id;
    const formData = new FormData();
    formData.append("evidence_comment", comment);
    formData.append("evidence_file", file);

    dispatch(
      vendorManagementActions.saveSpecOfScopeRequest({
        specificationId: specification_id,
        spec: formData as Spec,
        companyId: companyId,
      })
    );
    checkInputChange();
    setAnchorElForEvidenceMenu(null);
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  return (
    <>
      <S.StyledDataGridPro
        apiRef={apiRef}
        autoHeight
        autoPageSize
        headerHeight={48}
        rowHeight={32}
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        pageSize={pageSize}
        rowCount={specsOfScopeData?.count ? specsOfScopeData.count : 0}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        disableSelectionOnClick
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        loading={isLoadingFetchSpecs}
        getRowId={(row) => row.specification_id}
      />

      <SelectOptionMenu
        anchorEl={anchorElForOptionMenu}
        setAnchorEl={setAnchorElForOptionMenu}
        row={row}
        field={field}
        menuItems={selectComplianceMenuItems}
        handleClick={handleClickComplianceOption}
      />

      <SelectDateMenu
        anchorEl={anchorElForDateMenu}
        row={row}
        setAnchorEl={setAnchorElForDateMenu}
        handleChange={handleClickDateSelection}
      />

      <EvidenceMenu
        anchorEl={anchorElForEvidenceMenu}
        row={row}
        field={field}
        setAnchorEl={setAnchorElForEvidenceMenu}
        handleSaveCommentAndFile={handleSaveCommentAndFile}
      />
    </>
  );
};

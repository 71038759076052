import { Box } from "@mui/material";
import React from "react";
import { FeedbackActionItem } from "./components/feedback-action-item";
import { ContactSupportItem } from "./components/contact-support-action-item";
import { InviteUserActionItem } from "./components/invite-user-action-item";
import { SearchActionItem } from "./components/search-action-item";
import { useSelector } from "react-redux";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import { CompanyTypeNext } from "@next/redux/types";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { NotificationsActionItem } from "./components/notifications-action-item/notifications-action-item";

type Props = {
  inDrawer?: boolean;
  setSidePanelDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppBarNextItems: React.FC<Props> = ({
  inDrawer,
  setSidePanelDrawer,
}) => {
  const company = useSelector(getCompany);
  const isSeller = checkIsSellerCompany(company?.type as CompanyTypeNext);
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);

  return (
    <Box display="flex" alignItems="center">
      {!isSeller ? (
        <SearchActionItem
          iconOnly={inDrawer}
          setSidePanelDrawer={setSidePanelDrawer}
        />
      ) : null}

      <NotificationsActionItem />

      <ContactSupportItem />

      {inDrawer && <FeedbackActionItem />}

      {!hasViewerRole && inDrawer ? <InviteUserActionItem /> : null}
    </Box>
  );
};

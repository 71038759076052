import { GridOverlay, GridRowParams } from "@mui/x-data-grid-pro-v5";
import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@mui/styles";
import { useNotificationsTableData } from "./notifications-table.hooks";
import { frontendUrl } from "urls";
import { useDispatch, useSelector } from "react-redux";
import { Notification, appActions } from "@next/modules/app/redux";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import { CompanyTypeNext } from "@next/redux/types";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const StyledDataGridPro = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-columnsContainer": {
      display: "none !important",
    },
  },
})(DataGridProV5);

export const NotificationsTable = () => {
  const dispatch = useDispatch();

  const company = useSelector(getCompany);
  const isSeller = checkIsSellerCompany(company?.type as CompanyTypeNext);

  const {
    gridData,
    notificationsLoading,
    notificationsPage,
    notificationIsNextPageAvailable,
    fetchNotifications,
  } = useNotificationsTableData();

  const handleOnRowsScrollEnd = () => {
    if (!notificationsLoading && notificationIsNextPageAvailable) {
      fetchNotifications({ page: notificationsPage + 1 });
    }
  };

  const onRowClick = (params: GridRowParams) => {
    const notification = params?.row as Notification;

    dispatch(
      appActions.markAsSeenNotificationRequest({
        notificationId: notification?.id,
      })
    );

    switch (notification?.notification_type) {
      case "po":
        window.open(
          isSeller
            ? notification?.po?.url
            : `${frontendUrl.orders}/${notification?.po?.id}`,
          "_blank",
          "noreferrer"
        );
        break;
      case "po__internal":
        window.open(
          isSeller
            ? `${frontendUrl.orders}/${notification?.po?.id}?panelTab=1`
            : `${frontendUrl.orders}/${notification?.po?.id}?panelTab=1`,
          "_blank",
          "noreferrer"
        );
        break;
      case "rfq":
        window.open(
          isSeller
            ? `${frontendUrl.marketplace}/rfq/${notification?.rfq?.id}?tab=1&qa=${notification?.qa}`
            : `${frontendUrl.bundleView}/${notification?.rfq?.id}?tab=1&qa=${notification?.qa}`,
          "_blank",
          "noreferrer"
        );
        break;
      case "rfq__internal":
        window.open(
          isSeller
            ? `${frontendUrl.bundleView}/${notification?.rfq?.id}?rfqTab=1`
            : `${frontendUrl.bundleView}/${notification?.rfq?.id}?rfqTab=1`,
          "_blank",
          "noreferrer"
        );
        break;
      case "rfi":
        window.open(
          isSeller
            ? notification?.rfq?.portal_url
            : `${frontendUrl.bundleView}/${notification?.rfq?.id}?tab=1&qa=${notification?.qa}`,
          "_blank",
          "noreferrer"
        );
        break;
      case "quotation":
        window.open(
          isSeller
            ? `${frontendUrl.supplierQuoteView}/${notification?.quotation_id}?tab=1&qa=${notification?.qa}`
            : `${frontendUrl.bundleView}/${notification?.rfq?.id}?tab=1&qa=${notification?.qa}`,
          "_blank",
          "noreferrer"
        );
        break;
      case "contract":
        window.open(
          isSeller
            ? `${frontendUrl.supplierContractView}/${notification?.rfq?.id}?tab=1&qa=${notification?.qa}`
            : `${frontendUrl.bundleView}/${notification?.rfq?.id}?tab=1&qa=${notification?.qa}`,
          "_blank",
          "noreferrer"
        );
        break;
      default:
        break;
    }
  };

  return (
    <StyledDataGridPro
      rows={gridData?.rows || []}
      columns={gridData?.columns || []}
      loading={notificationsLoading}
      hideFooterPagination
      onRowsScrollEnd={handleOnRowsScrollEnd}
      components={{
        LoadingOverlay: NotificationsLoadingOverlay,
      }}
      hideFooter
      headerHeight={0}
      rowHeight={92}
      getRowClassName={() => "c-cursor-pointer"}
      isRowSelectable={() => false}
      onRowClick={onRowClick}
    />
  );
};

const NotificationsLoadingOverlay = () => {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};

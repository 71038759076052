import React, { useEffect } from "react";
import { Badge, Box, IconButton, Typography } from "@mui/material";
import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import { useQuery } from "@next/hooks/useQuery";
import { SuppliersField } from "../suppliers-table/suppliers-table-v5.types";
import { modalsActions } from "@next/redux/modalsSlices";
import { batch, useDispatch, useSelector } from "react-redux";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { WorkspaceModalTypes } from "../../modals/types";
import { SupplierWithContactsList } from "./supplier-with-contacts-list";
import {
  selectRfqQuestionsStats,
  selectRfqQuestionsStatsLoading,
} from "../../redux/selectors";
import CustomButton from "@next/components/custom-button";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { useTranslation } from "react-i18next";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { checkIsAirbusA220Company } from "@next/utils/checkCompanyUtils";

const NameCell: React.FC<
  GridRenderCellParams & {
    showContactsList?: boolean;
    openCommunicationsDrawer?: boolean;
  }
> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const rfqQAStats = useSelector(selectRfqQuestionsStats);
  const rfqQAStatsLoading = useSelector(selectRfqQuestionsStatsLoading);
  const rfqDetails = useSelector(getRfqDetails);
  const question = rfqQAStats?.find((q) => q?.company?.id === props.row?.id);
  const [query, _setQuery] = useQuery();
  const hideUploadQuoteBtn =
    rfqDetails?.quotations?.find(
      (q) => q?.company_info?.company_pk === props.row.id
    ) ||
    hasViewerRole ||
    checkIsAirbusA220Company();

  const handleOpenChat = () => {
    batch(() => {
      dispatch(
        modalsActions.showModal({
          key: WorkspaceModalTypes.RFQ_COMMUNICATION_DRAWER,
          data: {
            supplier: props.row,
            showContactsList: props.showContactsList,
            qaId: question?.id,
          },
        })
      );
    });
  };

  const handleUploadQuotation = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.UPLOAD_QUOTATION_AS_BUYER_MODAL,
        data: {
          supplier_id: props.row.id,
          supplier_picture: props.row.picture,
          parts: rfqDetails?.parts,
          currency: rfqDetails?.currency,
          delay: rfqDetails?.delay,
          delay_unit: rfqDetails?.delay_unit,
          files: [],
          fixed_charge: rfqDetails?.fixed_charge,
          total_price: rfqDetails?.total_price,
          rfq_id: rfqDetails?.pk,
        },
      })
    );
  };

  useEffect(() => {
    if (query?.supplier === props.row.id && rfqQAStats) {
      handleOpenChat();
    }
  }, [props.openCommunicationsDrawer, rfqQAStats]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <SupplierWithContactsList
        supplier={props.row}
        showContactsList={props.showContactsList}
        trimName
      />
      <Box sx={{ width: "max-content", display: "flex", alignItems: "center" }}>
        {hideUploadQuoteBtn ? null : (
          <Box width={"max-content"}>
            <CustomButton
              sx={{ ml: 1, padding: "3px 9px" }}
              variant="outlined"
              color="primary"
              onClick={handleUploadQuotation}
            >
              <Typography variant="body2">{t("rfq:uploadQuote")}</Typography>
            </CustomButton>
          </Box>
        )}

        <Box ml={1}>
          <IconButton
            onClick={handleOpenChat}
            disabled={!question || rfqQAStatsLoading}
          >
            <Badge
              badgeContent={question?.unseen_messages_count || 0}
              color="primary"
            >
              <ChatBubbleOutlineIcon
                sx={{
                  color: !question || rfqQAStatsLoading ? "inherit" : "black",
                }}
              />
            </Badge>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

export const getSuppliersColumns = (showContactsList: boolean) => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: SuppliersField.NAME,
      renderCell: (props) => (
        <NameCell {...props} showContactsList={showContactsList} />
      ),
      flex: 1,
    },
  ];

  return _columns;
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormAddButton } from "../../../form-add-button/form-add-button";
import { FormFieldErrorTypography } from "../../../form-field-error-typography/form-field-error-typography";
import { InternationalStandardTable } from "../../../table/international-standard-table/international-standard-table";
import {
  selectActiveFormStepIndex,
  selectIsFormCompleted,
  selectProcessControlStandards,
  selectProcessControlStandardsLoading,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  FieldMandatoryText,
  InternationalStandardTableRow,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import * as S from "./add-international-standard-field.styled";

type Props = {
  isShowTableErrorsIfExist: boolean;
};

const templateRow: InternationalStandardTableRow = {
  name: "",
  dash_ref: "",
  revision: "",
  controlled_process: "",
  description: "",
  scope_limitation: "",
};

export const AddInternationalStandardField: React.FC<Props> = ({
  isShowTableErrorsIfExist,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const steps = useSelector(selectSteps);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const tableRows = useSelector(selectProcessControlStandards);
  const isLoading = useSelector(selectProcessControlStandardsLoading);

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchProcessControlStandardsRequest(companyId)
    );
  }, []);

  const handleClickAddStandardBtn = () => {
    dispatch(
      vendorManagementActions.addProcessControlStandardRequest({
        companyId: companyId,
        standard: templateRow,
      })
    );
    checkInputChange();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[activeFormStepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  return (
    <S.StyledBox>
      <InternationalStandardTable />

      <S.StyledBtnBox>
        <FormAddButton
          btnText={"Add international standard"}
          onClick={handleClickAddStandardBtn}
          disabled={isLoading}
        />
      </S.StyledBtnBox>

      {isShowTableErrorsIfExist && tableRows.length === 0 ? (
        <FormFieldErrorTypography text={FieldMandatoryText} />
      ) : null}
    </S.StyledBox>
  );
};

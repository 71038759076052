import { PaginationAndFilterForSpecification } from "../redux";

export const generateSearchAndFilterSpecQuery = (
  params: PaginationAndFilterForSpecification
): string => {
  const { page, pageSize, search, specType } = params;
  let query = `page=${page}&pageSize=${pageSize}`;

  if (search) {
    query += `&search=${encodeURIComponent(search)}`;
  }

  if (specType) {
    if (Array.isArray(specType)) {
      specType.forEach((type) => {
        query += `&specType=${encodeURIComponent(type)}`;
      });
    } else {
      query += `&specType=${encodeURIComponent(specType)}`;
    }
  }

  return query;
};

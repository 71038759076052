import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import {
  AddOrUpdateProcessControlStandard,
  AddSpecToScopeRequest,
  AddSpecToSubtierSupplier,
  DeleteProcessControlStandard,
  DeleteSpecOfSubtierSupplier,
  FetchDesignAndBuiltDrawings,
  FetchFormDataRequest,
  FetchSpecsOfSubtierSupplier,
  FetchStepsRequest,
  Pagination,
  PaginationAndFilterForSpecification,
  RemoveSpecFromScopeRequest,
  SaveAdditionalComment,
  SaveSpecOfScopeRequest,
  Step,
  UpdateApprovalFormFile,
  UpdateDesignAndBuildDrawing,
  UpdateFormDataRequest,
  UpdateLeadTimeImplFormData,
  UpdateSpecOfSubtierSupplier,
  UpdateSubtierFormData,
  UpdateSubtierOnboardingTableRow,
  UpdateSubtiersOnboardingFormData,
} from "../redux";
import { generateSearchAndFilterSpecQuery } from "../utils/generateSearchAndFilterSpecQuery";

const fetchFormData = (params: FetchFormDataRequest) => {
  console.log("company_id", params.companyId, "formName", params.formName);
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementUrls[params.formName]}/${
      params.companyId
    }/`
  );
};

const updateFormData = (params: UpdateFormDataRequest) => {
  const payload = params.data;
  return axiosApi.put(
    `${backendUrl.airbusVendorManagementUrls[params.formName]}/${
      params.companyId
    }/`,
    payload
  );
};

const fetchSteps = (params: FetchStepsRequest) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/step-completion-status/company/${params.companyId}/questionnaire-type/${params.questionnaireType}/`
  );
};

const updateStep = (payload: Step) => {
  return axiosApi.put(
    `${backendUrl.airbusVendorManagementBaseUrl}/step-completion-status/${payload.id}/`,
    payload
  );
};

const fetchProcessControlStandards = (companyId: string) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/standard/${companyId}/`
  );
};

const fetchAllSpecifications = (
  payload: PaginationAndFilterForSpecification
) => {
  const query = generateSearchAndFilterSpecQuery(payload);
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/specifications?${query}`
  );
};

const deleteProcessControlStandard = (
  payload: DeleteProcessControlStandard
) => {
  const body = {
    standard_id: payload.standardId,
  };
  return axiosApi.delete(
    `${backendUrl.airbusVendorManagementBaseUrl}/standard/${payload.companyId}/`,
    { data: body }
  );
};

const addProcessControlStandard = (
  payload: AddOrUpdateProcessControlStandard
) => {
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/standard/${payload.companyId}/`,
    payload.standard
  );
};

const updateProcessControlStandard = (
  payload: AddOrUpdateProcessControlStandard
) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/standard/${payload.companyId}/`,
    payload.standard
  );
};

const updateApprovalFormFile = (payload: UpdateApprovalFormFile) => {
  const formData = new FormData();
  formData.append("file", payload.file);
  formData.append("field_name", payload.field_name);
  formData.append("field_title", payload.field_title);
  formData.append("companyId", payload.companyId);
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/approvals/upload_approval_file/`,
    formData
  );
};

const deleteApprovalFormFile = (fileId: string) => {
  return axiosApi.delete(
    `${backendUrl.airbusVendorManagementBaseUrl}/approvals/${fileId}/remove-file/`
  );
};

const fetchAclpClassifications = () => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/aclp-classifications/`
  );
};

const fetchSupplierCategories = () => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/supplier-categories/`
  );
};

const fetchSpecsOfScope = ({
  companyId,
  isA2PSControl,
  pagination,
}: {
  companyId: string;
  isA2PSControl: boolean;
  pagination: Pagination;
}) => {
  const query = generateSearchAndFilterSpecQuery({
    page: pagination.page,
    pageSize: pagination.pageSize,
    specType: isA2PSControl ? ["A2ERD", "A2TS"] : [],
  });
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/scopes/${companyId}/specifications/?${query}`
  );
};

const removeSpecFromScope = (params: RemoveSpecFromScopeRequest) => {
  return axiosApi.delete(
    `${backendUrl.airbusVendorManagementBaseUrl}/scopes/${params.companyId}/specifications/${params.specification_id}`
  );
};

const saveAdditionalCommentFieldOfForm = (params: SaveAdditionalComment) => {
  const body = {
    additional_comment: params.additionalComment,
  };
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementUrls[params.stepName]}/${
      params.companyId
    }/`,
    body
  );
};

const addSpecToScope = (params: AddSpecToScopeRequest) => {
  const body = {
    specification_id: params.specificationId,
  };
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/scopes/${params.companyId}/specifications/`,
    body
  );
};

const saveSpecOfScope = (params: SaveSpecOfScopeRequest) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/scopes/${params.companyId}/specifications/${params.specificationId}`,
    params.spec
  );
};

const updateLeadTimeImplFormData = (params: UpdateLeadTimeImplFormData) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementUrls.leadTimeForImplementationForm}/${params.companyId}/`,
    params.formData
  );
};

const updateSubtiersFormData = (params: UpdateSubtierFormData) => {
  console.log("patch", params.companyId);
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementUrls.subtiersForm}/${params.companyId}/`,
    params.formData
  );
};

const fetchSubtierCompaniesRequest = (search: string) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/companies/search/?query=${search}`
  );
};

const fetchSpecsOfSubtierSupplier = (params: FetchSpecsOfSubtierSupplier) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/specifications`
  );
};

const updateSpecOfSubtierSupplier = (params: UpdateSpecOfSubtierSupplier) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/specifications/${params.specificationId}/`,
    params.spec
  );
};

const deleteSpecOfSubtierSupplier = (params: DeleteSpecOfSubtierSupplier) => {
  return axiosApi.delete(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/specifications/${params.specificationId}/`
  );
};

const addSpecToSubtierSupplier = (params: AddSpecToSubtierSupplier) => {
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/specifications/`,
    params.spec
  );
};

const fetchSubtierOnboardingTableData = (companyId: string) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${companyId}/suppliers-access/`
  );
};

const updateSubtiersOnboardingFormData = (
  params: UpdateSubtiersOnboardingFormData
) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementUrls.subtiersOnboardingForm}/${params.companyId}/`,
    params.formData
  );
};

const updateSubtierOnboardingTableRow = (
  params: UpdateSubtierOnboardingTableRow
) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/access/`,
    params.data
  );
};

const fetchDesignAndBuiltDrawings = (params: FetchDesignAndBuiltDrawings) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/design-and-built/${params.companyId}/drawings/?page=${params.pagination.page}&pageSize=${params.pagination.pageSize}`
  );
};

const updateDesignAndBuiltDrawing = (params: UpdateDesignAndBuildDrawing) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/design-and-built/${params.companyId}/drawings/${params.drawingId}/`,
    params.data
  );
};

export const vendorManagementService = {
  fetchFormData,
  updateFormData,
  fetchSteps,
  updateStep,
  fetchProcessControlStandards,
  fetchAllSpecifications,
  deleteProcessControlStandard,
  addProcessControlStandard,
  updateProcessControlStandard,
  updateApprovalFormFile,
  fetchAclpClassifications,
  fetchSupplierCategories,
  deleteApprovalFormFile,
  fetchSpecsOfScope,
  removeSpecFromScope,
  saveAdditionalCommentFieldOfForm,
  addSpecToScope,
  saveSpecOfScope,
  updateLeadTimeImplFormData,
  fetchSubtierCompaniesRequest,
  fetchSpecsOfSubtierSupplier,
  updateSubtiersFormData,
  updateSpecOfSubtierSupplier,
  deleteSpecOfSubtierSupplier,
  addSpecToSubtierSupplier,
  fetchSubtierOnboardingTableData,
  updateSubtiersOnboardingFormData,
  updateSubtierOnboardingTableRow,
  fetchDesignAndBuiltDrawings,
  updateDesignAndBuiltDrawing,
};

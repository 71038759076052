import React from "react";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { EditableDateTemplateCell } from "../table-cells/editable-date-template-cell";
import { EditableEvidenceTemplateCell } from "../table-cells/editable-evidence-template-cell";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import { HeaderCell } from "../table-cells/header-cell";

export const selectConformMenuItems = [
  { key: "conform_to_a2x", value: "Conform to A2x/A0x" },
  { key: "partially_conform_to_a2x", value: "Partially conform to A2x/A0x" },
  { key: "not_conform_to_a2x", value: "Not conform to A2x/A0x" },
];

const sinceOptionsForDateTemplateCell = ["conform_to_a2x"];
const byOptionsForDateTemplateCell = [
  "partially_conform_to_a2x",
  "not_conform_to_a2x",
];

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getDesignAndBuiltFormTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "drawing_ref",
      headerName: "Drawing Ref",
    },
    {
      ...defaultColumnConfig,
      field: "drawing_title",
      headerName: "Drawing Title",
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "work_package_number",
      headerName: "Work package N°",
    },
    {
      ...defaultColumnConfig,
      field: "work_package_name",
      headerName: "Work package Name",
    },
    {
      ...defaultColumnConfig,
      field: "conform_to_a2x",
      headerName: "Conform to A2x",
      flex: 0.55,
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => {
        const complianceText =
          selectConformMenuItems.find((item) => item.key === params.value)
            ?.value || params.value;

        return (
          <EditableTemplateCell
            {...params}
            icon={<KeyboardArrowDownIcon />}
            placeholderText="Select an option"
            value={complianceText}
          />
        );
      },
    },
    {
      ...defaultColumnConfig,
      field: "poe",
      headerName: "POE (Point of Embodiement, due date)",
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableDateTemplateCell
          checkField={"conform_to_a2x"}
          sinceOptions={sinceOptionsForDateTemplateCell}
          byOptions={byOptionsForDateTemplateCell}
          {...params}
        />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "actionPlan",
      headerName: "Add action plan",
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableEvidenceTemplateCell
          fieldForCheckSelected={"conform_to_a2x"}
          {...params}
        />
      ),
    },
  ];

  return columns;
};

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";

import NotFoundPage from "components/not-found-page/not-found-page.component";

import { CustomRoute } from "./config/types";
import { useQueryParam } from "@next/hooks/useQuery";
import { enqueueSnackbar } from "notistack";

export const RenderRoutes = ({
  routes,
  props,
}: {
  routes: CustomRoute[];
  props: any;
}) => {
  const [errorMessage, setErrorMessage] = useQueryParam("errorMessage");
  const [successMessage, setSuccessMessage] = useQueryParam("successMessage");
  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
      setErrorMessage("");
    } else if (successMessage) {
      enqueueSnackbar(successMessage, {
        variant: "success",
      });
      setSuccessMessage("");
    }
  }, [errorMessage, successMessage]);

  return (
    <Switch>
      {routes.map((route: CustomRoute, i: number) => {
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            {...props}
            render={(routeProps) => {
              return (
                <route.layout {...props} routeConfig={route}>
                  <route.component {...props} {...routeProps} />
                </route.layout>
              );
            }}
          />
        );
      })}
      <Route
        path="*"
        component={withTranslation("ERRORS")((props) => {
          return <NotFoundPage {...props} />;
        })}
      />
    </Switch>
  );
};

import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormFooter } from "../../form-footer/form-footer";
import { FormFieldLabel } from "../../form-field-label/form-field-label";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { FormFieldBox } from "../../form-field-box";
import { FormTitle } from "../../form-title/form-title";
import { FormBoxMd } from "../../form-box/form-box-md";
import { FormParentBox } from "../../form-parent-box";
import {
  FieldMandatoryText,
  IdentificationFormData,
  Questionnaire,
  QuestionnaireForms,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectAclpClassifications,
  selectActiveFormStepIndex,
  selectFormData,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectQuestionnaireName,
  selectSteps,
  selectSupplierCategories,
} from "@next/modules/vendor-management/redux/selectors";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import * as S from "./identification-form.styled";

export const IdentificationForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const isLastStep = useSelector(selectIsLastStep);
  const steps = useSelector(selectSteps);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const questionnaireName = useSelector(selectQuestionnaireName);
  const aclpClassificationOptions = useSelector(selectAclpClassifications);
  const supplierCategoryOptions = useSelector(selectSupplierCategories);
  const identificationFormDataState = useSelector(
    selectFormData
  ) as IdentificationFormData;
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
  } = useForm<IdentificationFormData>({
    defaultValues: identificationFormDataState,
  });

  useEffect(() => {
    dispatch(vendorManagementActions.fetchAclpClassificationsRequest());
    dispatch(vendorManagementActions.fetchSupplierCategoriesRequest());
  }, []);

  const saveChanges = (isForCompletion: boolean) => {
    const formValues = watch();
    console.log("formValues", formValues);

    dispatch(
      vendorManagementActions.updateFormDataRequest({
        data: formValues,
        formName: QuestionnaireForms.Identification,
        companyId: companyId,
      })
    );
    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true);
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion = () => {
    const formValues = watch();
    const isHaveSubtier = formValues.has_subtiers;

    if (isHaveSubtier !== null && isHaveSubtier !== undefined) {
      saveChanges(true);
      showCompletedMessage();
      dispatch(
        vendorManagementActions.setRouteNextStep({
          isLastStep: isLastStep,
        })
      );
    } else {
      setError("has_subtiers", { type: "manual" });
      showInvalidMessage();
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[activeFormStepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  const onInvalid = () => {
    const formValues = watch();
    const isHaveSubtier = formValues.has_subtiers; // extra validation for isHaveSubtier
    if (isHaveSubtier === null || isHaveSubtier === undefined) {
      setError("has_subtiers", { type: "manual" });
    }
    showInvalidMessage();
  };

  const handleSubtierChange = (value: boolean) => {
    const subtierFormStep = steps.find((step) => step.step_name === "subtiers");
    const subtierOnboardingFormStep = steps.find(
      (step) => step.step_name === "subtierOnboarding"
    );

    if (subtierFormStep && subtierOnboardingFormStep) {
      const updateStepBodyForSubtier = {
        ...subtierFormStep,
        is_visible: value,
      };
      const updateStepBodyForSubtierOnboarding = {
        ...subtierOnboardingFormStep,
        is_visible: value,
      };
      dispatch(
        vendorManagementActions.updateStepRequest(updateStepBodyForSubtier)
      );
      dispatch(
        vendorManagementActions.updateStepRequest(
          updateStepBodyForSubtierOnboarding
        )
      );
    }
    checkInputChange();
    // TO-DO: if false, make null subtiers data in redux, after api integration, consider this
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  return (
    <>
      <FormParentBox>
        <FormBoxMd isCompleted={isFormCompleted}>
          <FormTitle title={"Identification"} isCompleted={isFormCompleted} />

          <FormControl margin="none">
            <Controller
              name="title"
              control={control}
              defaultValue={identificationFormDataState?.title || ""}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormFieldBox>
                  <FormFieldLabel label="Company title" />
                  <TextField
                    sx={{ width: "100%" }}
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                  {errors.title ? (
                    <FormFieldErrorTypography text={FieldMandatoryText} />
                  ) : null}
                </FormFieldBox>
              )}
            />
          </FormControl>

          <FormControl margin="none">
            <Controller
              name="address"
              control={control}
              defaultValue={identificationFormDataState?.address || ""}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormFieldBox>
                  <FormFieldLabel label="Company address" />
                  <TextField
                    sx={{ width: "100%" }}
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                  {errors.address ? (
                    <FormFieldErrorTypography text={FieldMandatoryText} />
                  ) : null}
                </FormFieldBox>
              )}
            />
          </FormControl>

          <FormControl margin="none">
            <Controller
              name="manufacturing_site_address"
              control={control}
              defaultValue={
                identificationFormDataState?.manufacturing_site_address || ""
              }
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormFieldBox>
                  <FormFieldLabel label="Manufacturing site address" />
                  <TextField
                    sx={{ width: "100%" }}
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                  {errors.manufacturing_site_address ? (
                    <FormFieldErrorTypography text={FieldMandatoryText} />
                  ) : null}
                </FormFieldBox>
              )}
            />
          </FormControl>

          <FormControl margin="none">
            <Controller
              name="vendor_code"
              control={control}
              defaultValue={identificationFormDataState?.vendor_code || ""}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormFieldBox>
                  <FormFieldLabel label="Company vendor code" />
                  <TextField
                    sx={{ width: "100%" }}
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                  {errors.vendor_code ? (
                    <FormFieldErrorTypography text={FieldMandatoryText} />
                  ) : null}
                </FormFieldBox>
              )}
            />
          </FormControl>

          <FormControl margin="none">
            <Controller
              name="cage_code"
              control={control}
              defaultValue={identificationFormDataState?.cage_code || ""}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormFieldBox>
                  <FormFieldLabel label="Company cage code" />
                  <TextField
                    sx={{ width: "100%" }}
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                  {errors.cage_code ? (
                    <FormFieldErrorTypography text={FieldMandatoryText} />
                  ) : null}
                </FormFieldBox>
              )}
            />
          </FormControl>

          <FormControl fullWidth margin="none">
            <FormFieldBox>
              <FormFieldLabel label="ACLP classification" />
              <Controller
                name="classification"
                control={control}
                rules={{ required: true }}
                defaultValue={identificationFormDataState?.classification || []}
                render={({ field }) => (
                  <Select
                    multiple
                    {...field}
                    value={field?.value || []}
                    onChange={(e) => {
                      field.onChange(e);
                      checkInputChange();
                    }}
                    error={!!errors.classification}
                  >
                    {aclpClassificationOptions
                      ? aclpClassificationOptions?.map((option) => {
                          return (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                )}
              />
            </FormFieldBox>
          </FormControl>

          <FormControl fullWidth margin="none">
            <FormFieldBox>
              <FormFieldLabel label="Supplier category" />
              <Controller
                name="supplier_category"
                control={control}
                rules={{ required: true }}
                defaultValue={
                  identificationFormDataState?.supplier_category || null
                }
                render={({ field }) => (
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e);
                      checkInputChange();
                    }}
                    error={!!errors.supplier_category}
                  >
                    {supplierCategoryOptions
                      ? supplierCategoryOptions.map((option) => {
                          return (
                            <MenuItem key={option.id} value={option?.id}>
                              {option.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                )}
              />
            </FormFieldBox>
          </FormControl>

          <FormControl margin="none">
            <Controller
              name="email_points_of_contact"
              control={control}
              defaultValue={
                identificationFormDataState?.email_points_of_contact || ""
              }
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormFieldBox>
                  <FormFieldLabel label="Email point(s) of contact" />
                  <TextField
                    sx={{ width: "100%" }}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      inputProps: {
                        minRows: 1,
                      },
                    }}
                  />
                  {errors.email_points_of_contact ? (
                    <FormFieldErrorTypography text={FieldMandatoryText} />
                  ) : null}
                </FormFieldBox>
              )}
            />
          </FormControl>

          {questionnaireName === Questionnaire.PosEndUsers ? (
            <FormControl
              fullWidth
              margin="none"
              error={errors.has_subtiers ? true : false}
            >
              <FormFieldBox>
                <FormFieldLabel label="Do you have subtiers?" />

                <Controller
                  name="has_subtiers"
                  control={control}
                  render={({ field }) => (
                    <>
                      <S.StyledRadioGroup
                        {...field}
                        value={field.value === null ? "" : field.value}
                        onChange={(e) => {
                          const value =
                            e.target.value === "true" ? true : false;
                          field.onChange(value);
                          handleSubtierChange(value);
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </S.StyledRadioGroup>
                      {errors.has_subtiers ? (
                        <FormFieldErrorTypography text={FieldMandatoryText} />
                      ) : null}
                    </>
                  )}
                />
              </FormFieldBox>
            </FormControl>
          ) : null}

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={
                identificationFormDataState?.additional_comment || ""
              }
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <FormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                    placeholder="Additional comment..."
                    multiline
                    rows={3}
                  />
                </FormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxMd>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import { SelectDateMenu } from "../table-menu/select-date-menu/select-date-menu";
import { EvidenceMenu } from "../table-menu/evidence-menu/evidence-menu";
import { SelectOptionMenu } from "../table-menu/select-option-menu/select-option-menu";
import {
  selectActiveFormStepIndex,
  selectDesignAndBuiltDrawings,
  selectDesignAndBuiltDrawingsLoading,
  selectIsFormCompleted,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  getDesignAndBuiltFormTableColumns,
  selectConformMenuItems,
} from "./design-and-built-form-table.columns";
import { formatDate } from "helpers/formatDate";
import * as S from "./design-and-built-form-table.styled";

type Props = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
};

export const DesignAndBuiltFormTable: React.FC<Props> = ({
  setPage,
  pageSize,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectSteps);
  const stepIndex = useSelector(selectActiveFormStepIndex);
  const columns = getDesignAndBuiltFormTableColumns();
  const tableData = useSelector(selectDesignAndBuiltDrawings);
  const rows = tableData ? tableData?.results : [];
  const loading = useSelector(selectDesignAndBuiltDrawingsLoading);
  const [anchorElForDateMenu, setAnchorElForDateMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElForEvidenceMenu, setAnchorElForEvidenceMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElForOptionMenu, setAnchorElForOptionMenu] =
    useState<null | HTMLElement>(null);
  const [row, setRow] = useState<any>({});
  const [field, setField] = useState<string>("");

  const handleCellClick = (
    params: GridCellParams,
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (params.field === "poe" && params.row.conform_to_a2x) {
      setRow(params.row);
      setField(params.field);
      setAnchorElForDateMenu(event.currentTarget);
    } else if (params.field === "actionPlan" && params.row.conform_to_a2x) {
      setRow(params.row);
      setField(params.field);
      setAnchorElForEvidenceMenu(event.currentTarget);
    } else if (params.field === "conform_to_a2x") {
      setRow(params.row);
      setField(params.field);
      setAnchorElForOptionMenu(event.currentTarget);
    }
  };

  const handleClickConformOption = (menuItem: string) => {
    console.log("menuItem", menuItem);
    const drawing_id = row?.drawing_id;
    const formData = new FormData();
    formData.append("conform_to_a2x", menuItem);

    dispatch(
      vendorManagementActions.updateDesignAndBuiltDrawingRequest({
        companyId: companyId,
        drawingId: drawing_id,
        data: formData as any,
      })
    );
    checkInputChange();
    setAnchorElForOptionMenu(null);
  };

  const handleClickDateSelection = (date: Date | null) => {
    const drawing_id = row?.drawing_id;
    const formattedDate = formatDate(date, "YYYY-MM-DD");
    const body = { poe: formattedDate };

    dispatch(
      vendorManagementActions.updateDesignAndBuiltDrawingRequest({
        companyId: companyId,
        drawingId: drawing_id,
        data: body,
      })
    );
    checkInputChange();
    setAnchorElForDateMenu(null);
  };

  const handleSaveCommentAndFile = (comment: string, file: File | "") => {
    const drawing_id = row?.drawing_id;
    const formData = new FormData();
    formData.append("action_plan_evidence_comment", comment);
    formData.append("action_plan_evidence_file", file);

    dispatch(
      vendorManagementActions.updateDesignAndBuiltDrawingRequest({
        companyId: companyId,
        drawingId: drawing_id,
        data: formData as any,
      })
    );
    checkInputChange();
    setAnchorElForEvidenceMenu(null);
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  return (
    <>
      <S.StyledDataGridPro
        autoHeight
        autoPageSize
        headerHeight={48}
        rowHeight={32}
        rows={rows || []}
        columns={columns}
        disableSelectionOnClick
        onCellClick={handleCellClick}
        pagination
        paginationMode="server"
        pageSize={pageSize}
        rowCount={tableData?.count ? tableData.count : 0}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        getRowId={(row) => row?.drawing_id}
        loading={loading}
      />

      <SelectOptionMenu
        anchorEl={anchorElForOptionMenu}
        setAnchorEl={setAnchorElForOptionMenu}
        row={row}
        field={field}
        menuItems={selectConformMenuItems}
        handleClick={handleClickConformOption}
      />

      <SelectDateMenu
        anchorEl={anchorElForDateMenu}
        row={row}
        setAnchorEl={setAnchorElForDateMenu}
        handleChange={handleClickDateSelection}
      />

      <EvidenceMenu
        anchorEl={anchorElForEvidenceMenu}
        row={row}
        field={field}
        setAnchorEl={setAnchorElForEvidenceMenu}
        handleSaveCommentAndFile={handleSaveCommentAndFile}
      />
    </>
  );
};

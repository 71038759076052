import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, ListItem } from "@mui/material";
import * as S from "./custom-auto-complete-text-field.styled";

type Props = {
  options: string[];
  selectedOptions: string[];
  handleChange: (options: any) => void;
};

export const CustomAutoCompleteTextField: React.FC<Props> = ({
  options,
  selectedOptions,
  handleChange,
}) => {
  const handleClickRemoveAll = () => {
    if (selectedOptions.length !== 0) {
      handleChange([]);
    }
  };

  return (
    <S.StyledAutoComplete
      multiple
      id="tags-standard"
      options={options}
      value={selectedOptions}
      onChange={(_, value) => {
        handleChange(value);
      }}
      disableCloseOnSelect
      renderInput={(params) => (
        <S.StyledInputBox>
          <SearchIcon />
          <S.StyledTextField
            {...params}
            variant="standard"
            placeholder="Search through supplier list to add your subtier(s)"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
          <IconButton onClick={handleClickRemoveAll}>
            <CloseIcon />
          </IconButton>
        </S.StyledInputBox>
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <S.StyledTypography variant="body1" tooltipTitle={null}>
            {option}
          </S.StyledTypography>
        </ListItem>
      )}
      PopperComponent={(props) => (
        <S.StyledPopper {...props} placement="bottom-start" />
      )}
    />
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import { useParams } from "react-router-dom";
import {
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectActiveFormStepIndex,
  selectIsFormCompleted,
  selectSteps,
  selectSubtierOnboardingTableData,
  selectSubtierOnboardingTableDataLoading,
} from "@next/modules/vendor-management/redux/selectors";
import { getSubtierOnboardingFormTableColumns } from "./subtier-onboarding-form-table.columns";
import * as S from "./subtier-onboarding-form-table.styled";

export const SubtierOnboardingFormTable: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectSteps);
  const stepIndex = useSelector(selectActiveFormStepIndex);
  const columns = getSubtierOnboardingFormTableColumns();
  const rows = useSelector(selectSubtierOnboardingTableData);
  const loading = useSelector(selectSubtierOnboardingTableDataLoading);

  const handleCellClick = (
    params: GridCellParams,
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (params.field !== "supplier_title") {
      dispatch(
        vendorManagementActions.updateSubtierOnboardingTableRowRequest({
          companyId: companyId,
          data: {
            [params.field]: !params.row[params.field],
          },
          supplierId: params.row.supplier_uuid,
        })
      );

      checkInputChange();
    }
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  return (
    <>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows || []}
        autoHeight
        autoPageSize
        hideFooter
        headerHeight={48}
        rowHeight={32}
        disableSelectionOnClick
        onCellClick={handleCellClick}
        loading={loading}
        getRowId={(row) => row?.supplier_uuid}
      />
    </>
  );
};

import React, { useEffect, useState } from "react";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SelectOptionsMenuWithCheckbox } from "../table-menu/select-options-menu-with-checkbox/select-options-menu-with-checkbox";
import {
  Questionnaire,
  ScopeTableRow,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectActiveFormStepIndex,
  selectAddSpecToScopeLoading,
  selectCompletionStatusesOfSteps,
  selectQuestionnaireName,
  selectRemoveSpecFromScopeLoading,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getScopeFormTableColumns } from "./scope-form-table.columns";
import { showIncompleteStepsMessageForScopeForm } from "@next/modules/vendor-management/utils/showIncompleteStepsMessageForScopeForm";
import * as S from "./scope-form-table.styled";

type Props = {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
};

export const ScopeFormTable: React.FC<Props> = ({ setPage, pageSize }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const steps = useSelector(selectSteps);
  const completionStatusesOfSteps = useSelector(
    selectCompletionStatusesOfSteps
  );
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const specsOfScopeData = useSelector(selectSpecsOfScope);
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isTableInPomDistributorQuestionnaire =
    questionnaireName === Questionnaire.PomDistributor;
  const rows = specsOfScopeData?.results || [];
  const isLoadingFetchSpecs = useSelector(selectSpecsOfScopeLoading);
  const isLoadingRowDelete = useSelector(selectRemoveSpecFromScopeLoading);
  const isLoadingAddSpecToScopeTable = useSelector(selectAddSpecToScopeLoading);
  const columns = getScopeFormTableColumns(
    isTableInPomDistributorQuestionnaire
  );
  const [prevScopeTableRows, setPrevScopeTableRows] = useState(
    specsOfScopeData ? specsOfScopeData?.results : []
  );
  const [rowsState, setRowsState] = useState<ScopeTableRow[]>(rows); // TO-DO: remove this line after api is active for checkbox menu
  const [anchorElForOptionMenu, setAnchorElForOptionMenu] =
    useState<null | HTMLElement>(null);
  const [row, setRow] = useState<any>({});
  const [field, setField] = useState<string>("");

  useEffect(() => {
    if (prevScopeTableRows.length !== rows?.length) {
      setPrevScopeTableRows(rows);
      makeAlreadyCompletedSubsequentStepsIncomplete();
    }
  }, [rows?.length]);

  const makeAlreadyCompletedSubsequentStepsIncomplete = () => {
    if (
      completionStatusesOfSteps[activeFormStepIndex + 1] ||
      completionStatusesOfSteps[activeFormStepIndex + 2]
    ) {
      if (
        completionStatusesOfSteps[activeFormStepIndex + 1] &&
        completionStatusesOfSteps[activeFormStepIndex + 2]
      ) {
        updateFieldStatusOfStep("is_completed", false, activeFormStepIndex + 1);
        updateFieldStatusOfStep("is_completed", false, activeFormStepIndex + 2);
      } else if (completionStatusesOfSteps[activeFormStepIndex + 1]) {
        updateFieldStatusOfStep("is_completed", false, activeFormStepIndex + 1);
      } else if (completionStatusesOfSteps[activeFormStepIndex + 2]) {
        updateFieldStatusOfStep("is_completed", false, activeFormStepIndex + 2);
      }
      showIncompleteStepsMessageForScopeForm(
        questionnaireName,
        completionStatusesOfSteps,
        activeFormStepIndex
      );
    }
  };

  const handleCellClick = (
    params: GridCellParams,
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (
      params.field === "sourceOfSupply" ||
      params.field === "manufacturingSite"
    ) {
      setRow(params.row);
      setField(params.field);
      setAnchorElForOptionMenu(event.currentTarget);
    } else if (params.field === "removeRowButton") {
      const id = params.row.specification_id;
      dispatch(
        vendorManagementActions.removeSpecFromScopeRequest({
          companyId: companyId,
          specification_id: id,
        })
      );
      checkInputChange();
      return;
    }
  };

  const checkInputChange = () => {
    if (completionStatusesOfSteps[activeFormStepIndex]) {
      updateFieldStatusOfStep("is_completed", false, activeFormStepIndex);
    } else {
      updateFieldStatusOfStep("is_partially_filled", true, activeFormStepIndex);
    }
  };

  const updateFieldStatusOfStep = (
    key: "is_completed" | "is_partially_filled",
    value: boolean,
    stepIndex: number
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  return (
    <>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows}
        headerHeight={48}
        rowHeight={32}
        disableSelectionOnClick
        onCellClick={handleCellClick}
        getRowId={(row) => row.specification_id}
        loading={
          isLoadingFetchSpecs ||
          isLoadingRowDelete ||
          isLoadingAddSpecToScopeTable
        }
        pagination
        paginationMode="server"
        pageSize={pageSize}
        rowCount={specsOfScopeData?.count ? specsOfScopeData.count : 0}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        autoHeight
      />

      <SelectOptionsMenuWithCheckbox
        anchorEl={anchorElForOptionMenu}
        setAnchorEl={setAnchorElForOptionMenu}
        setRows={setRowsState}
        row={row}
        field={field}
      />
    </>
  );
};

export enum Questionnaire {
  PosEndUsers = "posEndUsersQuestionnaire",
  PomManufacturer = "pomManufacturerQuestionnaire",
  PomDistributor = "pomDistributorQuestionnaire",
}

export enum QuestionnaireType {
  posEndUsersQuestionnaire = 1,
  pomManufacturerQuestionnaire = 2,
  pomDistributorQuestionnaire = 3,
}

export enum OptionalPosEndUsersQuestionnaireFormSteps {
  Subtiers = "Subtiers",
  SubtiersOnboarding = "Subtiers onboarding",
}

export type Step = {
  id: string;
  is_completed: boolean;
  is_partially_filled: boolean;
  last_updated: string;
  is_visible: boolean;
  step: number;
  company: string;
  step_order: number;
  step_name: string;
};

export const QuestionnaireFormSteps = {
  identification: "Identification",
  approval: "Approval",
  onboarding: "Onboarding",
  scope: "Scope",
  "A2P'S process control": "A2PS's process control",
  "Lead Time Implementation": "Lead time for implementation",
  "Design and Built": "Design and built",
  Subtiers: "Subtiers",
  "Subtiers Onboarding": "Subtiers onboarding",
  "Lead Time Production":
    "Lead time for production + industrialisation capacity",
  "Buffer Stock Strategy": "Buffer stock strategy",
};

export enum QuestionnaireForms {
  Identification = "identificationForm",
  Approval = "approvalForm",
  Onboarding = "onboardingForm",
  Scope = "scopeForm",
  A2psProcessControl = "a2psProcessControlForm",
  LeadTimeForImplementation = "leadTimeForImplementationForm",
  LeadTimeForProduction = "leadTimeForProductionForm",
  DesignAndBuilt = "designAndBuiltForm",
  Subtiers = "subtiersForm",
  SubtiersOnboarding = "subtiersOnboardingForm",
  BufferStockStrategy = "bufferStockStrategyForm",
}

export enum PosEndUsersQuestionnaireForms {
  Identification = "identificationForm",
  Approval = "approvalForm",
  Onboarding = "onboardingForm",
  Scope = "scopeForm",
  A2psProcessControl = "a2psProcessControlForm",
  LeadTimeForImplementation = "leadTimeForImplementationForm",
  DesignAndBuilt = "designAndBuiltForm",
  Subtiers = "subtiersForm",
  SubtiersOnboarding = "subtiersOnboardingForm",
}

export enum PomManufacturerForms {
  Onboarding = "onboardingForm",
  Approval = "approvalForm",
  Identification = "identificationForm",
  Scope = "scopeForm",
  LeadTimeForImplementation = "leadTimeForImplementationForm",
  LeadTimeForProduction = "leadTimeForProductionForm",
}

export enum PomDistributorForms {
  Identification = "identificationForm",
  Approval = "approvalForm",
  Onboarding = "onboardingForm",
  Scope = "scopeForm",
  LeadTimeForImplementation = "leadTimeForImplementationForm",
  BufferStockStrategy = "bufferStockStrategyForm",
}

export type IdentificationFormData = {
  company_id: string;
  title: string;
  address: string;
  manufacturing_site_address: string;
  vendor_code: string;
  cage_code: string;
  classification: number[];
  supplier_category: number;
  email_points_of_contact: string;
  has_subtiers?: boolean | null;
  additional_comment?: string;
};

export type OnboardingFormData = {
  supplier_access_id: number;
  isupply: boolean | null;
  A2emm_001: boolean | null;
  am9000: boolean | null;
  aqpl_airbus: boolean | null;
  a220_asl_airbus: boolean | null;
  normaster: boolean | null;
  additional_comment?: string;
  company: string;
};

export const OnboardingRadioGroupKeys = [
  "isupply",
  "A2emm_001",
  "am9000",
  "aqpl_airbus",
  "a220_asl_airbus",
  "normaster",
];

export type ScopeFormData = {
  scope_id: number;
  specs_count: number;
  source_of_supply: string;
  manufacturing_site: string;
  company: string;
  specifications: number[];
  additional_comment?: string;
  scope_limitation?: string;
};

export type A2psProcessControlFormData = {
  process_control_id: number;
  has_captive_laboratory: boolean | null;
  additional_comment?: string;
  company: string;
};

export type LeadTimeForImplementationFormData = {
  lead_time_id: number;
  specification_integration?: boolean | null;
  road_map?: string;
  road_map_file?: {
    file_url: string;
    file_type: string;
    file_size: number;
    file_name: string;
  };
  additional_comment: string;
  company: string;
};

export type DesignAndBuiltFormData = {
  design_and_build_id: number;
  additional_comment?: string;
  company: string;
};

export type SubtierSupplier = {
  id: string;
  title: string;
};

export type SubtiersFormData = {
  subtier_id: number;
  company: string;
  subtier_suppliers: string[];
  subtier_suppliers_info: SubtierSupplier[];
  subtier_validation: boolean | null;
  additional_comment: string;
};

export type SubtiersOnboardingFormData = {
  confirmSubtierOnboarding: boolean | null;
  additional_comment?: string;
};

export type BufferStockStrategyFormData = {
  tableData: BufferStockStrategyTableRow[];
  additionalComment?: string;
};

export type LeadTimeForProductionFormData = {
  tableData: LeadTimeForProductionTableRow[];
  additionalComment?: string;
};

export type ApprovalFormData = {} & FileObj[];

export type QuestionnaireState = {
  posEndUsersQuestionnaire: {
    name: Questionnaire.PosEndUsers;
    steps: Step[];
    activeFormStepIndex: number;
    formStepsData: {
      identificationForm: IdentificationFormData;
      onboardingForm: OnboardingFormData;
      scopeForm: ScopeFormData;
      a2psProcessControlForm: A2psProcessControlFormData;
      leadTimeForImplementationForm: LeadTimeForImplementationFormData;
      designAndBuiltForm: DesignAndBuiltFormData;
      subtiersForm?: SubtiersFormData;
      subtiersOnboardingForm?: SubtiersOnboardingFormData;
      approvalForm?: ApprovalFormData;
    };
    lastSavedDate: string;
  };
  pomManufacturerQuestionnaire: {
    name: Questionnaire.PomManufacturer;
    steps: Step[];
    activeFormStepIndex: number;
    formStepsData: {
      identificationForm: IdentificationFormData;
      onboardingForm: OnboardingFormData;
      scopeForm: ScopeFormData;
      leadTimeForImplementationForm: LeadTimeForImplementationFormData;
      leadTimeForProductionForm: LeadTimeForProductionFormData;
      approvalForm?: ApprovalFormData;
    };
    lastSavedDate: string;
  };
  pomDistributorQuestionnaire: {
    name: Questionnaire.PomDistributor;
    steps: Step[];
    activeFormStepIndex: number;
    formStepsData: {
      identificationForm: IdentificationFormData;
      onboardingForm: OnboardingFormData;
      scopeForm: ScopeFormData;
      leadTimeForImplementationForm: LeadTimeForImplementationFormData;
      bufferStockStrategyForm: BufferStockStrategyFormData;
      approvalForm?: ApprovalFormData;
    };
    lastSavedDate: string;
  };
  currentQuestionnaireName:
    | Questionnaire.PomDistributor
    | Questionnaire.PomManufacturer
    | Questionnaire.PosEndUsers;
  isThereUnsavedChangesInFormStep: boolean;
  isSupplierApproved: boolean | null;
  isShowGreetingPage: boolean;
};

export type VendorManagementState = {
  formDataLoading: boolean;
  formDataError?: Error | undefined;
  formData:
    | IdentificationFormData
    | OnboardingFormData
    | ScopeFormData
    | A2psProcessControlFormData
    | LeadTimeForImplementationFormData
    | DesignAndBuiltFormData
    | SubtiersFormData
    | SubtiersOnboardingFormData
    | BufferStockStrategyFormData
    | LeadTimeForProductionFormData
    | ApprovalFormData;

  updateFormDataLoading: boolean;
  updateFormDataError: Error | undefined;

  fetchStepsLoading: boolean;
  fetchStepsError: Error | undefined;

  updateStepLoading: boolean;
  updateStepError: Error | undefined;

  processControlStandardsLoading: boolean;
  processControlStandardsError: Error | undefined;
  processControlStandardsData: InternationalStandardTableRow[] | null;

  allSpecificationsLoading: boolean;
  allSpecificationsError: Error | undefined;
  allSpecificationsData: Specification | null;

  deleteProcessControlStandardLoading: boolean;
  deleteProcessControlStandardError: Error | undefined;

  addProcessControlStandardLoading: boolean;
  addProcessControlStandardError: Error;

  updateProcessControlStandardLoading: boolean;
  updateProcessControlStandardError: Error;

  updateApprovalFormFileLoading: boolean;
  updateApprovalFormFileError: Error | undefined;

  deleteApprovalFormFileLoading: boolean;
  deleteApprovalFormFileError: Error | undefined;

  aclpClassificationsLoading: boolean;
  aclpClassificationsError: Error | undefined;
  aclpClassificationsData: AclpClassification[] | null;

  supplierCategoriesLoading: boolean;
  supplierCategoriesError: Error | undefined;
  supplierCategoriesData: SupplierCategory[] | null;

  specsOfScopeLoading: boolean;
  specsOfScopeError: Error | undefined;
  specsOfScopeData: FetchSpecsOfScopeResponse | null;

  removeSpecFromScopeLoading: boolean;
  removeSpecFromScopeError: Error | undefined;

  saveAdditionalCommentLoading: boolean;
  saveAdditionalCommentError: Error | undefined;

  addSpecToScopeLoading: boolean;
  addSpecToScopeError: Error | undefined;

  saveSpecOfScopeLoading: boolean;
  saveSpecOfScopeError: Error | undefined;

  updateLeadTimeImplFormDataLoading: boolean;
  updateLeadTimeImplFormDataError: Error | undefined;

  updateSubtierFormDataLoading: boolean;
  updateSubtierFormDataError: Error | undefined;

  subtierCompaniesLoading: boolean;
  subtierCompaniesError: Error | undefined;
  subtierCompaniesData: FetchSubtierCompaniesResponse | null;

  specsOfSubtierSupplierLoading: boolean;
  specsOfSubtierSupplierError: Error | undefined;
  specsOfSubtierSuppliersData: SpecOfSubtierSupplier[] | null;

  updateSpecOfSubtierSupplierLoading: boolean;
  updateSpecOfSubtierSupplierError: Error | undefined;

  deleteSpecOfSubtierSupplierLoading: boolean;
  deleteSpecOfSubtierSupplierError: Error | undefined;

  addSpecToSubtierSupplierLoading: boolean;
  addSpecToSubtierSupplierError: Error | undefined;

  subtierOnboardingTableDataLoading: boolean;
  subtierOnboardingTableDataError: Error | undefined;
  subtierOnboardingTableData: SubtierOnboardingTableRow[] | null;

  updateSubtiersOnboardingFormDataLoading: boolean;
  updateSubtiersOnboardingFormDataError: Error | undefined;

  updateSubtierOnboardingTableRowLoading: boolean;
  updateSubtierOnboardingTableRowError: Error | undefined;

  designAndBuiltDrawingsLoading: boolean;
  designAndBuiltDrawingsError: Error | undefined;
  designAndBuiltDrawingsData: DesignAndBuiltDrawingsResponse | null;

  udpateDesignAndBuiltDrawingLoading: boolean;
  udpateDesignAndBuiltDrawingError: Error | undefined;
} & QuestionnaireState;

export const FieldMandatoryText = "Mandatory";

export type AclpClassification = {
  id: number;
  name: string;
};

export type SupplierCategory = {
  id: number;
  name: string;
};

export type SetQuestionnaireFormStepData = {
  data:
    | IdentificationFormData
    | OnboardingFormData
    | ScopeFormData
    | A2psProcessControlFormData
    | LeadTimeForImplementationFormData
    | DesignAndBuiltFormData
    | SubtiersFormData
    | SubtiersOnboardingFormData
    | BufferStockStrategyFormData
    | LeadTimeForProductionFormData
    | ApprovalFormData;
  stepName:
    | QuestionnaireForms
    | PosEndUsersQuestionnaireForms
    | PomManufacturerForms
    | PomDistributorForms;
};

export enum VendorManagementModalTypes {
  ADD_SPEC_LIST_MODAL = "add-spec-list-modal",
  THANK_YOU_MODAL = "thank-you-modal",
}

export type AddSpecListModalTableRow = {
  id: string;
  specType: string;
  specification: string;
  dash: string;
  revision: string;
  controlledProcess: string;
  description: string;
  supplierList: string[];
  manufacturerSiteList: string[];
};

export type ScopeTableRow = {} & SpecificationTableRow;

export type AirbusSpecificationTableRow = {
  process_control_spec_id: number;
  specification_details: SpecificationTableRow;
  scope_limitation: string;
  company: string;
  specification: 2;
};

export type SpecificationTableRow = {
  specification_id: number;
  name: string;
  dash_ref: string;
  revision: string;
  controlled_process: string;
  description: string;
  specType: string;
  scope_limitation: string;
  evidence_file: EvidenceFileObj;
  evidence_comment: string;
  compliance_status: string;
  poe_per_line: string;
  ao_x_stock: number;
  bo_x_stock: number;
};

export type Spec = {
  scope_limitation?: string;
  evidence_file?: EvidenceFileObj; // TO-DO: remove string after response structure is fixed
  evidence_comment?: string;
  compliance_status?: string;
  poe_per_line?: string;
  ao_x_stock?: number;
  bo_x_stock?: number;
};

export type Specification = {
  count: number;
  results: SpecificationTableRow[];
};

export type InternationalStandardTableRow = {
  standard_id?: number;
  name?: string;
  dash_ref?: string;
  revision?: string;
  controlled_process?: string;
  description?: string;
  scope_limitation?: string;
  company?: string;
};

export enum Compliance {
  FullyCompliant = "fully_compliant",
  PartiallyCompliant = "partially_compliant",
  NotCompliant = "not_compliant",
  CompliantWithRFD = "compliant_with_rfd",
}

export type ActionPlan = {
  file: any;
  comment?: string;
};

export type LeadTimeForImplementationTableRow = {
  id: string;
  specification: string;
  dash: string;
  revision: string;
  controlledProcess: string;
  description: string;
  sourceOfSupply?: string[];
  manufacturingSite?: string[];
  compliance?: Compliance;
  poe?: string | null;
  actionPlan?: ActionPlan;
  a0xStock?: number;
  b0xStock?: number;
  coc?: ActionPlan;
};

export type DesignAndBuiltTableRow = {
  drawing_id: number;
  drawing_ref: string;
  drawing_title: string;
  revision: string;
  work_package_number: string;
  work_package_name: string;
  conform_to_a2x: string;
  poe: string;
  action_plan_evidence_file: EvidenceFileObj;
  action_plan_evidence_comment: string;
};

export type SubtierTableRow = {
  id: number;
  name: string;
  specification_id?: number;
  dash_ref: string;
  revision: string;
  controlled_process: string;
  description: string;
  compliance: string;
  poe: string;
};

export type SubtierOnboardingTableRow = {
  supplier_uuid: string;
  supplier_title: string;
  is_upply: boolean;
  am9000: boolean;
  emcm: boolean;
  aqpl: boolean;
  A220asl: boolean;
  normaster: boolean;
};

export type UpdateSubtierOnboardingTableRow = {
  supplierId: string;
  companyId?: string;
  data: {
    is_upply?: boolean;
    am9000?: boolean;
    emcm?: boolean;
    aqpl?: boolean;
    A220asl?: boolean;
    normaster?: boolean;
  };
};

export type BufferStockStrategyTableRow = {
  id: string;
  specification: string;
  dash: string;
  revision: string;
  controlledProcess: string;
  description: string;
  sourceOfSupply: string[];
  manufacturingSite: string[];
  a0xStock?: number;
  b0xStock?: number;
};

export type LeadTimeForProductionTableRow = {
  id: string;
  specification: string;
  dash: string;
  revision: string;
  controlledProcess: string;
  description: string;
  initialIndustrialisationCapacity?: number;
  newIndustrialisationCapacity?: number;
  detailChange?: string;
  minOrderQuantity?: number;
  leadTimeForProduction?: number;
};

export type SetQuestionnaireCompletedInfoByStepIndex = {
  stepIndex: number;
  completed: boolean;
};

export type FetchFormDataRequest = {
  formName:
    | QuestionnaireForms
    | PosEndUsersQuestionnaireForms
    | PomManufacturerForms
    | PomDistributorForms;
  companyId: string;
};

export type UpdateFormDataRequest = {
  data:
    | IdentificationFormData
    | OnboardingFormData
    | ScopeFormData
    | A2psProcessControlFormData
    | LeadTimeForImplementationFormData
    | DesignAndBuiltFormData
    | SubtiersFormData
    | SubtiersOnboardingFormData
    | BufferStockStrategyFormData
    | LeadTimeForProductionFormData
    | ApprovalFormData;
} & FetchFormDataRequest;

export type Pagination = {
  page?: number;
  pageSize?: number;
};

export type PaginationAndFilterForSpecification = {
  search?: string;
  specType?: string | string[];
} & Pagination;

export type DeleteProcessControlStandard = {
  companyId: string;
  standardId: number;
};

export type AddOrUpdateProcessControlStandard = {
  companyId: string;
  standard: InternationalStandardTableRow;
};

export type UpdateApprovalFormFile = {
  companyId: string;
  file: File | string; // TO-DO: remove string after response structure is fixed
  field_name: string;
  field_title: string;
};

export type FetchStepsRequest = {
  companyId: string;
  questionnaireType: number;
};

export type FileObj = {
  file_id: string;
  field_name: string;
  field_title: string;
  file_size: number;
  file_name: string;
  file_url: string;
  file_type: string;
  uploaded_at?: string;
  company: string;
};

export type EvidenceFileObj = {
  file_type: string;
  file_size: number;
  file_name: string;
  file_url: string;
};

export type FetchSpecsOfScopeResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ScopeTableRow[];
};

export type RemoveSpecFromScopeRequest = {
  specification_id: number;
  companyId: string;
};

export type SaveAdditionalComment = {
  stepName:
    | QuestionnaireForms
    | PosEndUsersQuestionnaireForms
    | PomManufacturerForms
    | PomDistributorForms;
  additionalComment: string;
  companyId: string;
};

export type AddSpecToScopeRequest = {
  companyId: string;
  specificationId: number;
};

export type SaveSpecOfScopeRequest = {
  companyId: string;
  specificationId: number;
  spec: Spec;
};

export type SaveSpecOfScopeSuccess = {
  specificationId: number;
  spec: Spec;
};

export type UpdateLeadTimeImplFormData = {
  formData:
    | {
        road_map?: string | File;
        road_map_file?: File;
        specification_integration?: boolean;
        additional_comment?: string;
      }
    | FormData;
  companyId: string;
};

export type UpdateSubtierFormData = {
  formData: {
    subtier_validation?: boolean;
    additional_comment?: string;
    subtier_suppliers?: string[];
    subtier_suppliers_info?: SubtierSupplier[];
  };
  companyId: string;
};

export type FetchSpecsOfSubtier = {
  companyId: string;
  subtierId: string;
};

export type SubtierSupplierResponse = {
  company_id: string;
  title: string;
};

export type FetchSubtierCompaniesResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: SubtierSupplierResponse[];
};

export type SpecOfSubtierSupplier = {
  supplier_id: string;
  rows: SubtierTableRow[];
};

export type FetchSpecsOfSubtierSupplier = {
  companyId: string;
  supplierId: string;
};

export type SpecOfSubtier = {
  compliance?: string;
  poe?: string;
};

export type UpdateSpecOfSubtierSupplier = {
  companyId: string;
  supplierId: string;
  specificationId: number;
  spec: SpecOfSubtier;
};

export type UpdateSpecOfSubtierSupplierSuccess = {
  supplierId: string;
  specificationId: number;
  spec: SpecOfSubtier;
};

export type DeleteSpecOfSubtierSupplier = {
  companyId?: string;
  supplierId: string;
  specificationId: number;
};

export type AddSpecToSubtierSupplier = {
  companyId: string;
  supplierId: string;
  spec: {
    specification_id: number;
    poe: string | null;
    compliance: string | null;
  };
};

export type AddSpecToSubtierSupplierSuccess = {
  supplierId: string;
  spec: {
    id: number;
    specification_id: number;
    poe?: string | null;
    compliance?: string | null;
  };
};

export type UpdateSubtiersOnboardingFormData = {
  formData: {
    confirmSubtierOnboarding: boolean;
    additional_comment: string;
  };
  companyId: string;
};

export type DesignAndBuiltDrawingsResponse = {
  results: DesignAndBuiltTableRow[];
  count: number;
};

export type FetchDesignAndBuiltDrawings = {
  companyId: string;
  pagination: Pagination;
};

export type UpdateDesignAndBuildDrawing = {
  companyId: string;
  drawingId: number;
  data: {
    conform_to_a2x?: string;
    poe?: string;
    action_plan_evidence_file?: File;
    action_plan_evidence_comment?: string;
  };
};

export type UpdateDesignAndBuildDrawingSuccess = {
  drawingId: number;
  partOfRow: any;
};

/**
 * This is the component we use to display all the info about a bundle rfq that the buyer has created
 * it can be found at /workspace/buyer-quotes/view/:id
 * This view use the id to fetch the right RFQ in the RFQList that belongs to this user.
 */

// General imports
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RequestType } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.definitions";
// Material ui imports
import { Box, Grid, alpha } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { OffersDetails } from "./offers-details/offers-details.component";
import { OffersSummary } from "./offers-summary/offers-summary.component";
import { RenderUploads } from "components/utils/render-uploads/render-uploads.component";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { AppTheme } from "layout/AppTheme";
import { useQueryParam } from "@next/hooks/useQuery";

/**
 * OffersView  use to display all the characteristics for the offer
 *
 * @param {objects} props
 */
export const OffersView = (props) => {
  const {
    quoteIndex,
    quote,
    token,
    activeRfq,
    selectWinner,
    getQuotationThreadDetails,
    setActiveQuotationThread,
    t,
    handleOpenModal,
    handleCloseModal,
    expendedPanel,
  } = props;

  const dispatch = useDispatch();
  const [expandedQuote, setExpandedQuote] = useQueryParam("expandedQuote");
  const [expanded, setExpanded] = useState(
    quote.pk === parseInt(expandedQuote) ? quote.pk : false
  );
  const [newExpanded, setNewExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setNewExpanded(false);
    setExpanded(isExpanded ? panel : false);
    setExpandedQuote(panel === expandedQuote ? null : expandedQuote);
  };

  useEffect(() => {
    if (expendedPanel) {
      setNewExpanded(true);
    }
  }, [expendedPanel]);

  const openPopup = Boolean(anchorEl);

  const isWinningQuote = () => {
    if (activeRfq.request_type === RequestType.RFI && activeRfq?.shortlisted) {
      return activeRfq.shortlisted.includes(quote.company_info.company_pk);
    }

    return activeRfq.winner === quote.company_info.company_name;
  };

  useEffect(() => {
    if (expandedQuote) {
      const elem = document.getElementById(`quotePanel-${quote.pk}`);
      elem.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [expandedQuote]);

  return (
    <Accordion
      elevation={0}
      expanded={expanded === quote.pk || newExpanded}
      onChange={handleChange(quote.pk)}
      className="c-offers-view"
      style={{
        background: isWinningQuote()
          ? alpha(AppTheme.palette.success.main, 0.05)
          : "transparent",
      }}
      id={`quotePanel-${quote.pk}`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className={`c-offers-view--panel-header ${
          openPopup ? "disabled-expansion-panel" : ""
        }`}
      >
        <OffersSummary
          token={token}
          quote={quote}
          t={t}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          quotePk={quote["company_info"]["company_pk"]}
        />
      </AccordionSummary>

      <AccordionDetails>
        <Grid item xs={8}>
          <Grid container direction="column" justifyContent="flex-start">
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start">
                <Grid item>
                  <Box
                    fontWeight="fontWeightRegular"
                    fontSize={16}
                    className="c-offers-view--additional-details"
                  >
                    <RichTextEditor
                      readOnly
                      defaultValue={
                        quote.additional_details_rich_text ||
                        quote.additional_details
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {quote.quotation_uploads_list.length === 0
                ? ""
                : quote.quotation_uploads_list.map((quotationUpload) => {
                    return (
                      <RenderUploads
                        file={quotationUpload}
                        rfqPk={quote.rfq_info.pk}
                        rfqName={quote.rfq_info.name}
                        category="download"
                        featureName="download_quotation"
                      />
                    );
                  })}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>

      {activeRfq.request_type !== RequestType.RFI && (
        <AccordionDetails>
          <OffersDetails
            quoteIndex={quoteIndex}
            quote={quote}
            activeRfq={activeRfq}
            token={token}
            selectWinner={selectWinner}
            getQuotationThreadDetails={getQuotationThreadDetails}
            setActiveQuotationThread={setActiveQuotationThread}
            supplierName={quote.company_info.company_name}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            t={t}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
};

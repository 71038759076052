import { TenderData } from "@next/modules/marketplace/redux/types";
import { GenericUser } from "@next/modules/profile/redux";
import {
  ExportMyPartsInput,
  ImportMyPartsInput,
  PartUpload,
  ProjectCompanyPart,
  ProjectCreatePartInput,
  ProjectUpdatePartInput,
} from "@next/modules/project/redux";
import { addOrUpdateField } from "@next/utils/arrayUtils";
import { GenericPaginatedData } from "@next/utils/typeUtils";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RFQSupplierType } from "../components/rfq-creation-form/rfq-creation.form.types";
import {
  ASLSupplierSearch,
  AddAddendaInput,
  AddFavoriteSupplierInput,
  AnswerTopicInput,
  ApprovedSupplierInput,
  AxyaRFQStats,
  CheckEmailAlreadyExistsInput,
  ContactUsInput,
  ContractMarkAsCompleteInput,
  CreateDiscordNofityInput,
  CreateEasyRfqInput,
  CreateFavoriteSupplierGroupInput,
  CreatePortalQuoteInput,
  CreatePortalUrl,
  CreateTopicInput,
  CustomQuotationExportInput,
  DeleteFileUploadProgress,
  DeletePortalQuoteFileInput,
  DraftRFQ,
  DraftRFQData,
  ExportBRPFilesInput,
  ExportQuotesComparisonSheetInput,
  FavoriteSupplier,
  FavoriteSupplierGroup,
  FetchContractsInput,
  FetchDraftRFQsInput,
  FetchFavoriteSupplierInput,
  FetchInternalChatTopicInput,
  FetchMyPartsInput,
  FetchPartsInput,
  FetchPortalDataInput,
  FetchPresignedS3Input,
  FetchQAThreadDetailsInput,
  FetchQuoteComparisonInput,
  FetchRFQFavoriteSuppliersInput,
  FetchRFQQuestionsStatsInput,
  FetchSupplierQuotesInput,
  FetchTopicInput,
  FetchTopicsInput,
  FileChunkUploadProgress,
  FileChunkUploadSuccess,
  FileUploadProgress,
  GlobalSearchInput,
  GlobalSearchResult,
  InternalNotePayload,
  InviteSupplierContactInput,
  InviteSupplierInput,
  LoadDraftRFQInput,
  MarketplaceCounts,
  NDA,
  PortalData,
  PostInternalChatMessageInput,
  PresignedS3Url,
  ProceedQuoteComparisonSelectionInput,
  QaMarkAsReadInput,
  RFQQuestionsStats,
  SelectInternalNote,
  SendAirbusConfirmationInput,
  SendNewVerificationEmailInput,
  SendRevisionNoteInput,
  SupplierQuotesCounts,
  SyncERPQuotationInput,
  TopicMessageType,
  TopicType,
  UpdateDraftRFQCollaboratorsInput,
  UpdateNDAInput,
  UpdatePortalQuoteInput,
  UpdateQuoteComparisonConfigInput,
  UpdateQuoteNoQuoteStatusInput,
  UpdateRFQCollaboratorsInput,
  UpdateRFQFollowupDateInput,
  UpdateRFQIsPublicInput,
  UpdateRFQTargetedProfilesInput,
  UpdateSupplierContactsListInput,
  UploadAddendaAttachmentsInput,
  UploadMyPartsAttachmentsInput,
  UploadPortalQuoteFilesInput,
  UploadQuotationAsBuyerInput,
  UploadQuotationFilesAsBuyerInput,
  UploadRfqAttachmentsInput,
  WorkspaceNextState,
  uploadMultiplePartFilesInput,
  uploadSinglePartFileInput,
} from "./types";
import { QCResponse } from "../components/quote-comparison/types";
import { FileData } from "@next/components/attach-file/attach-file-list.types";

export const initialState: WorkspaceNextState = {
  searchSuppliersLoading: false,
  searchSuppliersError: undefined,
  searchSuppliers: {
    type: "",
    results: [],
  },

  addASLLoading: false,
  addASLError: undefined,

  qaTopicsLoading: false,
  qaTopicsError: undefined,
  qaTopics: [],

  qaTopicLoading: false,
  qaTopicError: undefined,
  qaTopic: undefined,

  internalChatTopicLoading: false,
  internalChatTopicError: undefined,
  internalChatTopic: undefined,

  createQaTopicLoading: false,
  createQaTopicError: undefined,

  answerQaTopicLoading: false,
  answerQaTopicError: undefined,
  contactUsLoading: false,
  contactUsError: undefined,

  customQuotationExportLoading: false,
  customQuotationExportError: undefined,
  inviteSupplierLoading: false,
  inviteSupplierError: undefined,
  isEditRfqDescription: false,
  showQuotationVariance: true,
  ndaListLoading: false,
  ndaListError: undefined,
  ndaList: [],

  updateNDALoading: false,
  updateNDAError: undefined,

  rfqForm: {
    fetchPartsLoading: false,
    fetchPartsError: undefined,
    partsList: [],
    partsListNext: null,

    fetchFavoriteSuppliersLoading: false,
    fetchFavoriteSuppliersError: undefined,
    favoriteSuppliersList: [],
    favoriteSuppliersListNext: null,
    favoriteSuppliersListCount: 0,

    additionalFiles: null,
  },
  favoriteSuppliers: [],
  favoriteSuppliersLoading: false,
  favoriteSuppliersError: undefined,
  favoriteSuppliersCount: 0,

  updateFavoriteSupplierLoading: false,
  updateFavoriteSupplierError: undefined,

  addFavoriteSupplierLoading: false,
  addFavoriteSupplierError: undefined,

  removeFavoriteSupplierLoading: false,
  removeFavoriteSupplierError: undefined,

  favoriteSupplierGroups: [],
  favoriteSupplierGroupsLoading: false,
  favoriteSupplierGroupsError: undefined,

  createFavoriteSupplierGroupLoading: false,
  createFavoriteSupplierGroupError: undefined,

  draftRFQs: [],
  draftRFQsLoading: false,
  draftRFQsError: undefined,

  saveDraftRFQLoading: false,
  saveDraftRFQError: undefined,

  deleteDraftRFQLoading: false,
  deleteDraftRFQError: undefined,

  loadDraftRFQLoading: -1,
  loadDraftRFQError: undefined,

  updateDraftRFQCollaboratorsLoading: false,
  updateDraftRFQCollaboratorsError: undefined,

  activeDraftRFQ: null,
  draftRFQsCount: 0,

  createDiscoryNotifyLoading: false,
  createDiscoryNotifyError: undefined,

  contracts: [],
  contractsLoading: false,
  contractsError: undefined,
  contractsCount: 0,

  tenders: [],
  tendersLoading: false,
  tendersError: undefined,

  myParts: [],
  myPartsLoading: false,
  myPartsError: undefined,
  myPartsCount: 0,

  exportMyPartsLoading: false,
  exportMyPartsError: undefined,

  importMyPartsLoading: false,
  importMyPartsError: undefined,
  importMyPartsSuccess: false,

  deletePartLoading: false,
  deletePartError: undefined,

  clonePartLoading: false,
  clonePartError: undefined,

  updatePartLoading: false,
  updatePartError: undefined,

  createPartLoading: false,
  createPartError: undefined,

  uploadMyPartsAttachmentsLoading: false,
  uploadMyPartsAttachmentsError: undefined,
  uploadMyPartsAttachments: [],

  exportBRPFilesLoading: {},
  exportBRPFilesError: undefined,

  contractMarkAsCompleteLoading: {},
  contractMarkAsCompleteError: undefined,

  qaMarAsReadLoading: -1,
  qaMarkAsReadError: undefined,

  supplierContactsList: [],
  fetchSupplierContactsListLoading: false,
  fetchSupplierContactsListError: undefined,

  inviteSupplierContactLoading: false,
  inviteSupplierContactError: undefined,

  updateSupplierContactsListLoading: false,
  updateSupplierContactsListError: undefined,

  axyaStatsSupplierLoading: false,
  axyaStatsSupplierError: undefined,
  axyaStatsSupplier: undefined,

  portalDataLoading: false,
  portalDataError: undefined,
  portalData: undefined,

  updateRFQCollaboratorsLoading: false,
  updateRFQCollaboratorsError: undefined,

  updateRFQIsPublicLoading: false,
  updateRFQIsPublicError: undefined,

  updateRFQFollowupDateLoading: false,
  updateRFQFollowupDateError: undefined,

  updateRFQTargetedProfilesLoading: false,
  updateRFQTargetedProfilesError: undefined,

  updatePortalQuoteNoQuoteLoading: false,
  updatePortalQuoteNoQuoteError: undefined,

  uploadPortalQuoteFilesLoading: false,
  uploadPortalQuoteFilesError: undefined,

  deletePortalQuoteLoading: false,
  deletePortalQuoteError: undefined,

  createPortalQuoteLoading: false,
  createPortalQuoteError: undefined,

  updatePortalQuoteLoading: false,
  updatePortalQuoteError: undefined,

  exportQuotesComparisonSheetLoading: false,
  exportQuotesComparisonSheetError: undefined,

  sendRevisionLoading: false,
  sendRevisionWithNoteLoading: false,
  revisionSendInProgress: false,
  sendRevisionError: undefined,

  showRevisionStatusBar: false,

  globalSearchResults: [],
  globalSearchLoading: false,
  globalSearchError: undefined,
  globalSearchNotFound: false,

  supplierQuotesLoading: false,
  supplierQuotesError: undefined,
  supplierQuotes: [],
  supplierQuotesCounts: {
    archived_count: 0,
    count: 0,
    on_hold_count: 0,
    ongoing_count: 0,
    waiting_count: 0,
  },

  marketplaceCounts: {
    tenders_total: 0,
    tenders_unseen: 0,
    public_total: 0,
    public_unseen: 0,
    private_total: 0,
    private_unseen: 0,
  },
  marketplaceCountsLoading: false,
  marketplaceCountsError: undefined,
  uploadRfqAttachmentsLoading: false,
  uploadRfqAttachmentsError: undefined,

  presignedS3UrlLoading: false,
  presignedS3UrlError: undefined,
  presignedS3Url: undefined,

  uploadS3FileLoading: false,
  uploadS3FileError: undefined,
  fileUploadProgress: [],
  fileUploadSuccess: undefined,
  fileUploadError: undefined,
  fileUploading: false,

  uploadSinglePartFileLoading: false,
  uploadSinglePartFileError: undefined,

  uploadMultiplePartFilesLoading: false,
  uploadMultiplePartFilesError: undefined,

  addAddendaLoading: false,
  addAddendaError: undefined,

  quoteComparison: undefined,
  quoteComparisonError: undefined,
  quoteComparisonLoading: false,
  quoteComparisonLastQueryRfqId: undefined,

  updateQuoteComparisonConfigError: undefined,
  updateQuoteComparisonConfigLoading: false,

  proceedQuoteComparisonSelectionLoading: false,
  proceedQuoteComparisonSelectionError: undefined,

  sendNewVerificationEmailLoading: false,
  sendNewVerificationEmailError: undefined,

  checkEmailAlreadyExistsLoading: false,
  checkEmailAlreadyExistsError: undefined,

  createEasyRfqLoading: false,
  createEasyRfqError: undefined,

  syncERPQuotationLoading: false,
  syncERPQuotationError: undefined,

  rfqQuestionsStats: undefined,
  rfqQuestionsStatsLoading: false,
  rfqQuestionsStatsError: undefined,

  qaThreadDetails: undefined,
  qaThreadDetailsLoading: false,
  qaThreadDetailsError: undefined,

  uploadQuotationAsBuyerLoading: false,
  uploadQuotationAsBuyerError: undefined,

  uploadQuotationFilesAsBuyerLoading: false,
  uploadQuotationFilesAsBuyerError: undefined,

  internalNotesMutateLoading: false,
  internalNotesMutateError: undefined,
  updateInternalNoteDescLoading: false,
  updateInternalNoteDescError: undefined,
  internalNotes: [],
  getInternalNotesLoading: false,
  getInternalNotesError: undefined,

  assignInternalNoteLoading: false,
  assignInternalNoteError: undefined,
  assignedInternalNote: [],
  sendAirbusConfirmationLoading: false,
  sendAirbusConfirmationError: undefined,
  fetchOTTRequestLoading: false,
  fetchOTTRequestError: undefined,
  OneTimeToken: "",
  getPortalUrlLoading: false,
  getPortalUrlError: undefined,
  portalUrl: "",
};

const workspaceNextSlice = createSlice({
  name: "workspaceNext",
  initialState,
  reducers: {
    fetchSuppliersRequest(state, _action: PayloadAction<string>) {
      state.searchSuppliersLoading = true;
      state.searchSuppliers.type = "";
      state.searchSuppliersError = undefined;
    },
    fetchSuppliersSuccess(state, action: PayloadAction<ASLSupplierSearch>) {
      state.searchSuppliersLoading = false;
      state.searchSuppliers = action.payload;
    },
    fetchSuppliersFailure(state, action: PayloadAction<string>) {
      state.searchSuppliersLoading = false;
      state.searchSuppliersError = new Error(action.payload);
    },
    addASLRequest(state, _action: PayloadAction<ApprovedSupplierInput>) {
      state.addASLLoading = true;
      state.addASLError = undefined;
    },
    addASLSuccess(state, _action: PayloadAction<any>) {
      state.addASLLoading = false;
      state.addASLError = undefined;
    },
    addASLFailure(state, action: PayloadAction<Error>) {
      state.addASLLoading = false;
      state.addASLError = action.payload;
    },
    fetchQATopicsRequest: (state, _action: PayloadAction<FetchTopicsInput>) => {
      state.qaTopicsLoading = true;
      state.qaTopicsError = undefined;
      state.qaTopics = [];
      state.qaTopic = undefined;
    },
    fetchQATopicsFailure: (state, action: PayloadAction<Error>) => {
      state.qaTopicsLoading = false;
      state.qaTopicsError = action.payload;
    },
    fetchQATopicsSuccess: (state, action: PayloadAction<TopicType[]>) => {
      state.qaTopicsLoading = false;
      state.qaTopicsError = undefined;
      state.qaTopics = action.payload;
    },
    fetchQATopicRequest: (state, action: PayloadAction<FetchTopicInput>) => {
      state.qaTopicLoading = true;
      state.qaTopicError = undefined;
      state.qaTopicLastToken = action.payload.token;

      if (!action?.payload?.isNotFirstFetch) {
        state.qaTopic = undefined;
      }
    },
    fetchQATopicFailure: (state, action: PayloadAction<Error>) => {
      state.qaTopicLoading = false;
      state.qaTopicError = action.payload;
    },
    fetchQATopicSuccess: (state, action: PayloadAction<TopicType>) => {
      state.qaTopicLoading = false;
      state.qaTopicError = undefined;
      state.qaTopic = action.payload;
    },
    fetchInternalChatTopicRequest: (
      state,
      _action: PayloadAction<FetchInternalChatTopicInput>
    ) => {
      state.internalChatTopicLoading = true;
      state.internalChatTopicError = undefined;
    },
    fetchInternalChatTopicFailure: (state, action: PayloadAction<Error>) => {
      state.internalChatTopicLoading = false;
      state.internalChatTopicError = action.payload;
    },
    fetchInternalChatTopicSuccess: (
      state,
      action: PayloadAction<TopicType>
    ) => {
      state.internalChatTopicLoading = false;
      state.internalChatTopicError = undefined;
      state.internalChatTopic = action.payload;
    },
    createQATopicRequest: (state, _action: PayloadAction<CreateTopicInput>) => {
      state.createQaTopicLoading = true;
      state.createQaTopicError = undefined;
    },
    createQATopicFailure: (state, action: PayloadAction<Error>) => {
      state.createQaTopicLoading = false;
      state.createQaTopicError = action.payload;
    },
    createQATopicSuccess: (state, action: PayloadAction<TopicType>) => {
      state.createQaTopicLoading = false;
      state.createQaTopicError = undefined;

      if (!Array.isArray(state.qaTopics)) {
        state.qaTopics = [action.payload];
      } else {
        const ix = state.qaTopics.findIndex(
          (topic) => topic?.pk === action.payload?.pk
        );
        if (ix === -1) {
          state.qaTopics.push(action.payload);
        } else {
          state.qaTopics[ix] = action.payload;
        }
      }
    },
    answerQATopicRequest: (state, _action: PayloadAction<AnswerTopicInput>) => {
      state.answerQaTopicLoading = true;
      state.answerQaTopicError = undefined;
    },
    answerQATopicFailure: (state, action: PayloadAction<Error>) => {
      state.answerQaTopicLoading = false;
      state.answerQaTopicError = action.payload;
    },
    answerQATopicSuccess: (state, action: PayloadAction<TopicMessageType>) => {
      state.answerQaTopicLoading = false;
      state.answerQaTopicError = undefined;

      // if it is a single qa update
      if (state?.qaTopic?.pk === action.payload.qa) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        state.qaTopic?.messages?.push(action.payload);
      } else {
        state.qaTopic = undefined;
        state.qaTopics = state.qaTopics.map((topic) => {
          if (topic?.pk === action.payload.qa) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            topic.messages?.push(action.payload);
          }
          return topic;
        });
      }
      if (state?.qaThreadDetails?.pk === action.payload.qa) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        state.qaThreadDetails?.messages?.push(action.payload);
      }
    },

    postInternalChatMessageRequest: (
      state,
      _action: PayloadAction<PostInternalChatMessageInput>
    ) => {
      state.answerQaTopicLoading = true;
      state.answerQaTopicError = undefined;
    },
    postInternalChatMessageFailure: (state, action: PayloadAction<Error>) => {
      state.answerQaTopicLoading = false;
      state.answerQaTopicError = action.payload;
    },
    postInternalChatMessageSuccess: (
      state,
      action: PayloadAction<TopicType>
    ) => {
      state.answerQaTopicLoading = false;
      state.answerQaTopicError = undefined;
      state.internalChatTopic = action.payload;
    },

    qaMarkAsReadRequest: (state, action: PayloadAction<QaMarkAsReadInput>) => {
      state.qaMarAsReadLoading = action.payload.qaId;
      state.answerQaTopicError = initialState.qaMarkAsReadError;
    },
    qaMarkAsReadFilure: (state, action: PayloadAction<Error>) => {
      state.qaMarAsReadLoading = initialState.qaMarAsReadLoading;
      state.answerQaTopicError = action.payload;
    },
    qaMarkAsReadSuccess: (state, action: PayloadAction<QaMarkAsReadInput>) => {
      state.qaMarAsReadLoading = initialState.qaMarAsReadLoading;
      state.qaMarkAsReadError = initialState.qaMarkAsReadError;
      if (state?.qaTopic?.pk === action.payload.qaId) {
        state.qaTopic.unseen_messages_count = 0;
      } else {
        const ix = state.qaTopics.findIndex(
          (item) => item.pk === action.payload.qaId
        );
        if (ix !== -1) state.qaTopics[ix].unseen_messages_count = 0;
      }
      if (state?.internalChatTopic?.pk === action.payload.qaId) {
        state.internalChatTopic.unseen_messages_count = 0;
      }
    },
    contactUsRequest: (state, _action: PayloadAction<ContactUsInput>) => {
      state.contactUsLoading = true;
      state.contactUsError = undefined;
    },
    contactUsFailure: (state, action: PayloadAction<Error>) => {
      state.contactUsLoading = false;
      state.contactUsError = action.payload;
    },
    contactUsSuccess: (state, _action) => {
      state.contactUsLoading = false;
      state.contactUsError = undefined;
    },
    customQuotationExportRequest: (
      state,
      _action: PayloadAction<CustomQuotationExportInput>
    ) => {
      state.customQuotationExportLoading = true;
      state.customQuotationExportError = undefined;
    },
    customQuotationExportFailure: (state, action: PayloadAction<Error>) => {
      state.customQuotationExportLoading = false;
      state.customQuotationExportError = action.payload;
    },
    customQuotationExportSuccess: (state) => {
      state.customQuotationExportLoading = false;
      state.customQuotationExportError = undefined;
    },
    inviteSupplierRequest: (
      state,
      _action: PayloadAction<InviteSupplierInput>
    ) => {
      state.inviteSupplierLoading = true;
      state.inviteSupplierError = undefined;
    },
    inviteSupplierFailure: (state, action: PayloadAction<Error>) => {
      state.inviteSupplierLoading = false;
      state.inviteSupplierError = action.payload;
    },
    inviteSupplierSuccess: (state, _action) => {
      state.inviteSupplierLoading = false;
      state.inviteSupplierError = undefined;
    },
    setEditRfqDescription: (state, action: PayloadAction<boolean>) => {
      state.isEditRfqDescription = action.payload;
    },
    showQuotationVariance: (state, action: PayloadAction<boolean>) => {
      state.showQuotationVariance = action.payload;
    },
    fetchNDAListRequest: (state) => {
      state.ndaListLoading = true;
      state.ndaListError = undefined;
      state.ndaList = [];
    },
    fetchNDAListFailure: (state, action: PayloadAction<Error>) => {
      state.ndaListLoading = false;
      state.ndaListError = action.payload;
    },
    fetchNDAListSuccess: (state, action: PayloadAction<NDA[]>) => {
      state.ndaListLoading = false;
      state.ndaListError = undefined;
      state.ndaList = action.payload;
    },
    updateNDARequest: (state, _action: PayloadAction<UpdateNDAInput>) => {
      state.updateNDALoading = true;
      state.updateNDAError = undefined;
    },
    updateNDAFailure: (state, action: PayloadAction<Error>) => {
      state.updateNDALoading = false;
      state.updateNDAError = action.payload;
    },
    updateNDASuccess: (state, _action: PayloadAction<boolean>) => {
      state.updateNDALoading = false;
      state.updateNDAError = undefined;
    },
    setRfqFormData: (
      state,
      action: PayloadAction<Partial<WorkspaceNextState["rfqForm"]>>
    ) => {
      Object.entries(action.payload || {}).forEach(([key, val]) => {
        (state.rfqForm[key as keyof typeof action.payload] as typeof val) = val;
      });
    },
    resetRfqFormData: (state) => {
      state.rfqForm = initialState.rfqForm;
    },
    fetchPartsRequest: (state, _action?: PayloadAction<FetchPartsInput>) => {
      state.rfqForm.fetchPartsLoading = true;
      state.rfqForm.fetchPartsError = undefined;
    },
    fetchPartsSuccess: (
      state,
      action: PayloadAction<GenericPaginatedData<ProjectCompanyPart>>
    ) => {
      state.rfqForm.fetchPartsLoading = false;
      state.rfqForm.fetchPartsError = undefined;
      state.rfqForm.partsList = state.rfqForm.partsList.concat(
        action.payload.results
      );
      state.rfqForm.partsListNext = action.payload.next || null;
    },
    fetchPartsFailure: (state, action: PayloadAction<Error>) => {
      state.rfqForm.fetchPartsLoading = false;
      state.rfqForm.fetchPartsError = action.payload;
    },
    fetchPartsReset: (state) => {
      state.rfqForm.fetchPartsError = initialState.rfqForm.fetchPartsError;
      state.rfqForm.fetchPartsLoading = initialState.rfqForm.fetchPartsLoading;
      state.rfqForm.partsList = initialState.rfqForm.partsList;
      state.rfqForm.partsListNext = initialState.rfqForm.partsListNext;
    },
    fetchRFQFavoriteSuppliersRequest: (
      state,
      _action?: PayloadAction<FetchRFQFavoriteSuppliersInput>
    ) => {
      state.rfqForm.fetchFavoriteSuppliersLoading = true;
      state.rfqForm.fetchFavoriteSuppliersError = undefined;
    },
    fetchRFQFavoriteSuppliersSuccess: (
      state,
      action: PayloadAction<GenericPaginatedData<RFQSupplierType>>
    ) => {
      state.rfqForm.fetchFavoriteSuppliersLoading = false;
      state.rfqForm.fetchFavoriteSuppliersError = undefined;
      state.rfqForm.favoriteSuppliersList =
        state.rfqForm.favoriteSuppliersList.concat(action.payload.results);
      state.rfqForm.favoriteSuppliersListNext = action.payload.next || null;
      state.rfqForm.favoriteSuppliersListCount =
        action.payload.count ?? state.rfqForm.favoriteSuppliersListCount;
    },
    fetchRFQFavoriteSuppliersFailure: (state, action: PayloadAction<Error>) => {
      state.rfqForm.fetchFavoriteSuppliersLoading = false;
      state.rfqForm.fetchFavoriteSuppliersError = action.payload;
    },
    fetchRFQFavoriteSuppliersReset: (state) => {
      state.rfqForm.fetchFavoriteSuppliersLoading =
        initialState.rfqForm.fetchFavoriteSuppliersLoading;
      state.rfqForm.fetchFavoriteSuppliersError =
        initialState.rfqForm.fetchFavoriteSuppliersError;
      state.rfqForm.favoriteSuppliersList =
        initialState.rfqForm.favoriteSuppliersList;
      state.rfqForm.favoriteSuppliersListNext =
        initialState.rfqForm.favoriteSuppliersListNext;
    },
    updatePartInPartsList: (
      state,
      action: PayloadAction<ProjectCompanyPart>
    ) => {
      const ix = state.rfqForm.partsList.findIndex(
        (part) => part.pk === action.payload.pk
      );
      if (ix >= 0) state.rfqForm.partsList[ix] = action.payload;
    },
    addPartInPartsList: (state, action: PayloadAction<ProjectCompanyPart>) => {
      state.rfqForm.partsList = [action.payload, ...state.rfqForm.partsList];
    },
    updateAdditionalFiles: (
      state,
      action: PayloadAction<Partial<FileData>[]>
    ) => {
      state.rfqForm.additionalFiles = action.payload;
    },

    fetchFavoriteSuppliersRequest: (
      state,
      _action: PayloadAction<FetchFavoriteSupplierInput>
    ) => {
      state.favoriteSuppliersLoading = true;
      state.favoriteSuppliersError = undefined;
      state.favoriteSuppliers = [];
    },
    fetchFavoriteSuppliersFailure: (state, action: PayloadAction<Error>) => {
      state.favoriteSuppliersLoading = false;
      state.favoriteSuppliersError = action.payload;
    },
    fetchFavoriteSuppliersSuccess: (
      state,
      action: PayloadAction<{ results: FavoriteSupplier[]; count: number }>
    ) => {
      state.favoriteSuppliersLoading = false;
      state.favoriteSuppliersError = undefined;
      state.favoriteSuppliers = action.payload.results;
      state.favoriteSuppliersCount =
        action.payload.count ?? state.favoriteSuppliersCount;
    },
    updateFavoriteSupplierRequest: (
      state,
      _action: PayloadAction<Partial<FavoriteSupplier>>
    ) => {
      state.updateFavoriteSupplierLoading = true;
      state.updateFavoriteSupplierError = undefined;
    },
    updateFavoriteSupplierFailure: (state, action: PayloadAction<Error>) => {
      state.updateFavoriteSupplierLoading = false;
      state.updateFavoriteSupplierError = action.payload;
    },
    updateFavoriteSupplierSuccess: (
      state,
      action: PayloadAction<Partial<FavoriteSupplier>>
    ) => {
      state.updateFavoriteSupplierLoading = false;
      state.updateFavoriteSupplierError = undefined;
      state.favoriteSuppliers = state.favoriteSuppliers.map((item) => {
        if (item.pk === action.payload.pk) {
          return { ...item, groups: action.payload.groups || [] };
        }

        return item;
      });
    },
    addFavoriteSupplierRequest: (
      state,
      _action: PayloadAction<AddFavoriteSupplierInput>
    ) => {
      state.addFavoriteSupplierLoading = true;
      state.addFavoriteSupplierError = undefined;
    },
    addFavoriteSupplierFailure: (state, action: PayloadAction<Error>) => {
      state.addFavoriteSupplierLoading = false;
      state.addFavoriteSupplierError = action.payload;
    },
    addFavoriteSupplierSuccess: (state, action: PayloadAction<any>) => {
      state.addFavoriteSupplierLoading = false;
      state.addFavoriteSupplierError = undefined;
      state.favoriteSuppliers = [action.payload, ...state.favoriteSuppliers];
      state.favoriteSuppliersCount = state.favoriteSuppliersCount + 1;
    },
    removeFavoriteSupplierRequest: (state, _action: PayloadAction<number>) => {
      state.removeFavoriteSupplierLoading = true;
      state.removeFavoriteSupplierError = undefined;
    },
    removeFavoriteSupplierFailure: (state, action: PayloadAction<Error>) => {
      state.removeFavoriteSupplierLoading = false;
      state.removeFavoriteSupplierError = action.payload;
    },
    removeFavoriteSupplierSuccess: (state, action: PayloadAction<number>) => {
      state.removeFavoriteSupplierLoading = false;
      state.removeFavoriteSupplierError = undefined;
      // remove from my suppliers
      state.favoriteSuppliers = state.favoriteSuppliers.filter(
        (item) => item.pk !== action.payload
      );
      state.favoriteSuppliersCount = state.favoriteSuppliersCount - 1;
    },
    fetchFavoriteSupplierGroupsRequest: (state) => {
      state.favoriteSupplierGroupsLoading = true;
      state.favoriteSupplierGroupsError = undefined;
      state.favoriteSupplierGroups = [];
    },
    fetchFavoriteSupplierGroupsFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.favoriteSupplierGroupsLoading = false;
      state.favoriteSupplierGroupsError = action.payload;
    },
    fetchFavoriteSupplierGroupsSuccess: (
      state,
      action: PayloadAction<FavoriteSupplierGroup[]>
    ) => {
      state.favoriteSupplierGroupsLoading = false;
      state.favoriteSupplierGroupsError = undefined;
      state.favoriteSupplierGroups = action.payload;
    },
    createFavoriteSupplierGroupRequest: (
      state,
      _action: PayloadAction<CreateFavoriteSupplierGroupInput>
    ) => {
      state.createFavoriteSupplierGroupLoading = true;
      state.createFavoriteSupplierGroupError = undefined;
    },
    createFavoriteSupplierGroupFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.createFavoriteSupplierGroupLoading = false;
      state.createFavoriteSupplierGroupError = action.payload;
    },
    createFavoriteSupplierGroupSuccess: (
      state,
      action: PayloadAction<FavoriteSupplierGroup>
    ) => {
      state.createFavoriteSupplierGroupLoading = false;
      state.createFavoriteSupplierGroupError = undefined;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      state.favoriteSupplierGroups?.unshift(action.payload);
    },
    fetchDraftRFQsRequest: (
      state,
      _action: PayloadAction<FetchDraftRFQsInput>
    ) => {
      state.draftRFQsLoading = true;
      state.draftRFQsError = undefined;
    },
    fetchDraftRFQsFailure: (state, action: PayloadAction<Error>) => {
      state.draftRFQsLoading = false;
      state.draftRFQsError = action.payload;
    },
    fetchDraftRFQsSuccess: (
      state,
      action: PayloadAction<{
        reset?: boolean;
        data: GenericPaginatedData<DraftRFQ>;
      }>
    ) => {
      state.draftRFQsLoading = false;
      state.draftRFQsError = undefined;
      state.draftRFQsCount = action.payload.data.count || 0;
      if (action.payload.reset) state.draftRFQs = action.payload.data.results;
      else
        state.draftRFQs = state.draftRFQs.concat(action.payload.data.results);
    },
    saveDraftRFQRequest: (state, _action: PayloadAction<DraftRFQData>) => {
      state.saveDraftRFQLoading = true;
      state.saveDraftRFQError = undefined;
    },
    saveDraftRFQFailure: (state, action: PayloadAction<Error>) => {
      state.saveDraftRFQLoading = false;
      state.saveDraftRFQError = action.payload;
    },
    saveDraftRFQSuccess: (state, action: PayloadAction<DraftRFQ>) => {
      state.saveDraftRFQLoading = false;
      state.saveDraftRFQError = undefined;
      if (!state.activeDraftRFQ) state.draftRFQsCount++;
      state.activeDraftRFQ = action.payload;
      const ix = state.draftRFQs.findIndex(
        (item) => item.id === action.payload.id
      );
      if (ix === -1) state.draftRFQs.unshift(action.payload);
      else state.draftRFQs[ix] = action.payload;
    },
    setActiveDraftRFQ: (
      state,
      action: PayloadAction<WorkspaceNextState["activeDraftRFQ"]>
    ) => {
      state.activeDraftRFQ = action.payload;
    },
    deleteDraftRFQRequest: (state, _action: PayloadAction<number>) => {
      state.deleteDraftRFQLoading = true;
      state.deleteDraftRFQError = undefined;
    },
    deleteDraftRFQFailure: (state, action: PayloadAction<Error>) => {
      state.deleteDraftRFQLoading = false;
      state.deleteDraftRFQError = action.payload;
    },
    deleteDraftRFQSuccess: (state, action: PayloadAction<number>) => {
      state.deleteDraftRFQLoading = false;
      state.deleteDraftRFQError = undefined;
      state.draftRFQsCount--;
      state.draftRFQs = state.draftRFQs.filter(
        (item) => item.id !== action.payload
      );
    },
    loadDraftRFQRequest: (state, action: PayloadAction<LoadDraftRFQInput>) => {
      state.loadDraftRFQLoading = action.payload.id;
      state.loadDraftRFQError = undefined;
    },
    loadDraftRFQFailure: (state, action: PayloadAction<Error>) => {
      state.loadDraftRFQLoading = -1;
      state.loadDraftRFQError = action.payload;
    },
    loadDraftRFQSuccess: (state, action: PayloadAction<DraftRFQ>) => {
      state.loadDraftRFQLoading = -1;
      state.loadDraftRFQError = undefined;
      state.activeDraftRFQ = action.payload;
    },
    updateDraftRFQCollaboratorsRequest: (
      state,
      _action: PayloadAction<UpdateDraftRFQCollaboratorsInput>
    ) => {
      state.updateDraftRFQCollaboratorsLoading = true;
      state.updateDraftRFQCollaboratorsError = undefined;
    },
    updateDraftRFQCollaboratorsFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateDraftRFQCollaboratorsLoading = false;
      state.updateDraftRFQCollaboratorsError = action.payload;
    },
    updateDraftRFQCollaboratorsSuccess: (
      state,
      action: PayloadAction<GenericUser[]>
    ) => {
      state.updateDraftRFQCollaboratorsLoading = false;
      state.updateDraftRFQCollaboratorsError = undefined;
      if (state.activeDraftRFQ) {
        state.activeDraftRFQ.collaborators = action.payload;
      }
    },
    createDiscordNotifyRequest: (
      state,
      _action: PayloadAction<CreateDiscordNofityInput>
    ) => {
      state.createDiscoryNotifyLoading = true;
      state.createDiscoryNotifyError = undefined;
    },
    createDiscordNotifyFailure: (state, action: PayloadAction<Error>) => {
      state.createDiscoryNotifyLoading = false;
      state.createDiscoryNotifyError = action.payload;
    },
    createDiscordNotifySuccess: (state) => {
      state.createDiscoryNotifyLoading = false;
      state.createDiscoryNotifyError = undefined;
    },
    fetchContractsRequest: (
      state,
      action: PayloadAction<FetchContractsInput>
    ) => {
      state.contractsLoading = true;
      state.contractsError = undefined;
      state.contracts = [];
      state.contractsLastQuery = action.payload.query;
    },
    fetchContractsFailure: (state, action: PayloadAction<Error>) => {
      state.contractsLoading = false;
      state.contractsError = action.payload;
    },
    fetchContractsSuccess: (
      state,
      action: PayloadAction<{ results: any[]; count: number }>
    ) => {
      state.contractsLoading = false;
      state.contractsError = undefined;
      state.contracts = action.payload.results;
      state.contractsCount = action.payload.count;
    },
    fetchTendersRequest: (state) => {
      state.tendersLoading = true;
    },
    fetchTendersFailure: (state, action: PayloadAction<Error>) => {
      state.tendersLoading = false;
      state.tendersError = action.payload;
    },
    fetchTendersSuccess: (
      state,
      action: PayloadAction<{ results: TenderData[] }>
    ) => {
      state.tendersLoading = false;
      state.tenders = action.payload.results;
    },
    fetchMarketplaceCountsRequest: (state) => {
      state.marketplaceCountsLoading = true;
    },
    fetchMarketplaceCountsFailure: (state, action: PayloadAction<Error>) => {
      state.marketplaceCountsLoading = false;
      state.marketplaceCountsError = action.payload;
    },
    fetchMarketplaceCountsSuccess: (
      state,
      action: PayloadAction<MarketplaceCounts>
    ) => {
      state.marketplaceCountsLoading = false;
      state.marketplaceCounts = action.payload;
    },
    fetchMyPartsRequest: (state, action: PayloadAction<FetchMyPartsInput>) => {
      state.myPartsLoading = true;
      state.myPartsError = undefined;
      state.myParts = [];
      state.myPartsLastQuery = action.payload.query;
    },
    fetchMyPartsFailure: (state, action: PayloadAction<Error>) => {
      state.myPartsLoading = false;
      state.myPartsError = action.payload;
    },
    fetchMyPartsSuccess: (
      state,
      action: PayloadAction<{ results: any[]; count: number }>
    ) => {
      state.myPartsLoading = false;
      state.myPartsError = undefined;
      state.myParts = action.payload.results;
      state.myPartsCount = action.payload.count;
    },
    exportMyPartsRequest: (
      state,
      _action: PayloadAction<ExportMyPartsInput>
    ) => {
      state.exportMyPartsLoading = true;
      state.exportMyPartsError = undefined;
    },
    exportMyPartsFailure: (state, action: PayloadAction<Error>) => {
      state.exportMyPartsLoading = false;
      state.exportMyPartsError = action.payload;
    },
    exportMyPartsSuccess: (state) => {
      state.exportMyPartsLoading = false;
      state.exportMyPartsError = undefined;
    },
    importMyPartsRequest: (
      state,
      _action: PayloadAction<ImportMyPartsInput>
    ) => {
      state.importMyPartsLoading = true;
      state.importMyPartsError = undefined;
      state.importMyPartsSuccess = false;
    },
    importMyPartsFailure: (state, action: PayloadAction<Error>) => {
      state.importMyPartsLoading = false;
      state.importMyPartsError = action.payload;
    },
    importMyPartsSuccess: (
      state,
      action: PayloadAction<Partial<ProjectCompanyPart>[]>
    ) => {
      state.importMyPartsLoading = false;
      state.importMyPartsError = undefined;
      state.importMyPartsSuccess = true;

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      action.payload?.forEach((importedPart) => {
        // Immutably Updating Or Adding An Item To An Array
        state.myParts = addOrUpdateField(state.myParts, importedPart);
      });
    },
    deletePartRequest: (
      state,
      _action: PayloadAction<ProjectCompanyPart["pk"] | undefined>
    ) => {
      state.deletePartLoading = true;
      state.deletePartError = undefined;
    },
    deletePartFailure: (state, action: PayloadAction<Error>) => {
      state.deletePartLoading = false;
      state.deletePartError = action.payload;
    },
    deletePartSuccess: (
      state,
      action: PayloadAction<ProjectCompanyPart["pk"] | undefined>
    ) => {
      state.deletePartLoading = false;
      state.deletePartError = undefined;
      state.myParts = state.myParts.filter(
        (item) => item.pk !== action.payload
      );
      state.myPartsCount = state.myPartsCount - 1;
    },
    clonePartRequest: (
      state,
      _action: PayloadAction<ProjectCompanyPart["pk"] | undefined>
    ) => {
      state.clonePartLoading = true;
      state.clonePartError = undefined;
    },
    clonePartFailure: (state, action: PayloadAction<Error>) => {
      state.clonePartLoading = false;
      state.clonePartError = action.payload;
    },
    clonePartSuccess: (state, action: PayloadAction<ProjectCompanyPart>) => {
      state.clonePartLoading = false;
      state.clonePartError = undefined;

      state.myParts = addOrUpdateField(state.myParts, action.payload);
      state.myPartsCount = state.myPartsCount + 1;
    },
    updatePartRequest: (
      state,
      _action: PayloadAction<Partial<ProjectUpdatePartInput>>
    ) => {
      state.updatePartLoading = true;
      state.updatePartError = undefined;
    },
    updatePartFailure: (state, action: PayloadAction<Error>) => {
      state.updatePartLoading = false;
      state.updatePartError = action.payload;
    },
    updatePartSuccess: (
      state,
      action: PayloadAction<Partial<ProjectCompanyPart>>
    ) => {
      state.updatePartLoading = false;
      state.updatePartError = undefined;

      state.myParts = state.myParts.map((item) => {
        if (item.pk === action.payload.pk) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },
    createPartRequest: (
      state,
      _action: PayloadAction<Partial<ProjectCreatePartInput>>
    ) => {
      state.createPartLoading = true;
      state.createPartError = undefined;
    },
    createPartFailure: (state, action: PayloadAction<Error>) => {
      state.createPartLoading = false;
      state.createPartError = action.payload;
    },
    createPartSuccess: (state, action: PayloadAction<ProjectCompanyPart>) => {
      state.createPartLoading = false;
      state.createPartError = undefined;

      state.myParts.unshift(action.payload);
    },
    uploadMyPartsAttachmentsRequest: (
      state,
      _action: PayloadAction<UploadMyPartsAttachmentsInput>
    ) => {
      state.uploadMyPartsAttachmentsLoading = true;
      state.uploadMyPartsAttachmentsError = undefined;
    },
    uploadMyPartsAttachmentsFailure: (state, action: PayloadAction<Error>) => {
      state.uploadMyPartsAttachmentsLoading = false;
      state.uploadMyPartsAttachmentsError = action.payload;
    },
    uploadMyPartsAttachmentsSuccess: (
      state,
      action: PayloadAction<PartUpload[]>
    ) => {
      state.uploadMyPartsAttachmentsLoading = false;
      state.uploadMyPartsAttachmentsError = undefined;
      state.uploadMyPartsAttachments = action.payload;
    },
    uploadMyPartsAttachmentsReset: (state) => {
      state.uploadMyPartsAttachmentsLoading = false;
      state.uploadMyPartsAttachmentsError = undefined;
      state.uploadMyPartsAttachments = [];
    },
    exportBRPFilesRequest: (
      state,
      action: PayloadAction<ExportBRPFilesInput>
    ) => {
      state.exportBRPFilesLoading[action.payload.rfqId] = true;
      state.exportBRPFilesError = undefined;
    },
    exportBRPFilesFailure: (
      state,
      action: PayloadAction<{ rfqId: string; error: Error }>
    ) => {
      state.exportBRPFilesLoading[action.payload.rfqId] = false;
      state.exportBRPFilesError = action.payload.error;
    },
    exportBRPFilesSuccess: (state, action: PayloadAction<string>) => {
      state.exportBRPFilesLoading[action.payload] = false;
      state.exportBRPFilesError = undefined;
    },
    contractMarkAsCompleteRequest: (
      state,
      action: PayloadAction<ContractMarkAsCompleteInput>
    ) => {
      state.contractMarkAsCompleteLoading[action.payload.pk] = true;
      state.contractMarkAsCompleteError = undefined;
    },
    contractMarkAsCompleteFailure: (
      state,
      action: PayloadAction<{ pk: number; error: Error }>
    ) => {
      state.contractMarkAsCompleteLoading[action.payload.pk] = false;
      state.contractMarkAsCompleteError = action.payload.error;
    },
    contractMarkAsCompleteSuccess: (state, action: PayloadAction<number>) => {
      state.contractMarkAsCompleteLoading[action.payload] = false;
      state.contractMarkAsCompleteError = undefined;
      state.contracts = state.contracts.filter(
        (item) => item.pk !== action.payload
      );
    },
    fetchSupplierContactsListRequest: (
      state,
      _action: PayloadAction<number>
    ) => {
      state.fetchSupplierContactsListLoading = true;
      state.fetchSupplierContactsListError = undefined;
    },
    fetchSupplierContactsListFailure: (state, action: PayloadAction<Error>) => {
      state.fetchSupplierContactsListLoading = false;
      state.fetchSupplierContactsListError = action.payload;
    },
    fetchSupplierContactsListSuccess: (
      state,
      action: PayloadAction<GenericUser[]>
    ) => {
      state.fetchSupplierContactsListLoading = false;
      state.fetchSupplierContactsListError = undefined;
      state.supplierContactsList = action.payload;
    },
    inviteSupplierContactRequest: (
      state,
      _action: PayloadAction<InviteSupplierContactInput>
    ) => {
      state.inviteSupplierContactLoading = true;
      state.inviteSupplierContactError = undefined;
    },
    inviteSupplierContactFailure: (state, action: PayloadAction<Error>) => {
      state.inviteSupplierContactLoading = false;
      state.inviteSupplierContactError = action.payload;
    },
    inviteSupplierContactSuccess: (
      state,
      action: PayloadAction<GenericUser>
    ) => {
      state.inviteSupplierContactLoading = false;
      state.inviteSupplierContactError = undefined;
      state.supplierContactsList = [
        ...state.supplierContactsList,
        action.payload,
      ];
    },
    updateSupplierContactsListRequest: (
      state,
      _action: PayloadAction<UpdateSupplierContactsListInput>
    ) => {
      state.updateSupplierContactsListLoading = true;
      state.updateSupplierContactsListError = undefined;
    },
    updateSupplierContactsListFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateSupplierContactsListLoading = false;
      state.updateSupplierContactsListError = action.payload;
    },
    updateSupplierContactsListSuccess: (
      state,
      action: PayloadAction<FavoriteSupplier>
    ) => {
      state.updateSupplierContactsListLoading = false;
      state.updateSupplierContactsListError = undefined;
      const ix = state.favoriteSuppliers.findIndex(
        (item) => item.pk === action.payload.pk
      );
      if (ix > -1) {
        state.favoriteSuppliers[ix] = action.payload;
      }
    },
    fetchAxyaRFQStatsRequest: (state) => {
      state.axyaStatsSupplierLoading = true;
      state.axyaStatsSupplierError = undefined;
      state.axyaStatsSupplier = undefined;
    },
    fetchAxyaRFQStatsFailure: (state, action: PayloadAction<Error>) => {
      state.axyaStatsSupplierLoading = false;
      state.axyaStatsSupplierError = action.payload;
    },
    fetchAxyaRFQStatsSuccess: (state, action: PayloadAction<AxyaRFQStats>) => {
      state.axyaStatsSupplierLoading = false;
      state.axyaStatsSupplierError = undefined;
      state.axyaStatsSupplier = action.payload;
    },
    fetchPortalDataRequest: (
      state,
      _action: PayloadAction<FetchPortalDataInput>
    ) => {
      state.portalDataLoading = true;
      state.portalDataError = undefined;
      state.portalData = undefined;
    },
    fetchPortalDataFailure: (state, action: PayloadAction<Error>) => {
      state.portalDataLoading = false;
      state.portalDataError = action.payload;
    },
    fetchPortalDataSuccess: (state, action: PayloadAction<PortalData>) => {
      state.portalDataLoading = false;
      state.portalDataError = undefined;
      state.portalData = action.payload;
    },
    updatePortalQuoteNoQuoteStatusRequest: (
      state,
      _action: PayloadAction<UpdateQuoteNoQuoteStatusInput>
    ) => {
      state.updatePortalQuoteNoQuoteLoading = true;
      state.updatePortalQuoteNoQuoteError = undefined;
    },
    updatePortalQuoteNoQuoteStatusFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updatePortalQuoteNoQuoteLoading = false;
      state.updatePortalQuoteNoQuoteError = action.payload;
    },
    updatePortalQuoteNoQuoteStatusSuccess: (
      state,
      action: PayloadAction<UpdateQuoteNoQuoteStatusInput["type"]>
    ) => {
      state.updatePortalQuoteNoQuoteLoading = false;
      state.updatePortalQuoteNoQuoteError = undefined;
      state.portalData!.has_itb = action.payload === "itb";
      state.portalData!.has_itnb = action.payload === "itnb";
    },
    updateRFQCollaboratorsRequest: (
      state,
      _action: PayloadAction<UpdateRFQCollaboratorsInput>
    ) => {
      state.updateRFQCollaboratorsLoading = true;
      state.updateRFQCollaboratorsError = undefined;
    },
    updateRFQCollaboratorsFailure: (state, action: PayloadAction<Error>) => {
      state.updateRFQCollaboratorsLoading = false;
      state.updateRFQCollaboratorsError = action.payload;
    },
    updateRFQCollaboratorsSuccess: (state) => {
      state.updateRFQCollaboratorsLoading = false;
      state.updateRFQCollaboratorsError = undefined;
    },
    updateRFQIsPublicRequest: (
      state,
      _action: PayloadAction<UpdateRFQIsPublicInput>
    ) => {
      state.updateRFQIsPublicLoading = true;
      state.updateRFQIsPublicError = undefined;
    },
    updateRFQIsPublicFailure: (state, action: PayloadAction<Error>) => {
      state.updateRFQIsPublicLoading = false;
      state.updateRFQIsPublicError = action.payload;
    },
    updateRFQIsPublicSuccess: (state) => {
      state.updateRFQIsPublicLoading = false;
      state.updateRFQIsPublicError = undefined;
    },
    updateRFQFollowupDateRequest: (
      state,
      _action: PayloadAction<UpdateRFQFollowupDateInput>
    ) => {
      state.updateRFQFollowupDateLoading = true;
      state.updateRFQFollowupDateError = undefined;
    },
    updateRFQFollowupDateFailure: (state, action: PayloadAction<Error>) => {
      state.updateRFQFollowupDateLoading = false;
      state.updateRFQFollowupDateError = action.payload;
    },
    updateRFQFollowupDateSuccess: (state) => {
      state.updateRFQFollowupDateLoading = false;
      state.updateRFQFollowupDateError = undefined;
    },
    updateRFQTargetedProfilesRequest: (
      state,
      _action: PayloadAction<UpdateRFQTargetedProfilesInput>
    ) => {
      state.updateRFQTargetedProfilesLoading = true;
      state.updateRFQTargetedProfilesError = undefined;
    },
    updateRFQTargetedProfilesFailure: (state, action: PayloadAction<Error>) => {
      state.updateRFQTargetedProfilesLoading = false;
      state.updateRFQTargetedProfilesError = action.payload;
    },
    updateRFQTargetedProfilesSuccess: (state) => {
      state.updateRFQTargetedProfilesLoading = false;
      state.updateRFQTargetedProfilesError = undefined;
    },
    uploadPortalQuoteFilesRequest: (
      state,
      _action: PayloadAction<UploadPortalQuoteFilesInput>
    ) => {
      state.uploadPortalQuoteFilesLoading = true;
      state.uploadPortalQuoteFilesError = undefined;
    },
    uploadPortalQuoteFilesFailure: (state, action: PayloadAction<Error>) => {
      state.uploadPortalQuoteFilesLoading = false;
      state.uploadPortalQuoteFilesError = action.payload;
    },
    uploadPortalQuoteFilesSuccess: (
      state,
      action: PayloadAction<PartUpload[]>
    ) => {
      state.uploadPortalQuoteFilesLoading = false;
      state.uploadPortalQuoteFilesError = undefined;
      state.uploadPortalQuoteFiles! = action.payload;
    },
    createPortalQuoteRequest: (
      state,
      _action: PayloadAction<CreatePortalQuoteInput>
    ) => {
      state.createPortalQuoteLoading = true;
      state.createPortalQuoteError = undefined;
    },
    createPortalQuoteFailure: (state, action: PayloadAction<Error>) => {
      state.createPortalQuoteLoading = false;
      state.createPortalQuoteError = action.payload;
    },
    createPortalQuoteSuccess: (state, action: PayloadAction<PortalData>) => {
      state.createPortalQuoteLoading = false;
      state.createPortalQuoteError = undefined;
      state.portalData!.quotation = action.payload.quotation;
    },
    updatePortalQuoteRequest: (
      state,
      _action: PayloadAction<UpdatePortalQuoteInput>
    ) => {
      state.updatePortalQuoteLoading = true;
      state.updatePortalQuoteError = undefined;
    },
    updatePortalQuoteFailure: (state, action: PayloadAction<Error>) => {
      state.updatePortalQuoteLoading = false;
      state.updatePortalQuoteError = action.payload;
    },
    updatePortalQuoteSuccess: (state, action: PayloadAction<PortalData>) => {
      state.updatePortalQuoteLoading = false;
      state.updatePortalQuoteError = undefined;
      state.portalData!.quotation = action.payload.quotation;
    },
    deletePortalQuoteRequest: (
      state,
      _action: PayloadAction<DeletePortalQuoteFileInput>
    ) => {
      state.deletePortalQuoteLoading = true;
      state.deletePortalQuoteError = undefined;
    },
    deletePortalQuoteFailure: (state, action: PayloadAction<Error>) => {
      state.deletePortalQuoteLoading = false;
      state.deletePortalQuoteError = action.payload;
    },
    deletePortalQuoteSuccess: (state) => {
      state.deletePortalQuoteLoading = false;
      state.deletePortalQuoteError = undefined;
    },
    exportQuotesComparisonSheetRequest: (
      state,
      _action: PayloadAction<ExportQuotesComparisonSheetInput>
    ) => {
      state.exportQuotesComparisonSheetLoading = true;
      state.exportQuotesComparisonSheetError = undefined;
    },
    exportQuotesComparisonSheetFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.exportQuotesComparisonSheetLoading = false;
      state.exportQuotesComparisonSheetError = action.payload;
    },
    exportQuotesComparisonSheetSuccess: (state) => {
      state.exportQuotesComparisonSheetLoading = false;
      state.exportQuotesComparisonSheetError = undefined;
    },
    sendRevisionRequest: (
      state,
      _action: PayloadAction<SendRevisionNoteInput>
    ) => {
      if (!_action.payload.revisionWithoutNote) {
        state.sendRevisionWithNoteLoading = true;
      } else {
        state.sendRevisionLoading = true;
      }
      state.revisionSendInProgress = true;
      state.sendRevisionError = undefined;
    },
    sendRevisionFailure: (state, action: PayloadAction<Error>) => {
      state.sendRevisionLoading = false;
      state.sendRevisionWithNoteLoading = false;
      state.revisionSendInProgress = false;
      state.sendRevisionError = action.payload;
    },
    sendRevisionSuccess: (state) => {
      state.sendRevisionLoading = false;
      state.sendRevisionWithNoteLoading = false;
      state.revisionSendInProgress = false;
      state.sendRevisionError = undefined;
    },
    setShowRevisionStatusBar: (state, action: PayloadAction<boolean>) => {
      state.showRevisionStatusBar = action.payload;
    },
    globalSearchRequest: (state, _action: PayloadAction<GlobalSearchInput>) => {
      state.globalSearchResults = initialState.globalSearchResults;
      state.globalSearchLoading = true;
      state.globalSearchError = undefined;
      state.globalSearchNotFound = initialState.globalSearchNotFound;
    },
    globalSearchFailure: (state, action: PayloadAction<Error>) => {
      state.globalSearchLoading = false;
      state.globalSearchError = action.payload;
    },
    globalSearchSuccess: (
      state,
      action: PayloadAction<GlobalSearchResult[]>
    ) => {
      state.globalSearchResults = action.payload;
      state.globalSearchLoading = false;
      state.globalSearchError = undefined;
      state.globalSearchNotFound = action.payload.length === 0;
    },
    clearGlobalSearch: (state) => {
      state.globalSearchResults = initialState.globalSearchResults;
      state.globalSearchNotFound = initialState.globalSearchNotFound;
    },
    fetchSupplierQuotesRequest: (
      state,
      _action: PayloadAction<FetchSupplierQuotesInput>
    ) => {
      state.supplierQuotesLoading = true;
      state.supplierQuotesError = undefined;
      state.supplierQuotes = undefined;
    },
    fetchSupplierQuotesFailure: (state, action: PayloadAction<Error>) => {
      state.supplierQuotesLoading = false;
      state.supplierQuotesError = action.payload;
    },
    fetchSupplierQuotesSuccess: (
      state,
      action: PayloadAction<{ results: any[] } & SupplierQuotesCounts>
    ) => {
      const { results, ...counts } = action.payload;
      state.supplierQuotesLoading = false;
      state.supplierQuotesError = undefined;
      state.supplierQuotes = results;
      state.supplierQuotesCounts = counts;
    },
    fetchPresignedUrlRequest: (
      state,
      _action: PayloadAction<FetchPresignedS3Input>
    ) => {
      state.presignedS3Url = undefined;
      state.presignedS3UrlLoading = true;
      state.presignedS3UrlError = undefined;
    },
    fetchPresignedUrlFailure: (state, action: PayloadAction<Error>) => {
      state.presignedS3UrlLoading = false;
      state.presignedS3UrlError = action.payload;
    },
    fetchPresignedUrlSuccess: (
      state,
      action: PayloadAction<PresignedS3Url>
    ) => {
      state.presignedS3UrlLoading = false;
      state.presignedS3Url = action.payload;
    },
    uploadAddendaAttachmentsRequeset: (
      state,
      _action: PayloadAction<UploadAddendaAttachmentsInput>
    ) => {
      state.addAddendaLoading = true;
      state.addAddendaError = undefined;
    },
    uploadRfqAttachmentsRequest: (
      state,
      _action: PayloadAction<UploadRfqAttachmentsInput>
    ) => {
      state.uploadRfqAttachmentsLoading = true;
      state.uploadRfqAttachmentsError = undefined;
    },
    uploadRfqAttachmentsFailure: (state, action: PayloadAction<Error>) => {
      state.uploadRfqAttachmentsLoading = false;
      state.uploadRfqAttachmentsError = action.payload;
    },
    uploadRfqAttachmentsSuccess: (state) => {
      state.uploadRfqAttachmentsLoading = false;
      state.uploadRfqAttachmentsError = undefined;
    },
    updateFileUploadProgress: (
      state,
      action: PayloadAction<FileChunkUploadProgress>
    ) => {
      const { fileName, partNumber, uploaded, total, pqueue, source } =
        action.payload;
      const fileIndex = state.fileUploadProgress.findIndex(
        (item) => item.fileName === fileName
      );

      if (fileIndex === -1) {
        // New file, initialize its progress tracking
        const newFileProgress: FileUploadProgress = {
          fileName,
          uploaded,
          total,
          chunksUploaded: [],
          percent: 0,
          pqueue,
          sources: [],
        };
        state.fileUploadProgress.push(newFileProgress);
      } else {
        // Existing file, update its progress
        const fileProgress = state.fileUploadProgress[fileIndex];
        fileProgress.chunksUploaded[partNumber] = uploaded;
        fileProgress.sources[partNumber] = source;

        const totalUploaded = fileProgress.chunksUploaded.reduce(
          (acc, cur) => acc + cur,
          0
        );
        fileProgress.uploaded = totalUploaded;
        fileProgress.percent = Math.round(
          (totalUploaded * 100) / fileProgress.total
        );

        // Update the entry in the state
        state.fileUploadProgress[fileIndex] = fileProgress;
      }

      // Update state flags
      state.fileUploading = true;
      state.fileUploadError = undefined;
      state.fileUploadSuccess = undefined;
    },
    fileChunkUploadSuccess: (
      state,
      _action: PayloadAction<FileChunkUploadSuccess>
    ) => {
      state.fileUploading = state.fileUploading;
    },
    fileUploadSuccess: (state) => {
      state.fileUploading = false;
    },
    fileUploadError: (state, action: PayloadAction<Error>) => {
      state.fileUploading = false;
      state.fileUploadError = action.payload;
    },
    resetFileUploadProgress: (state) => {
      state.fileUploading = false;
      state.fileUploadError = undefined;
      state.fileUploadSuccess = undefined;
      state.fileUploadProgress = [];
    },
    removeFileUploadProgress: (
      state,
      action: PayloadAction<DeleteFileUploadProgress>
    ) => {
      const fileToDelete = state.fileUploadProgress.find(
        (item) => item.fileName === action.payload.filesName
      );
      if (fileToDelete) {
        if (fileToDelete.sources) {
          fileToDelete.sources.forEach((item) =>
            item.cancel("Canceled by user")
          );
        }
        if (fileToDelete.pqueue) {
          fileToDelete.pqueue.clear();
        }
      }
      state.fileUploadProgress = state.fileUploadProgress.filter(
        (item) => action.payload.filesName !== item.fileName
      );
    },
    uploadSinglePartFileRequest: (
      state,
      _action: PayloadAction<uploadSinglePartFileInput>
    ) => {
      state.uploadSinglePartFileLoading = true;
      state.uploadSinglePartFileError = undefined;
    },
    uploadSinglePartFileRequestFailure: (
      state,
      action: PayloadAction<Error | undefined>
    ) => {
      state.uploadSinglePartFileLoading = false;
      state.uploadSinglePartFileError = action.payload;
    },
    uploadSinglePartFileRequestSuccess: (state) => {
      state.uploadSinglePartFileLoading = false;
      state.uploadSinglePartFileError = undefined;
    },
    uploadMultiplePartFileRequest: (
      state,
      _action: PayloadAction<uploadMultiplePartFilesInput>
    ) => {
      state.uploadMultiplePartFilesLoading = true;
      state.uploadMultiplePartFilesError = undefined;
    },
    uploadMultiplePartFileRequestFailure: (
      state,
      action: PayloadAction<Error | undefined>
    ) => {
      state.uploadMultiplePartFilesLoading = false;
      state.uploadMultiplePartFilesError = action.payload;
    },
    uploadMultiplePartFileRequestSuccess: (state) => {
      state.uploadMultiplePartFilesLoading = false;
      state.uploadMultiplePartFilesError = undefined;
    },
    addAddendaRequest: (state, _action: PayloadAction<AddAddendaInput>) => {
      state.addAddendaLoading = true;
      state.addAddendaError = undefined;
    },
    addAddendaFailure: (state, action: PayloadAction<Error | undefined>) => {
      state.addAddendaLoading = false;
      state.addAddendaError = action.payload;
    },
    addAddendaSuccess: (state) => {
      state.addAddendaLoading = false;
      state.addAddendaError = undefined;
    },
    fetchQuoteComparisonRequest: (
      state,
      action: PayloadAction<FetchQuoteComparisonInput>
    ) => {
      state.quoteComparisonLoading = true;
      state.quoteComparisonError = undefined;
      state.quoteComparison = undefined;
      state.quoteComparisonLastQueryRfqId = action.payload.rfq_id;
    },
    fetchQuoteComparisonFailure: (state, action: PayloadAction<Error>) => {
      state.quoteComparisonLoading = false;
      state.quoteComparisonError = action.payload;
      state.quoteComparisonLastQueryRfqId = undefined;
    },
    fetchQuoteComparisonSuccess: (state, action: PayloadAction<any>) => {
      state.quoteComparisonLoading = false;
      state.quoteComparisonError = undefined;
      state.quoteComparison = action.payload;
    },
    updateQuoteComparisonConfigRequest: (
      state,
      _action: PayloadAction<UpdateQuoteComparisonConfigInput>
    ) => {
      state.updateQuoteComparisonConfigLoading = true;
      state.updateQuoteComparisonConfigError = undefined;
    },
    updateQuoteComparisonConfigFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateQuoteComparisonConfigLoading = false;
      state.updateQuoteComparisonConfigError = action.payload;
    },
    updateQuoteComparisonConfigSuccess: (
      state,
      action: PayloadAction<QCResponse["config"]["selected"]>
    ) => {
      state.updateQuoteComparisonConfigLoading = false;
      state.updateQuoteComparisonConfigError = undefined;

      state.quoteComparison = {
        ...state.quoteComparison!,
        config: {
          ...state.quoteComparison?.config!,
          selected: action.payload,
        },
      };
    },
    proceedQuoteComparisonSelectionRequest: (
      state,
      _action: PayloadAction<ProceedQuoteComparisonSelectionInput>
    ) => {
      state.proceedQuoteComparisonSelectionLoading = true;
      state.proceedQuoteComparisonSelectionError = undefined;
    },
    proceedQuoteComparisonSelectionFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.proceedQuoteComparisonSelectionLoading = false;
      state.proceedQuoteComparisonSelectionError = action.payload;
    },
    proceedQuoteComparisonSelectionSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.proceedQuoteComparisonSelectionLoading = false;
      state.proceedQuoteComparisonSelectionError = undefined;
    },
    sendNewVetificationEmailRequest: (
      state,
      _action: PayloadAction<SendNewVerificationEmailInput>
    ) => {
      state.sendNewVerificationEmailLoading = true;
      state.sendNewVerificationEmailError = undefined;
    },
    sendNewVetificationEmailFailure: (state, action: PayloadAction<Error>) => {
      state.sendNewVerificationEmailLoading = false;
      state.sendNewVerificationEmailError = action.payload;
    },
    sendNewVetificationEmailSuccess: (state) => {
      state.sendNewVerificationEmailLoading = false;
      state.sendNewVerificationEmailError = undefined;
    },

    checkEmailAlreadyExistsRequest: (
      state,
      _action: PayloadAction<CheckEmailAlreadyExistsInput>
    ) => {
      state.checkEmailAlreadyExistsLoading = true;
      state.checkEmailAlreadyExistsError = undefined;
    },
    checkEmailAlreadyExistsFailure: (state, action: PayloadAction<Error>) => {
      state.checkEmailAlreadyExistsLoading = false;
      state.checkEmailAlreadyExistsError = action.payload;
    },
    checkEmailAlreadyExistsSuccess: (state) => {
      state.checkEmailAlreadyExistsLoading = false;
      state.checkEmailAlreadyExistsError = undefined;
    },

    createEasyRfqRequest: (
      state,
      _action: PayloadAction<CreateEasyRfqInput>
    ) => {
      state.createEasyRfqLoading = true;
      state.createEasyRfqError = undefined;
    },
    createEasyRfqFailure: (state, action: PayloadAction<Error>) => {
      state.createEasyRfqLoading = false;
      state.createEasyRfqError = action.payload;
    },
    createEasyRfqSuccess: (state) => {
      state.createEasyRfqLoading = false;
      state.createEasyRfqError = undefined;
    },

    syncERPQuotationRequest: (
      state,
      _action: PayloadAction<SyncERPQuotationInput>
    ) => {
      state.syncERPQuotationLoading = true;
      state.syncERPQuotationError = undefined;
    },
    syncERPQuotationFailure: (state, action: PayloadAction<Error>) => {
      state.syncERPQuotationLoading = false;
      state.syncERPQuotationError = action.payload;
    },
    syncERPQuotationSuccess: (state) => {
      state.syncERPQuotationLoading = false;
      state.syncERPQuotationError = undefined;
    },

    fetchRFQQuestionsStatsRequest: (
      state,
      _action: PayloadAction<FetchRFQQuestionsStatsInput>
    ) => {
      state.rfqQuestionsStatsLoading = true;
      state.rfqQuestionsStatsError = undefined;
    },
    fetchRFQQuestionsStatsFailure: (state, action: PayloadAction<Error>) => {
      state.rfqQuestionsStatsLoading = false;
      state.rfqQuestionsStatsError = action.payload;
    },
    fetchRFQQuestionsStatsSuccess: (
      state,
      action: PayloadAction<RFQQuestionsStats[]>
    ) => {
      state.rfqQuestionsStatsLoading = false;
      state.rfqQuestionsStatsError = undefined;
      state.rfqQuestionsStats = action.payload;
    },

    fetchQAThreadDetailsRequest: (
      state,
      _action: PayloadAction<FetchQAThreadDetailsInput>
    ) => {
      state.qaThreadDetailsLoading = true;
      state.qaThreadDetailsError = undefined;
    },
    fetchQAThreadDetailsFailure: (state, action: PayloadAction<Error>) => {
      state.qaThreadDetailsLoading = false;
      state.qaThreadDetailsError = action.payload;
    },
    fetchQAThreadDetailsSuccess: (state, action: PayloadAction<TopicType>) => {
      state.qaThreadDetailsLoading = false;
      state.qaThreadDetailsError = undefined;
      state.qaThreadDetails = action.payload;
    },

    uploadQuotationAsBuyerRequest: (
      state,
      _action: PayloadAction<UploadQuotationAsBuyerInput>
    ) => {
      state.uploadQuotationAsBuyerLoading = true;
      state.uploadQuotationAsBuyerError = undefined;
    },
    uploadQuotationAsBuyerFailure: (state, action: PayloadAction<Error>) => {
      state.uploadQuotationAsBuyerLoading = false;
      state.uploadQuotationAsBuyerError = action.payload;
    },
    uploadQuotationAsBuyerSuccess: (state, action: PayloadAction<any>) => {
      state.uploadQuotationAsBuyerLoading = false;
      state.uploadQuotationAsBuyerError = undefined;
    },

    uploadQuotationFileAsBuyerRequest: (
      state,
      _action: PayloadAction<UploadQuotationFilesAsBuyerInput>
    ) => {
      state.uploadQuotationFilesAsBuyerLoading = true;
      state.uploadQuotationFilesAsBuyerError = undefined;
    },
    uploadQuotationFileAsBuyerFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.uploadQuotationFilesAsBuyerLoading = false;
      state.uploadQuotationFilesAsBuyerError = action.payload;
    },
    uploadQuotationFileAsBuyerSuccess: (state) => {
      state.uploadQuotationFilesAsBuyerLoading = false;
      state.uploadQuotationFilesAsBuyerError = undefined;
    },
    internalNotesMutateRequest: (
      state,
      _action: PayloadAction<InternalNotePayload>
    ) => {
      //incase it's a save description button's loading only
      if (_action.payload.hasOwnProperty("updateNoteDescription")) {
        state.updateInternalNoteDescLoading = true;
        state.updateInternalNoteDescError = undefined;
        return;
      }
      state.internalNotesMutateLoading = true;
      state.internalNotesMutateError = undefined;
    },
    internalNotesMutateFailure: (state, action: PayloadAction<Error>) => {
      if (action.payload.hasOwnProperty("updateNoteDescription")) {
        state.updateInternalNoteDescLoading = false;
        state.updateInternalNoteDescError = action.payload;
        return;
      }
      state.internalNotesMutateLoading = false;
      state.internalNotesMutateError = action.payload;
    },
    internalNotesMutateSuccess: (state, action) => {
      state.updateInternalNoteDescLoading = false;
      state.updateInternalNoteDescError = undefined;

      state.internalNotesMutateLoading = false;
      state.internalNotesMutateError = undefined;
      state.internalNotes = [...state.internalNotes, action.payload];
    },

    getInternalNotesRequest: (state, _action: PayloadAction<any>) => {
      state.getInternalNotesLoading = true;
      state.getInternalNotesError = undefined;
    },
    getInternalNotesFailure: (state, action: PayloadAction<Error>) => {
      state.getInternalNotesLoading = false;
      state.getInternalNotesError = action.payload;
    },
    getInternalNotesSuccess: (state, action) => {
      state.getInternalNotesLoading = false;
      state.getInternalNotesError = undefined;
      state.internalNotes = action.payload;
    },

    assignInternalNoteRequest: (
      state,
      _action: PayloadAction<SelectInternalNote>
    ) => {
      state.assignInternalNoteLoading = true;
      state.assignInternalNoteError = undefined;
    },
    assignInternalNoteFailure: (state, action: PayloadAction<Error>) => {
      state.assignInternalNoteLoading = false;
      state.assignInternalNoteError = action.payload;
    },
    assignInternalNoteSuccess: (state, action) => {
      state.assignInternalNoteLoading = false;
      state.assignInternalNoteError = undefined;
      state.assignedInternalNote = action.payload;
    },
    sendAirbusConfirmationRequest: (
      state,
      _action: PayloadAction<SendAirbusConfirmationInput>
    ) => {
      state.sendAirbusConfirmationLoading = true;
      state.sendAirbusConfirmationError = undefined;
    },
    sendAirbusConfirmationFailure: (state, action: PayloadAction<Error>) => {
      state.sendAirbusConfirmationLoading = false;
      state.sendAirbusConfirmationError = action.payload;
    },
    sendAirbusConfirmationSuccess: (state) => {
      state.sendAirbusConfirmationLoading = false;
      state.sendAirbusConfirmationError = undefined;
    },
    fetchOTTRequest: (state) => {
      state.fetchOTTRequestLoading = true;
      state.fetchOTTRequestError = undefined;
    },
    fetchOTTRequestFailure: (state, action: PayloadAction<Error>) => {
      state.fetchOTTRequestLoading = false;
      state.fetchOTTRequestError = action.payload;
    },
    fetchOTTRequestSuccess: (state, action) => {
      state.fetchOTTRequestLoading = false;
      state.fetchOTTRequestError = undefined;
      state.OneTimeToken = action.payload;
    },
    resetOTT: (state) => {
      state.OneTimeToken = "";
    },
    getPortalUrlRequest: (state, _action: PayloadAction<CreatePortalUrl>) => {
      state.getPortalUrlLoading = true;
      state.getPortalUrlError = undefined;
    },
    getPortalUrlFailure: (state, action: PayloadAction<Error>) => {
      state.getPortalUrlLoading = false;
      state.getPortalUrlError = action.payload;
    },
    getPortalUrlSuccess: (state, action) => {
      state.getPortalUrlLoading = false;
      state.getPortalUrlError = undefined;
      state.portalUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state) => {
      state.updatePartLoading = false;
      state.updatePartError = undefined;
      state.fileUploading = false;
      state.uploadMyPartsAttachmentsLoading = false;
      state.uploadMyPartsAttachmentsError = undefined;
      state.uploadMyPartsAttachments = [];
    });
  },
});

export const workspaceNextActions = workspaceNextSlice.actions;

export default workspaceNextSlice.reducer;

import React from "react";
import { IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@next/components/custom-modal";
import { AddSpecListModalBody } from "./add-spec-list-modal-body";
import { StyledHeaderBox } from "./add-spec-list-modal-body.styled";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { VendorManagementModalTypes } from "@next/modules/vendor-management/redux";

type Props = {
  rowsAlreadyAdded?: any[];
  handleAddSpecToFormTable?: (specification_id: number) => void;
  title: string;
};

export const AddSpecListModal: React.FC<Props> = ({
  rowsAlreadyAdded,
  handleAddSpecToFormTable,
  title,
}) => {
  const dispatch = useDispatch();

  const modal = useSelector(
    getModal(VendorManagementModalTypes.ADD_SPEC_LIST_MODAL)
  );

  const onClose = () => {
    dispatch(
      modalsActions.closeModal(VendorManagementModalTypes.ADD_SPEC_LIST_MODAL)
    );
  };

  const ModalHeader = (
    <StyledHeaderBox>
      <Typography variant="h6">{title}</Typography>

      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </StyledHeaderBox>
  );

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalBody: (
          <AddSpecListModalBody
            rowsAlreadyAdded={rowsAlreadyAdded}
            handleAddSpecToFormTable={handleAddSpecToFormTable}
          />
        ),
        modalHeader: ModalHeader,
      }}
      isModalOpen={!!modal}
      customWidth="100%"
      dialogProps={{ fullWidth: true, maxWidth: "xl" }}
    />
  );
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IdentificationForm } from "../forms/identification-form/identification-form";
import { LeadTimeForImplementationForm } from "../forms/lead-time-for-implementation-form/lead-time-for-implementation-form";
import { OnboardingForm } from "../forms/onboarding-form/onboarding-form";
import { ScopeForm } from "../forms/scope-form/scope-form";
import { LeadTimeForProductionForm } from "../forms/lead-time-for-production-form/lead-time-for-production-form";
import { ApprovalForm } from "../forms/approval-form/approval-form";
import { ErrorScreen } from "../error-screen/error-screen";
import { LoadingScreen } from "../loading-screen/loading-screen";
import {
  selectActiveFormStepIndex,
  selectFormDataError,
  selectFormDataLoading,
} from "../../redux/selectors";
import { PomManufacturerForms, vendorManagementActions } from "../../redux";

const forms = [
  IdentificationForm,
  ApprovalForm,
  OnboardingForm,
  ScopeForm,
  LeadTimeForImplementationForm,
  LeadTimeForProductionForm,
];

export const PomManufacturerQuestionnaire: React.FC = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const requestFormDataLoading = useSelector(selectFormDataLoading);
  const requestFormDataError = useSelector(selectFormDataError);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const ActiveFormComponent = forms[activeFormStepIndex];
  const formNames = Object.values(PomManufacturerForms);

  useEffect(() => {
    if (companyId) {
      dispatch(
        vendorManagementActions.fetchFormDataRequest({
          formName: formNames[activeFormStepIndex],
          companyId: companyId,
        })
      );
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(false)
      );
    }
  }, [activeFormStepIndex]);

  if (requestFormDataError) {
    return <ErrorScreen message={requestFormDataError.message} />;
  }

  return (
    <>{requestFormDataLoading ? <LoadingScreen /> : <ActiveFormComponent />}</>
  );
};

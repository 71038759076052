import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormControl, FormControlLabel, Radio, TextField } from "@mui/material";
import { FormFooter } from "../../form-footer/form-footer";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldLabel } from "../../form-field-label/form-field-label";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { FormBoxMd } from "../../form-box/form-box-md";
import { FormParentBox } from "../../form-parent-box";
import {
  FieldMandatoryText,
  OnboardingFormData,
  OnboardingRadioGroupKeys,
  QuestionnaireForms,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectActiveFormStepIndex,
  selectFormData,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import * as S from "./onboarding-form.styled";

export const OnboardingForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectSteps);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const isLastStep = useSelector(selectIsLastStep);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const onboardingFormDataState = useSelector(
    selectFormData
  ) as OnboardingFormData;
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    watch,
    setError,
  } = useForm<OnboardingFormData>({
    defaultValues: onboardingFormDataState,
  });

  const saveChanges = (isForCompletion: boolean) => {
    const formValues = watch();
    dispatch(
      vendorManagementActions.updateFormDataRequest({
        data: formValues,
        formName: QuestionnaireForms.Onboarding,
        companyId: companyId,
      })
    );
    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStep(fieldOfStep, true);
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<OnboardingFormData> = (data) => {
    const isValidated = validateRadioGroupsAndSetError();
    if (isValidated) {
      saveChanges(true);

      showCompletedMessage();
      dispatch(
        vendorManagementActions.setRouteNextStep({
          isLastStep: isLastStep,
        })
      );
    } else {
      showInvalidMessage();
    }
  };

  const updateFieldStatusOfStep = (
    key: "is_completed" | "is_partially_filled" | "is_visible",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[activeFormStepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  const validateRadioGroupsAndSetError = () => {
    let isValid = true;
    const formValues = watch();
    const formData = formValues;
    OnboardingRadioGroupKeys.forEach((key) => {
      if (formData[key] === null || formData[key] === undefined) {
        setError(key as keyof OnboardingFormData, { type: "manual" });
        isValid = false;
      }
    });
    return isValid;
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      const updateStepBody = {
        ...steps[activeFormStepIndex],
        is_completed: false,
      };
      dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const renderRadioGroupField = (
    formFieldLabelComponent: React.ReactNode,
    key: keyof OnboardingFormData
  ) => {
    return (
      <FormControl fullWidth margin="none" error={errors[key] ? true : false}>
        <FormFieldBox>
          {formFieldLabelComponent}

          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <>
                <S.StyledRadioGroup
                  {...field}
                  value={field.value === null ? "" : field.value}
                  onChange={(e) => {
                    const value = e.target.value === "true" ? true : false;
                    field.onChange(value);
                    checkInputChange();
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </S.StyledRadioGroup>
                {errors[key] ? (
                  <FormFieldErrorTypography text={FieldMandatoryText} />
                ) : null}
              </>
            )}
          />
        </FormFieldBox>
      </FormControl>
    );
  };

  return (
    <>
      <FormParentBox>
        <FormBoxMd isCompleted={isFormCompleted}>
          <FormTitle
            title={"Supplier access onboarding"}
            isCompleted={isFormCompleted}
          />

          {renderRadioGroupField(
            <FormFieldLabel label="Access to" linklabel="Isupply" link="#" />,
            "isupply"
          )}

          {renderRadioGroupField(
            <FormFieldLabel
              label="Access to"
              linklabel="A2EMM-001 (EMCM)"
              link="#"
            />,
            "A2emm_001"
          )}

          {renderRadioGroupField(
            <FormFieldLabel label="Access to" linklabel="AM9000" link="#" />,
            "am9000"
          )}

          {renderRadioGroupField(
            <FormFieldLabel
              label="Access to"
              linklabel="AQPL Airbus.com"
              link="#"
            />,
            "aqpl_airbus"
          )}

          {renderRadioGroupField(
            <FormFieldLabel
              label="Access to"
              linklabel="A220 ASL Airbus.com"
              link="#"
            />,
            "a220_asl_airbus"
          )}

          {renderRadioGroupField(
            <FormFieldLabel label="Access to" linklabel="Normaster" link="#" />,
            "normaster"
          )}

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={onboardingFormDataState?.additional_comment || ""}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%" }}
                  multiline
                  rows={3}
                  placeholder="Additional comment..."
                  value={value || ""}
                  onChange={(e) => {
                    onChange(e);
                    checkInputChange();
                  }}
                />
              )}
            />
          </FormControl>
        </FormBoxMd>
      </FormParentBox>
      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};

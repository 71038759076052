import { DesignAndBuiltTableRow } from "../redux";

export function validateDesignAndBuiltFormTableRows(
  rows: DesignAndBuiltTableRow[]
): boolean {
  return rows.every(
    (row) =>
      row.conform_to_a2x &&
      row.poe &&
      row.action_plan_evidence_file &&
      row.action_plan_evidence_file !== ("" as any) &&
      row.action_plan_evidence_comment &&
      row.action_plan_evidence_comment !== ""
  );
}

import React from "react";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import { HeaderCell } from "../table-cells/header-cell";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getLeadTimeForProductionFormTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "specification",
      headerName: "Specification",
    },
    {
      ...defaultColumnConfig,
      field: "dash",
      headerName: "Dash",
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlledProcess",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
    {
      ...defaultColumnConfig,
      field: "initialIndustrialisationCapacity",
      headerName: "Initial industrialisation capacity",
      type: "number",
      editable: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Click to edit..." />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "newIndustrialisationCapacity",
      headerName: "New industrialisation capacity",
      type: "number",
      editable: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Click to edit..." />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "detailChange",
      headerName: "Please detail the change that will occur into the flow",
      editable: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Click to edit..." />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "minOrderQuantity",
      headerName: "Min Order Quantity (MOQ)",
      type: "number",
      editable: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Click to edit..." />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "leadTimeForProduction",
      headerName: "Lead Time for production (Ax)? (weeks)",
      type: "number",
      editable: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Click to edit..." />
      ),
    },
  ];

  return columns;
};

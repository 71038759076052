import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormControl, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { modalsActions } from "@next/redux/modalsSlices";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormFooter } from "../../form-footer/form-footer";
import { FormTitle } from "../../form-title/form-title";
import { FormAddButton } from "../../form-add-button/form-add-button";
import { FormParentBox } from "../../form-parent-box";
import { ScopeFormTable } from "../../table/scope-form-table/scope-form-table";
import { AddSpecListModal } from "../../modals/add-spec-list-modal/add-spec-list-modal";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import {
  selectActiveFormStepIndex,
  selectFormData,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectQuestionnaireName,
  selectSpecsOfScope,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  FieldMandatoryText,
  Questionnaire,
  QuestionnaireForms,
  ScopeFormData,
  Step,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { validateScopeTableRows } from "@next/modules/vendor-management/utils/validateScopeTableRows";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import * as S from "./scope-form.styled";

const tableBottomText =
  "If any specs are missing from the list above, click 'Add Specs' and " +
  "search through the complete list to include them.";
const tableTopTextForPosEndUserAndPomManufacturer =
  "Following our database you are qualified ACLP Material products under " +
  "Technical specifications bellow. Could you please confirm if this " +
  "perimetre is still accurate ?";
const tableTopTextForPomDistributor =
  "Declare couples ordered by the distributor  : [Spec + Supplier + Manufacturer Site]";

export const ScopeForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const steps = useSelector(selectSteps);
  const stepIndex = useSelector(selectActiveFormStepIndex);
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const scopeFormData = useSelector(selectFormData) as ScopeFormData;
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const specsOfScopeData = useSelector(selectSpecsOfScope);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const [isShowTableError, setIsShowTableError] = useState(false);
  const {
    formState: { isSubmitting, errors },
    control,
    watch,
  } = useForm<ScopeFormData>({ defaultValues: scopeFormData });

  const onClickOpenAddSpecListModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.ADD_SPEC_LIST_MODAL,
      })
    );
  };

  const handleAddSpecToScope = (specification_id: number) => {
    dispatch(
      vendorManagementActions.addSpecToScopeRequest({
        companyId: companyId,
        specificationId: specification_id,
      })
    );
  };

  useEffect(() => {
    if (scopeFormData) {
      dispatch(
        vendorManagementActions.fetchSpecsOfScopeRequest({
          companyId,
          isA2PSControl: false,
          pagination: { page: page, pageSize: pageSize },
        })
      );
    }
  }, [page]);

  const saveChanges = (isForCompletion: boolean) => {
    const additionalComment = watch("additional_comment");
    dispatch(
      vendorManagementActions.saveAdditionalCommentFieldOfFormRequest({
        companyId: companyId,
        additionalComment: additionalComment,
        stepName: QuestionnaireForms.Scope,
      })
    );
    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true);
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion = () => {
    const tableData = specsOfScopeData?.results || [];
    if (questionnaireName === Questionnaire.PomDistributor) {
      const isTableRowsValidated = validateScopeTableRows(tableData);
      if (isTableRowsValidated) {
        makeFormStepCompleteAndShowMessage();
      } else {
        setErrorAndShowInvalidMessage();
      }
    } else if (tableData.length > 0) {
      makeFormStepCompleteAndShowMessage();
    } else {
      setErrorAndShowInvalidMessage();
    }
  };

  const setErrorAndShowInvalidMessage = () => {
    showInvalidMessage();
    if (!isShowTableError) {
      setIsShowTableError(true);
    }
  };

  const makeFormStepCompleteAndShowMessage = () => {
    saveChanges(true);
    showCompletedMessage();
    dispatch(
      vendorManagementActions.setRouteNextStep({
        isLastStep: isLastStep,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle title={"Scope"} isCompleted={isFormCompleted} />
          <S.StyledBox>
            <S.StyledTableBox>
              <S.StyledTableTypography
                variant="body1"
                tooltipTitle={
                  questionnaireName === Questionnaire.PomDistributor
                    ? tableTopTextForPomDistributor
                    : tableTopTextForPosEndUserAndPomManufacturer
                }
              >
                {questionnaireName === Questionnaire.PomDistributor
                  ? tableTopTextForPomDistributor
                  : tableTopTextForPosEndUserAndPomManufacturer}
              </S.StyledTableTypography>

              <ScopeFormTable setPage={setPage} pageSize={pageSize} />
            </S.StyledTableBox>

            <S.StyledBox>
              <S.StyledInnerBox>
                <FormAddButton
                  btnText={"Add specs"}
                  onClick={onClickOpenAddSpecListModal}
                />

                <S.StyledTypography variant="body1" tooltipTitle={null}>
                  {tableBottomText}
                </S.StyledTypography>
              </S.StyledInnerBox>

              {isShowTableError && specsOfScopeData?.results.length === 0 ? (
                <FormFieldErrorTypography text={FieldMandatoryText} />
              ) : null}
            </S.StyledBox>
          </S.StyledBox>

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={scopeFormData?.additional_comment || ""}
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={confirmStepCompletion}
        isCompleted={isFormCompleted}
      />

      <AddSpecListModal
        title={"Spec list"}
        rowsAlreadyAdded={specsOfScopeData?.results || []}
        handleAddSpecToFormTable={handleAddSpecToScope}
      />
    </>
  );
};

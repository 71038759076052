import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

const StyledTypography = styled(CustomTypography)(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
  letterSpacing: "0.4px",
}));

type EditableEvidenceTemplateCellProps = GridRenderCellParams & {
  fieldForCheckSelected: string;
};

export const EditableEvidenceTemplateCell: React.FC<
  EditableEvidenceTemplateCellProps
> = ({ fieldForCheckSelected, ...params }) => {
  let text = null;
  const valueFromSelectionInRow = params.row[fieldForCheckSelected];

  if (valueFromSelectionInRow === "fully_compliant") {
    text = null;
  } else if (
    params.row["evidence_file"] ||
    params.row["evidence_comment"] ||
    params.row["action_plan_evidence_file"] ||
    params.row["action_plan_evidence_comment"]
  ) {
    text = "Edit evidence";
  } else if (
    valueFromSelectionInRow &&
    (!(params.row["evidence_file"] || params.row["evidence_comment"]) ||
      !(
        params.row["action_plan_evidence_file"] ||
        params.row["action_plan_evidence_comment"]
      ))
  ) {
    text = "Add evidence";
  }

  return (
    <Box sx={{ cursor: text ? "pointer" : "none" }}>
      <StyledTypography tooltipTitle={null} variant="caption">
        {text}
      </StyledTypography>
    </Box>
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import { vendorManagementActions } from "../../redux";
import {
  selectActiveFormStepIndex,
  selectCompletionStatusesOfSteps,
  selectIsAllStepsCompleted,
  selectIsLastStep,
  selectSteps,
} from "../../redux/selectors";
import { formatTime } from "../../utils/formatTime";
import * as S from "./form-footer.styled";

interface FormFooterProps {
  saveChanges: () => void; // api call to just save
  confirmStepCompletion: () => void; // call to save and validate
  isCompleted: boolean;
}

export const FormFooter: React.FC<FormFooterProps> = ({
  saveChanges,
  confirmStepCompletion,
  isCompleted,
}) => {
  const dispatch = useDispatch();
  const steps = useSelector(selectSteps);
  const completionStatusesOfSteps = useSelector(
    selectCompletionStatusesOfSteps
  );
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const lastUpdatedDate = steps[activeFormStepIndex]?.last_updated;
  const lastSavedAt = formatTime(lastUpdatedDate);

  const handleNext = () => {
    if (isCompleted) {
      routeNextStep();
    } else {
      saveChanges();
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStep =
      isLastStep && !isAllStepsCompleted
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completionStatusesOfSteps))
        : activeFormStepIndex + 1;
    dispatch(vendorManagementActions.setActiveFormStepIndex(newActiveStep));
  };

  const handleBack = () => {
    if (isCompleted) {
      dispatch(
        vendorManagementActions.setActiveFormStepIndex(activeFormStepIndex - 1)
      );
    } else {
      saveChanges();
      dispatch(
        vendorManagementActions.setActiveFormStepIndex(activeFormStepIndex - 1)
      );
    }
  };

  return (
    <S.StyledFooterBox>
      <S.StyledInnerBox>
        <S.StyledDirectionButton
          disabled={activeFormStepIndex === 0}
          onClick={handleBack}
        >
          <ArrowBackIcon />
        </S.StyledDirectionButton>

        <S.StyledButton
          startIcon={<SaveIcon sx={{ width: "24px", height: "24px" }} />}
          variant="contained"
          color="primary"
          onClick={saveChanges}
          disabled={isCompleted}
        >
          <S.StyledTypography variant="body2" tooltipTitle={null}>
            Save changes
          </S.StyledTypography>
        </S.StyledButton>

        <S.SavedBox>
          <Typography variant="body2">Last saved at {lastSavedAt}</Typography>
        </S.SavedBox>

        <S.StyledButton
          startIcon={<CheckIcon sx={{ width: "24px", height: "24px" }} />}
          variant="contained"
          color="success"
          onClick={confirmStepCompletion}
          disableElevation
          disabled={isCompleted}
        >
          <S.StyledTypography variant="body2" tooltipTitle={null}>
            Confirm step completion
          </S.StyledTypography>
        </S.StyledButton>

        {!isLastStep ? (
          <S.StyledDirectionButton onClick={handleNext}>
            <ArrowForwardIcon />
          </S.StyledDirectionButton>
        ) : (
          <S.BlankBox />
        )}
      </S.StyledInnerBox>
    </S.StyledFooterBox>
  );
};

import React from "react";
import { Link } from "@mui/material";
import { useSelector } from "react-redux";
import { selectQuestionnaireName } from "@next/modules/vendor-management/redux/selectors";
import * as S from "./form-header-content.styled";
import { Questionnaire } from "@next/modules/vendor-management/redux";

const textList = [
  "• Compliance to be declared (Fully compliant / Partially compliant / Not compliant).",
  "• POE per line (since when for fully compliant & by when for partially & not compliant.",
  "• If Partially or Not compliant: Action plan to be provided.",
];
const textForPomManufacturerAndPomDistributor =
  "If receiving ACLP Order : Ax at Time T0, what would be your lead time to deliver ACLP under A2x / A0x Technical Spec ? (Question for each Spec)";

const link =
  "https://supplier.aero.bombardier.com/A220-SQA/sqa-docs/enginnering_docs/A2ERD-A220-000_B.pdf";

export const FormHeaderContent: React.FC = () => {
  const questionnaireName = useSelector(selectQuestionnaireName);
  return (
    <>
      {questionnaireName === Questionnaire.PosEndUsers ? (
        <S.StyledBox>
          <S.StyledTypography variant="body1">
            Conformity with{" "}
            <Link href={link} target="_blank" rel="noopener" variant="body1">
              A2ERD A220-000
            </Link>{" "}
            ?
          </S.StyledTypography>

          {textList.map((text, i) => (
            <S.StyledTypography variant="body1" key={i}>
              {text}
            </S.StyledTypography>
          ))}
        </S.StyledBox>
      ) : (
        <S.StyledFullWidthBox>
          <S.StyledTypography variant="body1">
            {textForPomManufacturerAndPomDistributor}
          </S.StyledTypography>
        </S.StyledFullWidthBox>
      )}
    </>
  );
};

import { Box, styled } from "@mui/material";

const FormParentBox = styled(Box)({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "24px",
  paddingTop: "96px",
  paddingBottom: "96px",
});
export default FormParentBox;

import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Link } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormBoxMd } from "../../form-box/form-box-md";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormTitle } from "../../form-title/form-title";
import { FormFileAttachmentButton } from "../../form-file-attachment-button/form-file-attachment-button";
import { FormAttachedFileBox } from "../../form-attached-file-box/form-attached-file-box";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import {
  FieldMandatoryText,
  FileObj,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectActiveFormStepIndex,
  selectFormData,
  selectIsFormCompleted,
  selectIsLastStep,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import * as S from "./approval-form.styled";

const link = "https://supplier.aero.bombardier.com/A220-SQA/";
const linklabel = "https://supplier.aero.bombardier.com/A220-SQA/";

export const ApprovalForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const steps = useSelector(selectSteps);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const fileInputRefs = useRef<{
    [key: string]: React.MutableRefObject<HTMLInputElement | null>;
  }>({});
  const formData = useSelector(selectFormData) as FileObj[];

  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<{ [key: string]: FileObj }>({
    defaultValues:
      formData && formData?.length > 0
        ? formData?.reduce(
            (acc, fileObj) => ({
              ...acc,
              [fileObj.field_name]: fileObj,
            }),
            {}
          )
        : [],
  });

  const handleAttachButtonClick = (fieldName: string) => {
    const inputRef = fileInputRefs.current[fieldName];
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fileObj: FileObj
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      dispatch(
        vendorManagementActions.updateApprovalFormFileRequest({
          companyId: companyId,
          field_name: fileObj.field_name,
          field_title: fileObj.field_title,
          file: selectedFile,
        })
      );
      checkInputChange();

      if (errors[fileObj.field_name]) {
        clearErrors(fileObj.field_name);
      }
    }
  };

  const handleClickRemove = (fileObj: FileObj) => {
    dispatch(
      vendorManagementActions.deleteApprovalFormFileRequest(fileObj?.file_id)
    );
    checkInputChange(); // TO-DO: remove from comment when delete method is implemented
  };

  const validateFilesAndSetError = () => {
    let isValid = true;
    formData.forEach((fileObj) => {
      if (!fileObj.file_url) {
        setError(fileObj.field_name, { type: "manual" });
        isValid = false;
      }
    });
    return isValid;
  };

  const saveChanges = (isForCompletion: boolean) => {
    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true);
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion = () => {
    const isFormValidated = validateFilesAndSetError();

    if (isFormValidated) {
      saveChanges(true);
      showCompletedMessage();
      dispatch(
        vendorManagementActions.setRouteNextStep({
          isLastStep: isLastStep,
        })
      );
    } else {
      showInvalidMessage();
    }
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[activeFormStepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  const renderFileField = (fileObj: FileObj) => {
    if (!fileInputRefs.current[fileObj.field_name]) {
      fileInputRefs.current[fileObj.field_name] =
        React.createRef<HTMLInputElement>();
    }

    return (
      <FormFieldBox key={fileObj.field_name}>
        <S.StyledTypography>{fileObj.field_title}</S.StyledTypography>
        <Box>
          <FormFileAttachmentButton
            handleAttachButtonClick={() =>
              handleAttachButtonClick(fileObj.field_name)
            }
            handleFileChange={(event) => handleFileChange(event, fileObj)}
            fileInputRef={fileInputRefs.current[fileObj.field_name]}
            btnText={"Attach"}
          />
        </Box>
        {fileObj.file_url ? (
          <S.AttachmentBox>
            <FormAttachedFileBox
              file={fileObj}
              handleClickRemove={() => handleClickRemove(fileObj)}
            />
          </S.AttachmentBox>
        ) : null}
        {errors[fileObj.field_name] && (
          <Box mt={"-5px"}>
            <FormFieldErrorTypography text={FieldMandatoryText} />
          </Box>
        )}
      </FormFieldBox>
    );
  };

  return (
    <>
      <FormParentBox>
        <FormBoxMd isCompleted={isFormCompleted}>
          <FormTitle title={"Approval"} isCompleted={isFormCompleted} />

          <S.StyledTypography variant="body1">
            *QMSF templates available on{" "}
            <Link
              href={link}
              target="_blank"
              rel="noopener"
              variant="body1"
              underline="none"
            >
              {linklabel}
            </Link>
          </S.StyledTypography>

          {formData && formData?.length > 0
            ? formData?.map((fileObj) => renderFileField(fileObj))
            : null}
        </FormBoxMd>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={confirmStepCompletion}
        isCompleted={isFormCompleted}
      />
    </>
  );
};

import { Box, IconButton, Step, Stepper, styled } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    width: 0,
    border: 0,
  },
}));

export const StepperContainer = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  width: "90%",
  overflow: "visible",

  paddingLeft: "6px",
});

export const StyledStepper = styled(Stepper)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const StyledRightIconButton = styled(IconButton)({
  position: "absolute",
  right: "-17px",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "#fff",
  zIndex: 2,
  width: "16px",
  height: "16px",
});

export const StyledLeftIconButton = styled(IconButton)({
  position: "absolute",
  left: "-10px",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "#fff",
  zIndex: 2,
  width: "16px",
  height: "16px",
});

export const StyledStep = styled(Step)({
  padding: "0",
  margin: "0 2px",
  flexShrink: 0,
});

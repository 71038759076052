import React, { useRef } from "react";
import { useSelector } from "react-redux";
import StepLabel from "@mui/material/StepLabel";
import { Box } from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  selectActiveFormStepIndex,
  selectCompletionStatusesOfSteps,
  selectIsShowGreetingPage,
  selectSteps,
} from "../../redux/selectors";
import { FormStepBox } from "../form-step-box/form-step-box";
import * as S from "./custom-form-stepper.styled";

function ColorlibStepIcon(props: StepIconProps) {
  const isShowGreetingPage = useSelector(selectIsShowGreetingPage);
  const completedStatusOfSteps = useSelector(selectCompletionStatusesOfSteps);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const steps = useSelector(selectSteps).filter((step) => step.is_visible);

  const icons = steps.reduce<{ [index: string]: React.ReactElement }>(
    (acc, step, index) => {
      const isActive = isShowGreetingPage
        ? false
        : activeFormStepIndex === index;
      const isCompleted = completedStatusOfSteps[index];
      acc[String(index + 1)] = (
        <FormStepBox
          name={step.step_name}
          active={isActive}
          completed={isCompleted}
          stepIndex={index}
        />
      );
      return acc;
    },
    {}
  );

  return <Box>{icons[String(props.icon)]}</Box>;
}

export const CustomFormStepper: React.FC = () => {
  const stepperRef = useRef<HTMLDivElement>(null);
  const steps = useSelector(selectSteps);
  const completedSteps = useSelector(selectCompletionStatusesOfSteps);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);

  const handleScrollRight = () => {
    if (stepperRef.current) {
      stepperRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const handleScrollLeft = () => {
    if (stepperRef.current) {
      stepperRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  return (
    <S.StepperContainer>
      <S.StyledLeftIconButton onClick={handleScrollLeft}>
        <ChevronLeftIcon fontSize="small" />
      </S.StyledLeftIconButton>

      <S.StyledStepper
        ref={stepperRef}
        activeStep={activeFormStepIndex}
        connector={null}
        alternativeLabel={false}
      >
        {steps.map((step, index) => (
          <S.StyledStep key={step?.step_name} completed={completedSteps[index]}>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </S.StyledStep>
        ))}
      </S.StyledStepper>

      <S.StyledRightIconButton onClick={handleScrollRight}>
        <ChevronRightIcon fontSize="small" />
      </S.StyledRightIconButton>
    </S.StepperContainer>
  );
};

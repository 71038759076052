import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  Theme,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBarItemType } from "./app-bar-next-dropdown";
import { AppBarNextItems } from "./app-bar-next-action-items/app-bar-next-action-items";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    drawerRoot: {
      "& .MuiDrawer-paper": {
        backgroundColor: theme.palette.appbar.main,
        color: theme.palette.background.default,
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.background.default,
      },
    },
  })
);

type Props = {
  appBarItems: AppBarItemType[];
};

export const AppBarNextIcon: React.FC<{
  Icon?: React.ComponentType<SvgIconProps>;
}> = ({ Icon }) => {
  if (!Icon) return <></>;

  return Icon && <Icon />;
};

export const AppBarNextDrawer: React.FC<Props> = ({ appBarItems }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const navigateTo = (appBarItem: AppBarItemType) => () => {
    if (appBarItem?.navigateWithHref) {
      const selectedLanguage = getSelectedLanguage();

      window.location.href = `/${selectedLanguage}/search`;
    } else {
      history.push(appBarItem?.path);
    }
  };

  return (
    <>
      <Drawer
        className={classes.drawerRoot}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {appBarItems.map((appBarItem) => (
            <ListItem onClick={() => setOpenDrawer(false)} button>
              {appBarItem?.icon ? (
                <ListItemIcon>
                  <AppBarNextIcon
                    Icon={appBarItem?.icon as React.ComponentType<SvgIconProps>}
                  />
                </ListItemIcon>
              ) : null}

              <ListItemText onClick={navigateTo(appBarItem)}>
                {appBarItem.label}
              </ListItemText>
            </ListItem>
          ))}
        </List>

        <Box flexGrow={1} display="flex" alignItems="flex-end">
          <AppBarNextItems inDrawer setSidePanelDrawer={setOpenDrawer} />
        </Box>
      </Drawer>

      <IconButton onClick={() => setOpenDrawer(!openDrawer)} size="large">
        <MenuIcon sx={{ color: "white" }} />
      </IconButton>
    </>
  );
};

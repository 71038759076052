import React from "react";
import {
  GridCloseIcon,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { HeaderCell } from "../table-cells/header-cell";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getScopeFormTableColumns = (
  isTableInPomDistributorQuestionnaire: boolean
) => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "name",
      headerName: "Specification",
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash",
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
  ];

  const lastColumn = {
    ...defaultColumnConfig,
    field: "removeRowButton",
    headerName: " ",
    align: "center" as const,
    renderCell: (params: GridRenderCellParams) => (
      <IconButtonCell {...params} Icon={GridCloseIcon} />
    ),
    flex: 0.1,
  };

  const columnsForPomDistributor = [
    {
      ...defaultColumnConfig,
      field: "sourceOfSupply",
      headerName: "Source of supply",
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell
          {...params}
          icon={<KeyboardArrowDownIcon />}
          placeholderText="Select an option"
        />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "manufacturingSite",
      headerName: "Manufacturing site",
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell
          {...params}
          icon={<KeyboardArrowDownIcon />}
          placeholderText="Select an option"
        />
      ),
    },
    { ...lastColumn },
  ];

  if (isTableInPomDistributorQuestionnaire) {
    columns.push(...columnsForPomDistributor);
  } else {
    columns.push(lastColumn);
  }

  return columns;
};

import React, { useCallback } from "react";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro-v5";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsFormCompleted,
  selectIsThereUnsavedChangesInFormStep,
} from "@next/modules/vendor-management/redux/selectors";
import { vendorManagementActions } from "@next/modules/vendor-management/redux";
import { getBufferStockStrategyFormTableColumns } from "./buffer-stock-strategy-form-table.columns";
import * as S from "./buffer-stock-strategy-form-table.styled";

type Props = {
  rows: any[];
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
};

export const BufferStockStrategyFormTable: React.FC<Props> = ({
  rows,
  setRows,
}) => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const columns = getBufferStockStrategyFormTableColumns();
  const [cellModesModel, setCellModesModel] =
    React.useState<GridCellModesModel>({});

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
      if (!params.colDef.editable) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [isFormCompleted, isThereUnsavedChanges]
  );

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    checkInputChange();
    return updatedRow;
  };

  const checkInputChange = () => {
    dispatch(
      vendorManagementActions.checkFieldChangeForFormStep({
        isFormCompleted: isFormCompleted,
        isThereUnsavedChanges: isThereUnsavedChanges,
      })
    );
  };

  return (
    <S.StyledDataGridPro
      apiRef={apiRef}
      autoHeight
      autoPageSize
      hideFooter
      headerHeight={48}
      rowHeight={32}
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      experimentalFeatures={{ newEditingApi: true }}
      processRowUpdate={processRowUpdate}
    />
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { vendorManagementActions } from "../../redux";
import {
  selectIsShowGreetingPage,
  selectIsThereUnsavedChangesInFormStep,
} from "../../redux/selectors";
import * as S from "./form-step-box.styled";

type FormStepBoxProps = {
  name: string;
  active: boolean;
  completed: boolean | undefined;
  stepIndex: number;
};

export const FormStepBox: React.FC<FormStepBoxProps> = ({
  name,
  active,
  completed,
  stepIndex,
}) => {
  const dispatch = useDispatch();
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const isShowGreetingPage = useSelector(selectIsShowGreetingPage);

  const handleClick = () => {
    if (isShowGreetingPage) {
      dispatch(vendorManagementActions.setIsShowGreetingPage(false));
    }

    if (!isThereUnsavedChanges) {
      dispatch(vendorManagementActions.setActiveFormStepIndex(stepIndex));
    } else {
      const confirmLeave = window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
      if (confirmLeave) {
        // initially, isThereUnsavedChanges must be false when new form is rendered
        dispatch(
          vendorManagementActions.setIsThereUnsavedChangesInFormStep(false)
        );
        dispatch(vendorManagementActions.setActiveFormStepIndex(stepIndex));
      }
    }
  };

  return (
    <S.StyledBox active={active} completed={completed} onClick={handleClick}>
      <S.StyledIcon active={active} completed={completed} />
      <S.StyledTypography active={active} completed={completed}>
        {name}
      </S.StyledTypography>
    </S.StyledBox>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { AddSpecListModalTable } from "../../table/add-spec-list-modal-table/add-spec-list-modal-table";
import { SearchTextField } from "../../search-text-field";
import { modalsActions } from "@next/redux/modalsSlices";
import {
  selectActiveFormStepIndex,
  selectIsFormCompleted,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  SpecificationTableRow,
  Step,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  PAGINATION_PAGE_SIZE,
} from "@next/constants";
import * as S from "./add-spec-list-modal-body.styled";

const menuItemValues = [
  "A2ERD",
  "A2LS",
  "A2MS",
  "A2NDE",
  "A2PS",
  "A2TS",
  "A0x",
];

type Props = {
  rowsAlreadyAdded: any[];
  handleAddSpecToFormTable?: (specification_id: number) => void;
};

export const AddSpecListModalBody: React.FC<Props> = ({
  rowsAlreadyAdded,
  handleAddSpecToFormTable,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectSteps);
  const stepIndex = useSelector(selectActiveFormStepIndex);
  const [selectedRows, setSelectedRows] = useState<SpecificationTableRow[]>([]);
  const [specType, setSpecType] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchAllSpecificationsRequest({
        page: page,
        pageSize: PAGINATION_PAGE_SIZE,
        specType: specType,
        search: searchValue,
      })
    );
  }, [specType, page, searchValue]);

  const handleClickAddSpecsToListBtn = () => {
    if (selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        handleAddSpecToFormTable(row.specification_id);
      });

      checkCompletionStatusOfStep();
    }

    dispatch(
      modalsActions.closeModal(VendorManagementModalTypes.ADD_SPEC_LIST_MODAL)
    );
  };

  const checkCompletionStatusOfStep = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;

    setSpecType(value);
  };

  const clearSearch = () => {
    setSearchValue("");
  };

  return (
    <>
      <S.StyledBox>
        <S.StyledContent>
          <S.StyledRowBox>
            <FormControl style={{ height: "37px" }}>
              <Select
                id="spec-type-select"
                value={specType}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected || selected === "") {
                    return (
                      <span style={{ color: "#aaa" }}>Filter by spec type</span>
                    );
                  }
                  return selected;
                }}
                onChange={handleChange}
                placeholder="Filter by spec type"
                sx={{ width: "260px", height: "37px" }}
              >
                <MenuItem key={"All"} value={""}>
                  All
                </MenuItem>
                {menuItemValues.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <SearchTextField
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              clearSearch={clearSearch}
            />
          </S.StyledRowBox>

          <AddSpecListModalTable
            setSelectedRows={setSelectedRows}
            setPage={setPage}
            rowsAlreadyAdded={rowsAlreadyAdded}
          />
        </S.StyledContent>

        <S.StyledFooterBox>
          <S.StyledButtonBox>
            <S.StyledButton
              variant="contained"
              color="primary"
              onClick={handleClickAddSpecsToListBtn}
            >
              Add selected specs to the list ({selectedRows.length})
            </S.StyledButton>
          </S.StyledButtonBox>
        </S.StyledFooterBox>
      </S.StyledBox>
    </>
  );
};

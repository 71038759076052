import { Compliance, Questionnaire, ScopeTableRow } from "../redux";
const tex =
  "compliance_status, poe_per_line, ao_x_stock, bo_x_stock, evidence_file";

export const validateLeadTimeForImplFormTableData = (
  rows: ScopeTableRow[],
  currentQuestionnaireName: Questionnaire
): boolean => {
  if (
    currentQuestionnaireName === Questionnaire.PosEndUsers ||
    currentQuestionnaireName === Questionnaire.PomManufacturer
  ) {
    return rows.every((row) => {
      const {
        compliance_status,
        poe_per_line,
        ao_x_stock,
        bo_x_stock,
        evidence_file,
      } = row;
      if (
        compliance_status === null ||
        compliance_status === undefined ||
        poe_per_line === null ||
        poe_per_line === undefined ||
        ao_x_stock === null ||
        ao_x_stock === undefined ||
        bo_x_stock === null ||
        bo_x_stock === undefined
      ) {
        return false;
      }
      if (
        compliance_status !== Compliance.FullyCompliant &&
        (evidence_file === null || evidence_file === undefined)
      ) {
        return false;
      }
      if (
        compliance_status === Compliance.FullyCompliant &&
        evidence_file !== null &&
        evidence_file !== undefined &&
        (evidence_file === null || evidence_file === undefined)
      ) {
        return false;
      }

      return true;
    });
  } else {
    return rows.every((row) => {
      const { compliance_status, poe_per_line, ao_x_stock, bo_x_stock } = row;
      if (
        compliance_status === null ||
        compliance_status === undefined ||
        poe_per_line === null ||
        poe_per_line === undefined
      ) {
        return false;
      }
      /* if ( // TO-DO: api is not ready for this field
        compliance_status !== Compliance.FullyCompliant &&
        (coc === null || coc === undefined)
      ) {
        return false;
      }

      if (
        compliance_status === Compliance.FullyCompliant &&
        coc !== null &&
        coc !== undefined &&
        (coc.file === null || coc.file === undefined)
      ) {
        return false;
      } */

      return true;
    });
  }
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@next/components/custom-modal";
import { ThankYouModalBody } from "./thank-you-modal-body";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { VendorManagementModalTypes } from "@next/modules/vendor-management/redux";

export const ThankYouModal: React.FC = () => {
  const dispatch = useDispatch();

  const modal = useSelector(
    getModal(VendorManagementModalTypes.THANK_YOU_MODAL)
  );

  const onClose = () => {
    dispatch(
      modalsActions.closeModal(VendorManagementModalTypes.THANK_YOU_MODAL)
    );
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalBody: <ThankYouModalBody />,
      }}
      isModalOpen={!!modal}
      customWidth="100%"
      dialogProps={{ fullWidth: true, maxWidth: "md" }}
    />
  );
};

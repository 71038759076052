import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormControl, TextField } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormTitle } from "../../form-title/form-title";
import { DesignAndBuiltFormTable } from "../../table/design-and-built-form-table/design-and-built-form-table";
import {
  selectActiveFormStepIndex,
  selectDesignAndBuiltDrawings,
  selectFormStepData,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import {
  DesignAndBuiltFormData,
  QuestionnaireForms,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { validateDesignAndBuiltFormTableRows } from "@next/modules/vendor-management/utils/validateDesignAndBuiltFormTableData";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import * as S from "./design-and-built-form.styled";

export const DesignAndBuiltForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const steps = useSelector(selectSteps);
  const stepIndex = useSelector(selectActiveFormStepIndex);
  const [page, setPage] = useState(1);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const formData = useSelector(
    selectFormStepData(QuestionnaireForms.DesignAndBuilt)
  ) as DesignAndBuiltFormData;
  const tableData = useSelector(selectDesignAndBuiltDrawings);

  const { handleSubmit, control, watch } = useForm<DesignAndBuiltFormData>({
    defaultValues: formData,
  });

  useEffect(() => {
    if (formData) {
      dispatch(
        vendorManagementActions.fetchDesignAndBuiltDrawingsRequest({
          companyId: companyId,
          pagination: { page: page, pageSize: PAGINATION_PAGE_SIZE },
        })
      );
    }
  }, [page]);

  const saveChanges = (isForCompletion: boolean) => {
    const additionalComment = watch("additional_comment");
    dispatch(
      vendorManagementActions.saveAdditionalCommentFieldOfFormRequest({
        companyId: companyId,
        additionalComment: additionalComment,
        stepName: QuestionnaireForms.DesignAndBuilt,
      })
    );

    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true);
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<DesignAndBuiltFormData> = (
    data
  ) => {
    const isTableDataValidated = validateDesignAndBuiltFormTableRows(
      tableData?.results || []
    );

    if (isTableDataValidated) {
      saveChanges(true);
      showCompletedMessage();
      dispatch(
        vendorManagementActions.setRouteNextStep({
          isLastStep: isLastStep,
        })
      );
    } else {
      showInvalidMessage();
      return;
    }
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle title={"Design And Built"} isCompleted={isFormCompleted} />

          <S.StyledInnerBox>
            <S.StyledTypography variant="body1" tooltipTitle={null}>
              Integration into your ecosystem
            </S.StyledTypography>

            <DesignAndBuiltFormTable
              setPage={setPage}
              pageSize={PAGINATION_PAGE_SIZE}
            />
          </S.StyledInnerBox>

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={formData.additional_comment}
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};

import React from "react";
import { Checkbox, Menu, MenuItem, Typography } from "@mui/material";

type Props = {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  row: any;
  field: string;
};

const isItemInRow = (row: any, field: string, item: string): boolean => {
  return row && row[field] && row[field].includes(item);
};

export const SelectOptionsMenuWithCheckbox: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  setRows,
  row,
  field,
}) => {
  const openMenu = Boolean(anchorEl);
  const menuItems =
    field === "sourceOfSupply" ? row.supplierList : row.manufacturerSiteList;

  const handleCloseMenuItem = () => {
    setAnchorEl(null);
  };

  const handleToggleItem = (item: string) => {
    setRows((prevRows) =>
      prevRows.map((rowState) => {
        if (rowState.id === row.id) {
          const newFieldValues = rowState[field] ? [...rowState[field]] : [];
          const itemIndex = newFieldValues.indexOf(item);
          if (itemIndex !== -1) {
            newFieldValues.splice(itemIndex, 1);
          } else {
            newFieldValues.push(item);
          }
          return { ...rowState, [field]: newFieldValues };
        }
        return rowState;
      })
    );
    handleCloseMenuItem();
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleCloseMenuItem}
    >
      {menuItems?.map((item) => (
        <MenuItem
          key={item}
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Checkbox
            checked={isItemInRow(row, field, item)}
            onClick={(e) => e.stopPropagation()}
            onChange={() => handleToggleItem(item)}
          />
          <Typography variant="caption">{item}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { CustomAutoCompleteTextField } from "../../custom-auto-complete-text-field/custom-auto-complete-text-field";
import { SubtierCard } from "./subtier-card/subtier-card";
import { AddSpecListModal } from "../../modals/add-spec-list-modal/add-spec-list-modal";
import { modalsActions } from "@next/redux/modalsSlices";
import {
  selectActiveFormStepIndex,
  selectAllSpecsOfSubtierSupppliers,
  selectFormData,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectSteps,
  selectSubtierCompanies,
} from "@next/modules/vendor-management/redux/selectors";
import {
  FieldMandatoryText,
  Step,
  SubtierSupplier,
  SubtierTableRow,
  SubtiersFormData,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { validateSubtiersData } from "@next/modules/vendor-management/utils/validateSubtiersData";
import * as S from "./subtiers-form.styled";
import { formatDate } from "helpers/formatDate";

const validatingText =
  "Validating this section, Tier 1 confirmes that the communication (B->A) has been cascaded to all Subtiers";

export const SubtiersForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const steps = useSelector(selectSteps);
  const stepIndex = useSelector(selectActiveFormStepIndex);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const formData = useSelector(selectFormData) as SubtiersFormData;
  const selectedSubtierSupplierInfos = formData?.subtier_suppliers_info || [];
  const subtierSupplierInfos = useSelector(selectSubtierCompanies);
  const allSpecsOfSubtierSuppliers = useSelector(
    selectAllSpecsOfSubtierSupppliers
  );
  const [selectedSubtierForModal, setSelectedSubtierForModal] =
    useState<SubtierSupplier | null>(null);
  const [rowsToNotShowInModal, setRowsToNotShowInModal] = useState<
    SubtierTableRow[]
  >([]);
  const [isShowTableErrors, setIsShowTableErrors] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setError,
  } = useForm<SubtiersFormData>({ defaultValues: formData });

  useEffect(() => {
    if (formData) {
      dispatch(vendorManagementActions.fetchSubtierCompaniesRequest(""));
    }
  }, []);

  const saveChanges = (isForCompletion: boolean) => {
    const additionalComment = getValues("additional_comment");
    const isValidated = getValues("subtier_validation");
    const body = {
      subtier_validation: isValidated,
      additional_comment: additionalComment,
      subtier_suppliers: formData.subtier_suppliers,
      subtier_suppliers_info: formData.subtier_suppliers_info,
    };

    dispatch(
      vendorManagementActions.updateSubtierFormDataRequest({
        formData: body,
        companyId: companyId,
      })
    );

    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true);

    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<SubtiersFormData> = (data) => {
    const isValidatedSubtiersData = validateSubtiersData(
      allSpecsOfSubtierSuppliers
    );
    const isCheckedValidateField = data.subtier_validation;

    if (isValidatedSubtiersData && isCheckedValidateField) {
      saveChanges(true);
      showCompletedMessage();
      dispatch(
        vendorManagementActions.setRouteNextStep({
          isLastStep: isLastStep,
        })
      );
    } else {
      if (!isValidatedSubtiersData && !isShowTableErrors) {
        setIsShowTableErrors(true);
      }
      if (!isCheckedValidateField) {
        setError("subtier_validation", { type: "manual" });
      }
      showInvalidMessage();
      return;
    }
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const handleAutocompleteChange = (options: string[]) => {
    const updatedSubtierSupplierInfos = subtierSupplierInfos
      .filter((info) => options.includes(info.title))
      .map((info) => ({
        id: info.company_id,
        title: info.title,
      }));
    const updatedSubtierIds = updatedSubtierSupplierInfos.map(
      (info) => info.id
    );

    saveSuppliersAndSuppliersInfo(
      updatedSubtierIds,
      updatedSubtierSupplierInfos
    );
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  const saveSuppliersAndSuppliersInfo = (
    subtierSuppliers: string[],
    subtierSuppliersInfo: SubtierSupplier[]
  ) => {
    const body = {
      subtier_suppliers: subtierSuppliers,
      subtier_suppliers_info: subtierSuppliersInfo,
    };

    dispatch(
      vendorManagementActions.updateSubtierFormDataRequest({
        formData: body,
        companyId: companyId,
      })
    );
  };

  const handleAddSpecToFormTable = (specification_id: number) => {
    const date = formatDate(new Date(), "YYYY-MM-DD");
    const spec = {
      specification_id: specification_id,
      poe: date,
      compliance: "fully_compliant",
    };
    dispatch(
      vendorManagementActions.addSpecToSubtierSupplierRequest({
        companyId: companyId,
        supplierId: selectedSubtierForModal.id,
        spec: spec,
      })
    );
  };

  const handleClickAddSpecsBtn = (subtierSupplier: SubtierSupplier) => {
    setSelectedSubtierForModal(subtierSupplier);

    if (subtierSupplier) {
      const selectedSubtierSupplier = allSpecsOfSubtierSuppliers.find(
        (supplier) => {
          if (supplier.supplier_id === subtierSupplier.id) {
            return supplier;
          }
        }
      );
      if (selectedSubtierSupplier) {
        setRowsToNotShowInModal(selectedSubtierSupplier?.rows || []);
      }
    }

    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.ADD_SPEC_LIST_MODAL,
      })
    );
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle
            title={"Subtier readiness"}
            isCompleted={isFormCompleted}
          />

          <CustomAutoCompleteTextField
            options={
              subtierSupplierInfos?.map((subtier) => subtier.title) || []
            }
            selectedOptions={
              selectedSubtierSupplierInfos?.map((subtier) => subtier.title) ||
              []
            }
            handleChange={handleAutocompleteChange}
          />

          {selectedSubtierSupplierInfos?.map(
            (subtierSupplier: SubtierSupplier, i) => (
              <SubtierCard
                key={i}
                subtierSupplier={subtierSupplier}
                handleClickAddSpecsBtn={handleClickAddSpecsBtn}
                isShowTableErrors={isShowTableErrors}
              />
            )
          )}

          <FormFieldBox>
            <Typography variant="body1">{validatingText}</Typography>

            <FormControl error={errors.subtier_validation ? true : false}>
              <FormControlLabel
                sx={{ width: "120px" }}
                control={
                  <Checkbox
                    defaultChecked={formData?.subtier_validation || null}
                    {...register("subtier_validation", { required: false })}
                  />
                }
                label="I validate"
              />
            </FormControl>
            {errors.subtier_validation ? (
              <FormFieldErrorTypography text={FieldMandatoryText} />
            ) : null}
          </FormFieldBox>

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={formData?.additional_comment || ""}
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />

      <AddSpecListModal
        title={selectedSubtierForModal?.title + " list"}
        rowsAlreadyAdded={rowsToNotShowInModal}
        handleAddSpecToFormTable={handleAddSpecToFormTable}
      />
    </>
  );
};

import { Box, Button, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledButton = styled(Button)({
  height: "40px",
  borderRadius: "8px",
});

export const StyledAddIcon = styled(AddIcon)({
  width: "24px",
  height: "24px",
});

export const StyledButtonTypography = styled(CustomTypography)({
  lineHeight: "20px",
  letterSpacing: "0.25px",
});

export const StyledTypography = styled(CustomTypography)({
  lineHeight: "20px",
  letterSpacing: "0.5px",
  color: "rgba(0, 0, 0, 0.54)",
});

export const StyledInnerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  alignItems: "center",
  marginTop: "-32px",
});

export const StyledTableBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

export const StyledTableTypography = styled(CustomTypography)({
  lineHeight: "20px",
  letterSpacing: "0.5px",
  color: "rgba(0, 0, 0, 0.87)",
});

export const StyledFormFieldBox = styled(Box)({
  width: "48%",
  display: "flex",
});

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AclpClassification,
  AddOrUpdateProcessControlStandard,
  AddSpecToScopeRequest,
  AddSpecToSubtierSupplier,
  AddSpecToSubtierSupplierSuccess,
  ApprovalFormData,
  DeleteProcessControlStandard,
  DeleteSpecOfSubtierSupplier,
  DesignAndBuiltDrawingsResponse,
  FetchDesignAndBuiltDrawings,
  FetchFormDataRequest,
  FetchSpecsOfScopeResponse,
  FetchSpecsOfSubtierSupplier,
  FetchStepsRequest,
  FetchSubtierCompaniesResponse,
  FileObj,
  InternationalStandardTableRow,
  LeadTimeForImplementationFormData,
  Pagination,
  PaginationAndFilterForSpecification,
  Questionnaire,
  RemoveSpecFromScopeRequest,
  SaveAdditionalComment,
  SaveSpecOfScopeRequest,
  SaveSpecOfScopeSuccess,
  SetQuestionnaireFormStepData,
  SpecOfSubtierSupplier,
  Specification,
  Step,
  SubtierOnboardingTableRow,
  SubtiersFormData,
  SupplierCategory,
  UpdateApprovalFormFile,
  UpdateDesignAndBuildDrawing,
  UpdateDesignAndBuildDrawingSuccess,
  UpdateFormDataRequest,
  UpdateLeadTimeImplFormData,
  UpdateSpecOfSubtierSupplier,
  UpdateSpecOfSubtierSupplierSuccess,
  UpdateSubtierFormData,
  UpdateSubtierOnboardingTableRow,
  UpdateSubtiersOnboardingFormData,
  VendorManagementState,
} from "./types";
import { orderSteps } from "../utils/orderSteps";

export const initialState: VendorManagementState = {
  formDataLoading: false,
  formDataError: undefined,
  formData: undefined,

  updateFormDataLoading: false,
  updateFormDataError: undefined,

  fetchStepsLoading: false,
  fetchStepsError: undefined,

  updateStepLoading: false,
  updateStepError: undefined,

  processControlStandardsLoading: false,
  processControlStandardsError: undefined,
  processControlStandardsData: undefined,

  allSpecificationsLoading: false,
  allSpecificationsError: undefined,
  allSpecificationsData: undefined,

  deleteProcessControlStandardLoading: false,
  deleteProcessControlStandardError: undefined,

  addProcessControlStandardLoading: false,
  addProcessControlStandardError: undefined,

  updateProcessControlStandardLoading: false,
  updateProcessControlStandardError: undefined,

  updateApprovalFormFileLoading: false,
  updateApprovalFormFileError: undefined,

  deleteApprovalFormFileLoading: false,
  deleteApprovalFormFileError: undefined,

  aclpClassificationsLoading: false,
  aclpClassificationsError: undefined,
  aclpClassificationsData: undefined,

  supplierCategoriesLoading: false,
  supplierCategoriesError: undefined,
  supplierCategoriesData: undefined,

  specsOfScopeLoading: false,
  specsOfScopeError: undefined,
  specsOfScopeData: undefined,

  removeSpecFromScopeLoading: false,
  removeSpecFromScopeError: undefined,

  saveAdditionalCommentLoading: false,
  saveAdditionalCommentError: undefined,

  addSpecToScopeLoading: false,
  addSpecToScopeError: undefined,

  saveSpecOfScopeLoading: false,
  saveSpecOfScopeError: undefined,

  updateLeadTimeImplFormDataLoading: false,
  updateLeadTimeImplFormDataError: undefined,

  updateSubtierFormDataLoading: false,
  updateSubtierFormDataError: undefined,

  subtierCompaniesLoading: false,
  subtierCompaniesError: undefined,
  subtierCompaniesData: undefined,

  specsOfSubtierSupplierLoading: false,
  specsOfSubtierSupplierError: undefined,
  specsOfSubtierSuppliersData: undefined,

  updateSpecOfSubtierSupplierLoading: false,
  updateSpecOfSubtierSupplierError: undefined,

  deleteSpecOfSubtierSupplierLoading: false,
  deleteSpecOfSubtierSupplierError: undefined,

  addSpecToSubtierSupplierLoading: false,
  addSpecToSubtierSupplierError: undefined,

  updateSubtiersOnboardingFormDataLoading: false,
  updateSubtiersOnboardingFormDataError: undefined,

  subtierOnboardingTableDataLoading: false,
  subtierOnboardingTableDataError: undefined,
  subtierOnboardingTableData: undefined,

  updateSubtierOnboardingTableRowLoading: false,
  updateSubtierOnboardingTableRowError: undefined,

  designAndBuiltDrawingsLoading: false,
  designAndBuiltDrawingsError: undefined,
  designAndBuiltDrawingsData: undefined,

  udpateDesignAndBuiltDrawingLoading: false,
  udpateDesignAndBuiltDrawingError: undefined,

  posEndUsersQuestionnaire: {
    name: Questionnaire.PosEndUsers,
    steps: [],
    activeFormStepIndex: 0,
    formStepsData: {
      identificationForm: {
        company_id: "",
        title: "",
        address: "",
        manufacturing_site_address: "",
        vendor_code: "",
        cage_code: "",
        classification: null,
        supplier_category: null,
        email_points_of_contact: "",
        has_subtiers: null,
        additional_comment: "",
      },
      approvalForm: [],
      onboardingForm: {
        supplier_access_id: null,
        isupply: null,
        A2emm_001: null,
        am9000: null,
        aqpl_airbus: null,
        a220_asl_airbus: null,
        normaster: null,
        company: "",
        additional_comment: "",
      },
      scopeForm: {
        scope_id: null,
        specs_count: null,
        source_of_supply: null,
        manufacturing_site: null,
        company: null,
        additional_comment: "",
        specifications: null,
      },
      a2psProcessControlForm: {
        has_captive_laboratory: null,
        additional_comment: "",
        company: "",
        process_control_id: null,
      },
      leadTimeForImplementationForm: {
        lead_time_id: null,
        specification_integration: null,
        road_map: null,
        additional_comment: "",
        company: "",
      },
      designAndBuiltForm: {
        design_and_build_id: null,
        company: "",
        additional_comment: "",
      },
      subtiersForm: {
        subtier_id: null,
        company: "",
        subtier_suppliers: [],
        subtier_suppliers_info: [],
        subtier_validation: null,
        additional_comment: "",
      },
      subtiersOnboardingForm: {
        confirmSubtierOnboarding: null,
        additional_comment: "",
      },
    },
    lastSavedDate: "",
  },
  pomManufacturerQuestionnaire: {
    name: Questionnaire.PomManufacturer,
    steps: [],
    activeFormStepIndex: 0,
    formStepsData: {
      identificationForm: {
        company_id: "",
        title: "",
        address: "",
        manufacturing_site_address: "",
        vendor_code: "",
        cage_code: "",
        classification: null,
        supplier_category: null,
        email_points_of_contact: "",
        additional_comment: "",
      },
      approvalForm: [],
      onboardingForm: {
        supplier_access_id: null,
        isupply: null,
        A2emm_001: null,
        am9000: null,
        aqpl_airbus: null,
        a220_asl_airbus: null,
        normaster: null,
        company: "",
        additional_comment: "",
      },
      scopeForm: {
        scope_id: null,
        specs_count: null,
        source_of_supply: null,
        manufacturing_site: null,
        company: null,
        additional_comment: "",
        specifications: null,
      },
      leadTimeForImplementationForm: {
        lead_time_id: null,
        specification_integration: null,
        additional_comment: "",
        company: "",
      },
      leadTimeForProductionForm: {
        tableData: null,
        additionalComment: "",
      },
    },
    lastSavedDate: "",
  },
  pomDistributorQuestionnaire: {
    name: Questionnaire.PomDistributor,
    steps: [],
    activeFormStepIndex: 0,
    formStepsData: {
      identificationForm: {
        company_id: "",
        title: "",
        address: "",
        manufacturing_site_address: "",
        vendor_code: "",
        cage_code: "",
        classification: null,
        supplier_category: null,
        email_points_of_contact: "",
        additional_comment: "",
      },
      approvalForm: [],
      onboardingForm: {
        supplier_access_id: null,
        isupply: null,
        A2emm_001: null,
        am9000: null,
        aqpl_airbus: null,
        a220_asl_airbus: null,
        normaster: null,
        company: "",
        additional_comment: "",
      },
      scopeForm: {
        scope_id: null,
        specs_count: null,
        source_of_supply: null,
        manufacturing_site: null,
        company: null,
        additional_comment: "",
        specifications: null,
      },
      leadTimeForImplementationForm: {
        lead_time_id: null,
        specification_integration: null,
        additional_comment: "",
        company: "",
      },
      bufferStockStrategyForm: {
        tableData: null,
        additionalComment: "",
      },
    },
    lastSavedDate: "",
  },
  currentQuestionnaireName: Questionnaire.PosEndUsers,
  isThereUnsavedChangesInFormStep: false,
  isSupplierApproved: null,
  isShowGreetingPage: true,
};

export const vendorManagementSlice = createSlice({
  name: "vendorManagement",
  initialState,
  reducers: {
    fetchFormDataRequest: (
      state,
      action: PayloadAction<FetchFormDataRequest>
    ) => {
      state.formDataLoading = true;
      state.formDataError = undefined;
      state.formData = undefined;
    },
    fetchFormDataFailure: (state, action: PayloadAction<Error>) => {
      state.formDataLoading = false;
      state.formDataError = action.payload;
    },
    fetchFormDataSuccess: (
      state,
      action: PayloadAction<SetQuestionnaireFormStepData>
    ) => {
      const { stepName, data } = action.payload;
      console.log("fetchFormDataSuccess", stepName, data);
      const currentQuestionnaire = state[state.currentQuestionnaireName];

      state.formData = data;
      state.formDataLoading = false;
      state.formDataError = undefined;
      currentQuestionnaire.formStepsData[stepName] = data;
    },
    updateFormDataRequest: (
      state,
      action: PayloadAction<UpdateFormDataRequest>
    ) => {
      state.updateFormDataLoading = true;
      state.updateFormDataError = undefined;
    },
    updateFormDataFailure: (state, action: PayloadAction<Error>) => {
      state.updateFormDataLoading = false;
      state.updateFormDataError = action.payload;
    },
    updateFormDataSuccess: (
      state,
      action: PayloadAction<SetQuestionnaireFormStepData>
    ) => {
      const { stepName, data } = action.payload;
      const currentQuestionnaire = state[state.currentQuestionnaireName];

      state.formData = data;
      state.updateFormDataLoading = false;
      state.updateFormDataError = undefined;
      currentQuestionnaire.formStepsData[stepName] = data;
      state.isThereUnsavedChangesInFormStep = false;
    },
    fetchStepsRequest: (state, action: PayloadAction<FetchStepsRequest>) => {
      state.fetchStepsLoading = true;
      state.fetchStepsError = undefined;
    },
    fetchStepsFailure: (state, action: PayloadAction<Error>) => {
      state.fetchStepsLoading = false;
      state.fetchStepsError = action.payload;
    },
    fetchStepsSuccess: (state, action: PayloadAction<Step[]>) => {
      const currentQuestionnaire = state[state.currentQuestionnaireName];
      state.fetchStepsLoading = false;
      state.fetchStepsError = undefined;
      currentQuestionnaire.steps = orderSteps(action.payload);
    },
    updateStepRequest: (state, action: PayloadAction<Step>) => {
      state.updateStepLoading = true;
      state.updateStepError = undefined;
    },
    updateStepFailure: (state, action: PayloadAction<Error>) => {
      state.updateStepLoading = false;
      state.updateStepError = action.payload;
    },
    updateStepSuccess: (state, action: PayloadAction<Step>) => {
      const currentQuestionnaire = state[state.currentQuestionnaireName];
      const updatedSteps = currentQuestionnaire.steps.map((step) => {
        if (step.id === action.payload.id) {
          return action.payload;
        }
        return step;
      });
      const orderedSteps = orderSteps(updatedSteps);
      currentQuestionnaire.steps = orderedSteps;
      state.updateStepLoading = false;
      state.updateStepError = undefined;
    },
    fetchProcessControlStandardsRequest: (
      state,
      action: PayloadAction<string>
    ) => {
      state.processControlStandardsLoading = true;
      state.processControlStandardsError = undefined;
    },
    fetchProcessControlStandardsFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.processControlStandardsLoading = false;
      state.processControlStandardsError = action.payload;
    },
    fetchProcessControlStandardsSuccess: (
      state,
      action: PayloadAction<InternationalStandardTableRow[]>
    ) => {
      state.processControlStandardsLoading = false;
      state.processControlStandardsError = undefined;
      state.processControlStandardsData = action.payload;
    },
    fetchAllSpecificationsRequest: (
      state,
      action: PayloadAction<PaginationAndFilterForSpecification>
    ) => {
      state.allSpecificationsLoading = true;
      state.allSpecificationsError = undefined;
    },
    fetchAllSpecificationsFailure: (state, action: PayloadAction<Error>) => {
      state.allSpecificationsLoading = false;
      state.allSpecificationsError = action.payload;
    },
    fetchAllSpecificationsSuccess: (
      state,
      action: PayloadAction<Specification>
    ) => {
      state.allSpecificationsLoading = false;
      state.allSpecificationsError = undefined;
      state.allSpecificationsData = action.payload;
    },
    deleteProcessControlStandardRequest: (
      state,
      action: PayloadAction<DeleteProcessControlStandard>
    ) => {
      state.deleteProcessControlStandardLoading = true;
      state.deleteProcessControlStandardError = undefined;
    },
    deleteProcessControlStandardFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.deleteProcessControlStandardLoading = false;
      state.deleteProcessControlStandardError = action.payload;
    },
    deleteProcessControlStandardSuccess: (
      state,
      action: PayloadAction<DeleteProcessControlStandard>
    ) => {
      state.deleteProcessControlStandardLoading = false;
      state.deleteProcessControlStandardError = undefined;
      state.processControlStandardsData =
        state.processControlStandardsData?.filter(
          (standard) => standard.standard_id !== action.payload.standardId
        );
    },
    addProcessControlStandardRequest: (
      state,
      action: PayloadAction<AddOrUpdateProcessControlStandard>
    ) => {
      state.addProcessControlStandardLoading = true;
      state.addProcessControlStandardError = undefined;
    },
    addProcessControlStandardFailure: (state, action: PayloadAction<Error>) => {
      state.addProcessControlStandardLoading = false;
      state.addProcessControlStandardError = action.payload;
    },
    addProcessControlStandardSuccess: (
      state,
      action: PayloadAction<InternationalStandardTableRow>
    ) => {
      state.addProcessControlStandardLoading = false;
      state.addProcessControlStandardError = undefined;
      state.processControlStandardsData = [
        ...state.processControlStandardsData,
        action.payload,
      ];
    },
    updateProcessControlStandardRequest: (
      state,
      action: PayloadAction<AddOrUpdateProcessControlStandard>
    ) => {
      state.updateProcessControlStandardLoading = true;
      state.updateProcessControlStandardError = undefined;
    },
    updateProcessControlStandardFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateProcessControlStandardLoading = false;
      state.updateProcessControlStandardError = action.payload;
    },
    updateProcessControlStandardSuccess: (
      state,
      action: PayloadAction<InternationalStandardTableRow>
    ) => {
      state.updateProcessControlStandardLoading = false;
      state.updateProcessControlStandardError = undefined;
      state.processControlStandardsData =
        state.processControlStandardsData?.map((standard) => {
          if (standard.standard_id === action.payload.standard_id) {
            return action.payload;
          }
          return standard;
        });
    },
    updateApprovalFormFileRequest: (
      state,
      action: PayloadAction<UpdateApprovalFormFile>
    ) => {
      state.updateApprovalFormFileLoading = true;
      state.updateApprovalFormFileError = undefined;
    },
    updateApprovalFormFileFailure: (state, action: PayloadAction<Error>) => {
      state.updateApprovalFormFileLoading = false;
      state.updateApprovalFormFileError = action.payload;
    },
    updateApprovalFormFileSuccess: (state, action: PayloadAction<FileObj>) => {
      state.formData = (state.formData as ApprovalFormData)?.map((fileObj) => {
        if (fileObj.field_name === action.payload.field_name) {
          return action.payload;
        }
        return fileObj;
      });
      state.updateApprovalFormFileLoading = false;
      state.updateApprovalFormFileError = undefined;
    },
    deleteApprovalFormFileRequest: (state, action: PayloadAction<string>) => {
      state.deleteApprovalFormFileLoading = true;
      state.deleteApprovalFormFileError = undefined;
    },
    deleteApprovalFormFileFailure: (state, action: PayloadAction<Error>) => {
      state.deleteApprovalFormFileLoading = false;
      state.deleteApprovalFormFileError = action.payload;
    },
    deleteApprovalFormFileSuccess: (state, action: PayloadAction<string>) => {
      state.formData = (state.formData as ApprovalFormData)?.map((fileObj) => {
        if (fileObj.file_id === action.payload) {
          return {
            ...fileObj,
            file_url: null,
            file_id: null,
            file_name: null,
            file_type: null,
            file_size: null,
          };
        }

        return fileObj;
      });
      state.deleteApprovalFormFileLoading = false;
      state.deleteApprovalFormFileError = undefined;
    },
    fetchAclpClassificationsRequest: (state) => {
      state.aclpClassificationsLoading = true;
      state.aclpClassificationsError = undefined;
    },
    fetchAclpClassificationsFailure: (state, action: PayloadAction<Error>) => {
      state.aclpClassificationsLoading = false;
      state.aclpClassificationsError = action.payload;
    },
    fetchAclpClassificationsSuccess: (
      state,
      action: PayloadAction<AclpClassification[]>
    ) => {
      state.aclpClassificationsLoading = false;
      state.aclpClassificationsError = undefined;
      state.aclpClassificationsData = action.payload;
    },
    fetchSupplierCategoriesRequest: (state) => {
      state.supplierCategoriesLoading = true;
      state.supplierCategoriesError = undefined;
    },
    fetchSupplierCategoriesFailure: (state, action: PayloadAction<Error>) => {
      state.supplierCategoriesLoading = false;
      state.supplierCategoriesError = action.payload;
    },
    fetchSupplierCategoriesSuccess: (
      state,
      action: PayloadAction<SupplierCategory[]>
    ) => {
      state.supplierCategoriesLoading = false;
      state.supplierCategoriesError = undefined;
      state.supplierCategoriesData = action.payload;
    },
    fetchSpecsOfScopeRequest: (
      state,
      action: PayloadAction<{
        companyId: string;
        isA2PSControl: boolean;
        pagination: Pagination;
      }>
    ) => {
      state.specsOfScopeLoading = true;
      state.specsOfScopeError = undefined;
      state.specsOfScopeData = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
    },
    fetchSpecsOfScopeFailure: (state, action: PayloadAction<Error>) => {
      state.specsOfScopeLoading = false;
      state.specsOfScopeError = action.payload;
    },
    fetchSpecsOfScopeSuccess: (
      state,
      action: PayloadAction<FetchSpecsOfScopeResponse>
    ) => {
      state.specsOfScopeLoading = false;
      state.specsOfScopeError = undefined;
      state.specsOfScopeData = action.payload;
    },
    removeSpecFromScopeRequest: (
      state,
      action: PayloadAction<RemoveSpecFromScopeRequest>
    ) => {
      state.removeSpecFromScopeLoading = true;
      state.removeSpecFromScopeError = undefined;
    },
    removeSpecFromScopeFailure: (state, action: PayloadAction<Error>) => {
      state.removeSpecFromScopeLoading = false;
      state.removeSpecFromScopeError = action.payload;
    },
    removeSpecFromScopeSuccess: (state, action: PayloadAction<number>) => {
      state.removeSpecFromScopeLoading = false;
      state.removeSpecFromScopeError = undefined;
      state.specsOfScopeData = {
        ...state.specsOfScopeData,
        results: state.specsOfScopeData?.results.filter(
          (spec) => spec.specification_id !== action.payload
        ),
      };
    },
    saveAdditionalCommentFieldOfFormRequest: (
      state,
      action: PayloadAction<SaveAdditionalComment>
    ) => {
      state.saveAdditionalCommentLoading = true;
      state.saveAdditionalCommentError = undefined;
    },
    saveAdditionalCommentFieldOfFormFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.saveAdditionalCommentLoading = false;
      state.saveAdditionalCommentError = action.payload;
    },
    addSpecToScopeRequest: (
      state,
      action: PayloadAction<AddSpecToScopeRequest>
    ) => {
      state.addSpecToScopeLoading = true;
      state.addSpecToScopeError = undefined;
    },
    addSpecToScopeFailure: (state, action: PayloadAction<Error>) => {
      state.addSpecToScopeLoading = false;
      state.addSpecToScopeError = action.payload;
    },
    addSpecToScopeSuccess: (state, action: PayloadAction<number>) => {
      state.addSpecToScopeLoading = false;
      state.addSpecToScopeError = undefined;
      state.specsOfScopeData = {
        ...state.specsOfScopeData,
        results: [
          ...state.specsOfScopeData?.results,
          state.allSpecificationsData?.results.find(
            (spec) => spec.specification_id === action.payload
          ),
        ],
      };
    },
    saveSpecOfScopeRequest: (
      state,
      action: PayloadAction<SaveSpecOfScopeRequest>
    ) => {
      state.saveSpecOfScopeLoading = true;
      state.saveSpecOfScopeError = undefined;
    },
    saveSpecOfScopeFailure: (state, action: PayloadAction<Error>) => {
      state.saveSpecOfScopeLoading = false;
      state.saveSpecOfScopeError = action.payload;
    },
    saveSpecOfScopeSuccess: (
      state,
      action: PayloadAction<SaveSpecOfScopeSuccess>
    ) => {
      state.saveSpecOfScopeLoading = false;
      state.saveSpecOfScopeError = undefined;
      state.specsOfScopeData = {
        ...state.specsOfScopeData,
        results: state.specsOfScopeData?.results.map((spec) =>
          spec.specification_id === action.payload.specificationId
            ? { ...spec, ...action.payload.spec }
            : spec
        ),
      };
    },
    updateLeadTimeImplFormDataRequest: (
      state,
      action: PayloadAction<UpdateLeadTimeImplFormData>
    ) => {
      state.saveSpecOfScopeLoading = true;
      state.saveSpecOfScopeError = undefined;
    },
    updateLeadTimeImplFormDataFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.saveSpecOfScopeLoading = false;
      state.saveSpecOfScopeError = action.payload;
    },
    updateLeadTimeImplFormDataSuccess: (
      state,
      action: PayloadAction<LeadTimeForImplementationFormData>
    ) => {
      state.saveSpecOfScopeLoading = false;
      state.saveSpecOfScopeError = undefined;
      state.formData = action.payload;
    },
    updateSubtierFormDataRequest: (
      state,
      action: PayloadAction<UpdateSubtierFormData>
    ) => {
      state.updateSubtierFormDataLoading = true;
      state.updateSubtierFormDataError = undefined;
    },
    updateSubtierFormDataFailure: (state, action: PayloadAction<Error>) => {
      state.updateSubtierFormDataLoading = false;
      state.updateSubtierFormDataError = action.payload;
    },
    updateSubtierFormDataSuccess: (state, action: PayloadAction<any>) => {
      state.updateSubtierFormDataLoading = false;
      state.updateSubtierFormDataError = undefined;
      state.formData = action.payload as SubtiersFormData;

      const subtierIds = state.formData.subtier_suppliers;
      state.specsOfSubtierSuppliersData =
        state.specsOfSubtierSuppliersData.filter((spec) =>
          subtierIds.includes(spec.supplier_id)
        );
    },
    fetchSubtierCompaniesRequest: (state, action: PayloadAction<string>) => {
      state.subtierCompaniesLoading = true;
      state.subtierCompaniesError = undefined;
    },
    fetchSubtierCompaniesFailure: (state, action: PayloadAction<Error>) => {
      state.subtierCompaniesLoading = false;
      state.subtierCompaniesError = action.payload;
    },
    fetchSubtierCompaniesSuccess: (
      state,
      action: PayloadAction<FetchSubtierCompaniesResponse>
    ) => {
      state.subtierCompaniesLoading = false;
      state.subtierCompaniesError = undefined;
      state.subtierCompaniesData = action.payload;
    },
    fetchSpecsOfSubtierSupplierRequest: (
      state,
      action: PayloadAction<FetchSpecsOfSubtierSupplier>
    ) => {
      state.specsOfSubtierSupplierLoading = true;
      state.specsOfSubtierSupplierError = undefined;
    },
    fetchSpecsOfSubtierSupplierFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.specsOfSubtierSupplierLoading = false;
      state.specsOfSubtierSupplierError = action.payload;
    },
    fetchSpecsOfSubtierSupplierSuccess: (
      state,
      action: PayloadAction<SpecOfSubtierSupplier>
    ) => {
      state.specsOfSubtierSupplierLoading = false;
      state.specsOfSubtierSupplierError = undefined;

      const newSubtierSupplier = action.payload;
      const existingIndex = state.specsOfSubtierSuppliersData?.findIndex(
        (supplier) => supplier.supplier_id === newSubtierSupplier.supplier_id
      );

      if (existingIndex !== -1 && existingIndex !== undefined) {
        state.specsOfSubtierSuppliersData![existingIndex] = newSubtierSupplier;
      } else {
        if (state.specsOfSubtierSuppliersData) {
          state.specsOfSubtierSuppliersData.push(newSubtierSupplier);
        } else {
          state.specsOfSubtierSuppliersData = [newSubtierSupplier];
        }
      }
    },
    updateSubtiersOnboardingFormDataRequest: (
      state,
      action: PayloadAction<UpdateSubtiersOnboardingFormData>
    ) => {
      state.updateSubtiersOnboardingFormDataLoading = true;
      state.updateSubtiersOnboardingFormDataError = undefined;
    },
    updateSubtiersOnboardingFormDataFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateSubtiersOnboardingFormDataLoading = false;
      state.updateSubtiersOnboardingFormDataError = action.payload;
    },
    updateSpecOfSubtierSupplierRequest: (
      state,
      action: PayloadAction<UpdateSpecOfSubtierSupplier>
    ) => {
      state.updateSpecOfSubtierSupplierLoading = true;
      state.updateSpecOfSubtierSupplierError = undefined;
    },
    updateSpecOfSubtierSupplierFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateSpecOfSubtierSupplierLoading = false;
      state.updateSpecOfSubtierSupplierError = action.payload;
    },
    updateSpecOfSubtierSupplierSuccess: (
      state,
      action: PayloadAction<UpdateSpecOfSubtierSupplierSuccess>
    ) => {
      state.updateSpecOfSubtierSupplierLoading = false;
      state.updateSpecOfSubtierSupplierError = undefined;
      const { supplierId, specificationId, spec } = action.payload;
      state.specsOfSubtierSuppliersData = state.specsOfSubtierSuppliersData.map(
        (supplier) => {
          if (supplier.supplier_id === supplierId) {
            return {
              ...supplier,
              rows: supplier.rows.map((row) => {
                if (row.specification_id === specificationId) {
                  return {
                    ...row,
                    ...spec,
                  };
                }
                return row;
              }),
            };
          }
          return supplier;
        }
      );
    },
    deleteSpecOfSubtierSupplierRequest: (
      state,
      action: PayloadAction<DeleteSpecOfSubtierSupplier>
    ) => {
      state.deleteSpecOfSubtierSupplierLoading = true;
      state.deleteSpecOfSubtierSupplierError = undefined;
    },
    deleteSpecOfSubtierSupplierFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.deleteSpecOfSubtierSupplierLoading = false;
      state.deleteSpecOfSubtierSupplierError = action.payload;
    },
    deleteSpecOfSubtierSupplierSuccess: (
      state,
      action: PayloadAction<DeleteSpecOfSubtierSupplier>
    ) => {
      state.deleteSpecOfSubtierSupplierLoading = false;
      state.deleteSpecOfSubtierSupplierError = undefined;
      state.specsOfSubtierSuppliersData = state.specsOfSubtierSuppliersData.map(
        (supplier) => {
          if (supplier.supplier_id === action.payload.supplierId) {
            return {
              ...supplier,
              rows: supplier.rows.filter(
                (row) => row.id !== action.payload.specificationId
              ),
            };
          }
          return supplier;
        }
      );
    },
    addSpecToSubtierSupplierRequest: (
      state,
      action: PayloadAction<AddSpecToSubtierSupplier>
    ) => {
      state.addSpecToSubtierSupplierLoading = true;
      state.addSpecToSubtierSupplierError = undefined;
    },
    addSpecToSubtierSupplierFailure: (state, action: PayloadAction<Error>) => {
      state.addSpecToSubtierSupplierLoading = false;
      state.addSpecToSubtierSupplierError = action.payload;
    },
    addSpecToSubtierSupplierSuccess: (
      state,
      action: PayloadAction<AddSpecToSubtierSupplierSuccess>
    ) => {
      state.addSpecToSubtierSupplierLoading = false;
      state.addSpecToSubtierSupplierError = undefined;
      const { supplierId, spec } = action.payload;
      state.specsOfSubtierSuppliersData = state.specsOfSubtierSuppliersData.map(
        (supplier) => {
          if (supplier.supplier_id === supplierId) {
            return {
              ...supplier,
              rows: [...supplier.rows, spec as any],
            };
          }
          return supplier;
        }
      );
    },
    fetchSubtierOnboardingTableDataRequest: (
      state,
      action: PayloadAction<string>
    ) => {
      state.subtierOnboardingTableDataLoading = true;
      state.subtierOnboardingTableDataError = undefined;
    },
    fetchSubtierOnboardingTableDataFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.subtierOnboardingTableDataLoading = false;
      state.subtierOnboardingTableDataError = action.payload;
    },
    fetchSubtierOnboardingTableDataSuccess: (
      state,
      action: PayloadAction<SubtierOnboardingTableRow[]>
    ) => {
      state.subtierOnboardingTableDataLoading = false;
      state.subtierOnboardingTableDataError = undefined;
      state.subtierOnboardingTableData = action.payload;
    },
    updateSubtiersOnboardingFormDataSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.updateSubtiersOnboardingFormDataLoading = false;
      state.updateSubtiersOnboardingFormDataError = undefined;
    },
    updateSubtierOnboardingTableRowRequest: (
      state,
      action: PayloadAction<UpdateSubtierOnboardingTableRow>
    ) => {
      state.updateSubtierOnboardingTableRowLoading = true;
      state.updateSubtierOnboardingTableRowError = undefined;
    },
    updateSubtierOnboardingTableRowFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateSubtierOnboardingTableRowLoading = false;
      state.updateSubtierOnboardingTableRowError = action.payload;
    },
    updateSubtierOnboardingTableRowSuccess: (
      state,
      action: PayloadAction<UpdateSubtierOnboardingTableRow>
    ) => {
      state.updateSubtierOnboardingTableRowLoading = false;
      state.updateSubtierOnboardingTableRowError = undefined;
      state.subtierOnboardingTableData = state.subtierOnboardingTableData?.map(
        (row) => {
          if (row.supplier_uuid === action.payload.supplierId) {
            return { ...row, ...action.payload.data };
          }
          return row;
        }
      );
    },
    fetchDesignAndBuiltDrawingsRequest: (
      state,
      action: PayloadAction<FetchDesignAndBuiltDrawings>
    ) => {
      state.designAndBuiltDrawingsLoading = true;
      state.designAndBuiltDrawingsError = undefined;
    },
    fetchDesignAndBuiltDrawingsFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.designAndBuiltDrawingsLoading = false;
      state.designAndBuiltDrawingsError = action.payload;
    },
    fetchDesignAndBuiltDrawingsSuccess: (
      state,
      action: PayloadAction<DesignAndBuiltDrawingsResponse>
    ) => {
      state.designAndBuiltDrawingsLoading = false;
      state.designAndBuiltDrawingsError = undefined;
      state.designAndBuiltDrawingsData = action.payload;
    },
    updateDesignAndBuiltDrawingRequest: (
      state,
      action: PayloadAction<UpdateDesignAndBuildDrawing>
    ) => {
      state.udpateDesignAndBuiltDrawingLoading = true;
      state.udpateDesignAndBuiltDrawingError = undefined;
    },
    updateDesignAndBuiltDrawingFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.udpateDesignAndBuiltDrawingLoading = false;
      state.udpateDesignAndBuiltDrawingError = action.payload;
    },
    updateDesignAndBuiltDrawingSuccess: (
      state,
      action: PayloadAction<UpdateDesignAndBuildDrawingSuccess>
    ) => {
      state.udpateDesignAndBuiltDrawingLoading = false;
      state.udpateDesignAndBuiltDrawingError = undefined;
      state.designAndBuiltDrawingsData.results =
        state.designAndBuiltDrawingsData.results.map((drawing) => {
          if (drawing.drawing_id === action.payload.drawingId) {
            return { ...drawing, ...action.payload.partOfRow };
          }
          return drawing;
        });
    },
    setQuestionnaireName: (state, action: PayloadAction<Questionnaire>) => {
      state.currentQuestionnaireName = action.payload;
    },
    setActiveFormStepIndex: (state, action: PayloadAction<number>) => {
      const currentQuestionnaire = state[state.currentQuestionnaireName];
      currentQuestionnaire.activeFormStepIndex = action.payload;
    },
    setQuestionnaireFormStepData: (
      // TO-DO: remove after api integration
      state,
      action: PayloadAction<SetQuestionnaireFormStepData>
    ) => {
      const { stepName, data } = action.payload;
      const currentQuestionnaire = state[state.currentQuestionnaireName];

      currentQuestionnaire.formStepsData[stepName] = data;
    },
    setIsThereUnsavedChangesInFormStep: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isThereUnsavedChangesInFormStep = action.payload;
    },
    checkFieldChangeForFormStep: (
      // TO-DO: remove after api integration
      state,
      action: PayloadAction<{
        isFormCompleted: boolean;
        isThereUnsavedChanges: boolean;
      }>
    ) => {
      const { isFormCompleted, isThereUnsavedChanges } = action.payload;
      if (isFormCompleted) {
        /* const currentQuestionnaire = state[state.currentQuestionnaireName];
        const activeFormStepIndex = currentQuestionnaire.activeFormStepIndex;
        const newCompletedSteps = { ...currentQuestionnaire.completedSteps };
        newCompletedSteps[activeFormStepIndex] = false;

        currentQuestionnaire.completedSteps = newCompletedSteps; */

        state.isThereUnsavedChangesInFormStep = true;
      } else if (!isThereUnsavedChanges) {
        state.isThereUnsavedChangesInFormStep = true;
      }
    },
    setRouteNextStep: (
      state,
      action: PayloadAction<{
        isLastStep: boolean;
      }>
    ) => {
      const { isLastStep } = action.payload;
      const currentQuestionnaire = state[state.currentQuestionnaireName];
      const allSteps = currentQuestionnaire.steps.filter(
        (step) => step.is_visible
      );
      const completedStatusesOfSteps = currentQuestionnaire.steps.map(
        (step) => step.is_completed
      );
      const isAllStepsCompleted = allSteps?.every(
        (_: any, i: number) => completedStatusesOfSteps[i]
      );
      const activeFormStepIndex = currentQuestionnaire.activeFormStepIndex;

      if (isAllStepsCompleted || isLastStep) {
        return;
      }
      const newActiveStep = activeFormStepIndex + 1;

      currentQuestionnaire.activeFormStepIndex = newActiveStep;
    },
    setIsShowGreetingPage: (state, action: PayloadAction<boolean>) => {
      state.isShowGreetingPage = action.payload;
    },
  },
});

export const vendorManagementActions = vendorManagementSlice.actions;

export default vendorManagementSlice.reducer;

import { AirbusSpecificationTableRow } from "../redux";

export const validateAirbusSpecTableRows = (
  rows: AirbusSpecificationTableRow[]
): boolean => {
  if (!rows || rows.length === 0) return false;

  return rows.every(
    (row) => row.scope_limitation !== "" && row.scope_limitation !== undefined
  );
};

import React from "react";
import { Menu, MenuItem, Typography } from "@mui/material";

type menuItem = {
  key: string;
  value: string;
};

type Props = {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  setRows?: React.Dispatch<React.SetStateAction<any[]>>;
  row: any;
  field: string;
  menuItems: menuItem[];
  handleClick?: (option: string) => void;
};

const isItemSelected = (row: any, field: string, item: string): boolean => {
  if (!row || row[field] === undefined || row[field] === null) {
    return false;
  }
  return row[field] === item;
};

export const SelectOptionMenu: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  row,
  field,
  menuItems,
  handleClick,
}) => {
  const openMenu = Boolean(anchorEl);

  const handleCloseMenuItem = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleCloseMenuItem}
    >
      {menuItems.map((item) => (
        <MenuItem
          onClick={() => handleClick(item.key)}
          key={item.key}
          selected={isItemSelected(row, field, item.key)}
          sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
        >
          <Typography variant="caption">{item.value}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

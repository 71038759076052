import React from "react";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "./types";
import { PartUpload, projectActions } from "../redux";
import {
  selectProjectCompanyPart,
  selectSelectedPartPk,
} from "../redux/selectors";
import { workspaceActions } from "services/workspace";
import { getToken } from "services/profile/profile.selectors";
import { selectRFQPart } from "services/workspace/workspace.selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";

type Props = {
  isRFQPart?: boolean;
  isRFQEditMode?: boolean;
  customDeleteHandler?: () => void;
  customUpdateHandler?: (filePK: number) => void;
};

export const ProjectFileDeleteConfirmationModal: React.FC<Props> = ({
  isRFQPart,
  isRFQEditMode,
  customDeleteHandler,
  customUpdateHandler,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(
    getModal(ProjectModalTypes.FILE_DELETE_CONFIRMATION_MODAL)
  );
  const token = useSelector(getToken);
  const deleteFilePk = modal?.data as number;

  const selectedPartPk = useSelector(selectSelectedPartPk) as number;
  const selectedPart = useSelector(
    isRFQPart
      ? selectRFQPart(selectedPartPk)
      : selectProjectCompanyPart(selectedPartPk)
  );

  const closeModal = () => {
    dispatch(
      modalsActions.closeModal(ProjectModalTypes.FILE_DELETE_CONFIRMATION_MODAL)
    );
  };

  const onClickDeletePart = () => {
    if (customDeleteHandler instanceof Function && deleteFilePk === -1) {
      customDeleteHandler();
      closeModal();
      return;
    }

    if (customUpdateHandler instanceof Function && isRFQEditMode) {
      customUpdateHandler(deleteFilePk);
      closeModal();
      return;
    }

    const newFileIds =
      selectedPart?.part_uploads_list
        .map((item: PartUpload) => item.pk)
        .filter((item: number) => item !== deleteFilePk) || [];

    const data = {
      files: newFileIds,
    };

    if (isRFQPart) {
      batch(() => {
        dispatch(
          workspaceActions.editPart(token, data, selectedPartPk, t, true)
        );
        dispatch(workspaceNextActions.setShowRevisionStatusBar(true));
      });
    } else {
      dispatch(
        projectActions.updateProjectPartRequest({
          pk: selectedPartPk as number,
          part: data,
        })
      );
    }

    closeModal();
  };

  return (
    <ConfirmationDialog
      title={t("workspace:deleteThisFile")}
      content={t("workspaceNext:thisActionIsIrreversible")}
      isOpen={!!modal}
      handleClose={closeModal}
      dialogActions={
        <div>
          <Button autoFocus onClick={closeModal} color="primary">
            {t("workspace:cancel")}
          </Button>
          <Button onClick={onClickDeletePart} color="primary">
            {t("RFQ:yes")}
          </Button>
        </div>
      }
    />
  );
};

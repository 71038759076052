import React, { useEffect } from "react";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import { Menu } from "@mui/material";
import { transformDateToISOFormat } from "@next/modules/vendor-management/utils/transformDateToISOFormat";

type Props = {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  row: any;
  handleChange?: (date: Date | null) => void;
};

export const SelectDateMenu: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  row,
  handleChange,
}) => {
  const openMenu = Boolean(anchorEl);
  const [isPastDate, setIsPastDate] = React.useState(false);

  useEffect(() => {
    if (
      row?.compliance_status === "fully_compliant" ||
      row?.compliance_status === "compliant_with_rfd" ||
      row?.compliance === "fully_compliant" ||
      row?.conform_to_a2x === "conform_to_a2x"
    ) {
      setIsPastDate(true);
    } else {
      setIsPastDate(false);
    }
  }, [row]);

  const handleCloseMenuItem = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleCloseMenuItem}
    >
      <MUIDatePicker
        variant="static"
        value={row?.poe ? transformDateToISOFormat(row.poe) : ""}
        disablePast={!isPastDate}
        disableFuture={isPastDate}
        onChange={handleChange}
      />
    </Menu>
  );
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { SubtierOnboardingFormTable } from "../../table/subtier-onboarding-form-table/subtier-onboarding-form-table";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import {
  selectActiveFormStepIndex,
  selectFormData,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  FieldMandatoryText,
  Step,
  SubtiersOnboardingFormData,
  UpdateSubtiersOnboardingFormData,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import * as S from "./subtiers-onboarding-form.styled";

const completionText =
  "On completion of the transformation programme, all suppliers and second tier suppliers will lose all " +
  "access to applications on the Bombardier system, and all applications will have to be accessed via Airbus gateway(s). " +
  "Only tier 1 companies that have a contract with Airbus can be registered on Airbus Partner Portal (APP). " +
  "As per Airbus policy (and as stated in the GTC document and Airbus methods), second tier (or nth tier) suppliers are managed by the tier 1 company. " +
  "Setting up n-tier suppliers will require additional IM user configuration. Will the Subtier be impacted info management (IM) Separation ?";

export const SubtiersOnboardingForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const steps = useSelector(selectSteps);
  const stepIndex = useSelector(selectActiveFormStepIndex);
  const subtierOnboardingFormData = useSelector(
    selectFormData
  ) as SubtiersOnboardingFormData;
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setError,
  } = useForm<SubtiersOnboardingFormData>({
    defaultValues: subtierOnboardingFormData,
  });

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchSubtierOnboardingTableDataRequest(companyId)
    );
  }, []);

  const saveChanges = (isForCompletion: boolean) => {
    const additionalComment = getValues("additional_comment");
    const isSubtierWillBeImpactedIMSeparation = getValues(
      "confirmSubtierOnboarding"
    );
    const body: UpdateSubtiersOnboardingFormData = {
      formData: {
        confirmSubtierOnboarding: isSubtierWillBeImpactedIMSeparation,
        additional_comment: additionalComment,
      },
      companyId: companyId,
    };
    dispatch(
      vendorManagementActions.updateSubtiersOnboardingFormDataRequest({
        ...body,
      })
    );

    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true);
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<SubtiersOnboardingFormData> = (
    data
  ) => {
    const isSubtierWillBeImpactedIMSeparation = getValues(
      "confirmSubtierOnboarding"
    );
    if (
      isSubtierWillBeImpactedIMSeparation !== null &&
      isSubtierWillBeImpactedIMSeparation !== undefined
    ) {
      saveChanges(true);
      showCompletedMessage();
      dispatch(
        vendorManagementActions.setRouteNextStep({
          isLastStep: isLastStep,
        })
      );
    } else {
      setError("confirmSubtierOnboarding", { type: "manual" });
      showInvalidMessage();
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle
            title={"Subtier onboarding"}
            isCompleted={isFormCompleted}
          />

          <FormFieldBox>
            <S.StyledTypography variant="body1">
              Please confirm if your subtiers have access to these tools:
            </S.StyledTypography>

            <SubtierOnboardingFormTable />
          </FormFieldBox>

          <S.StyledFormFieldBox>
            <S.StyledTypography>{completionText}</S.StyledTypography>

            <FormControl error={errors.confirmSubtierOnboarding ? true : false}>
              <Controller
                name="confirmSubtierOnboarding"
                control={control}
                render={({ field }) => (
                  <FormFieldBox>
                    <RadioGroup
                      {...field}
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const value = e.target.value === "true" ? true : false;
                        field.onChange(value);
                        checkInputChange();
                      }}
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>
                    {errors.confirmSubtierOnboarding ? (
                      <FormFieldErrorTypography text={FieldMandatoryText} />
                    ) : null}
                  </FormFieldBox>
                )}
              />
            </FormControl>
          </S.StyledFormFieldBox>

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={subtierOnboardingFormData?.additional_comment || ""}
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};

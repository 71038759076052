import moment from "moment";

export const formatTime = (date: string) => {
  const momentDate = moment.utc(date);

  if (!momentDate.isValid()) {
    return "";
  }

  return momentDate.format("hh:mm a");
};

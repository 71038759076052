import React from "react";
import { FormControl, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldLabel } from "../../form-field-label/form-field-label";
import { BufferStockStrategyFormTable } from "../../table/buffer-stock-strategy-form-table/buffer-stock-strategy-form-table";
import {
  BufferStockStrategyFormData,
  BufferStockStrategyTableRow,
  QuestionnaireForms,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectFormStepData,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
} from "@next/modules/vendor-management/redux/selectors";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { validateBufferStockStrategyTableRows } from "@next/modules/vendor-management/utils/validateBufferStockStrategyTableRows";
import * as S from "./buffer-stock-strategy-form.styled";

const dummyRow: BufferStockStrategyTableRow = {
  id: "1",
  specification: "A2ERD 168-013",
  dash: "-",
  revision: "Rev1",
  controlledProcess: "Process A",
  description: "Description A",
  sourceOfSupply: [
    "BODYCOTE THERMAL PROCESSING INC.",
    "HONEYWELL INTERNATIONAL INC.",
  ],
  manufacturingSite: [
    "456 Production Lane, Industrial Zone, Fremont, CA 94538, USA",
    "1019 MCLEAN BLVD., WICHITA, KS, USA",
  ],
};

export const BufferStockStrategyForm: React.FC = () => {
  const dispatch = useDispatch();
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const formData = useSelector(
    selectFormStepData(QuestionnaireForms.BufferStockStrategy)
  ) as BufferStockStrategyFormData;
  const [rows, setRows] = React.useState<BufferStockStrategyTableRow[]>(
    formData?.tableData || [dummyRow]
  );
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    control,
    watch,
  } = useForm<BufferStockStrategyFormData>({
    defaultValues: formData,
  });

  const saveChanges = () => {
    // TO-DO: add api call to save changes

    const formBody: BufferStockStrategyFormData = {
      tableData: rows,
      additionalComment: watch("additionalComment"),
    };
    console.log("formBody", formBody);

    dispatch(
      vendorManagementActions.setQuestionnaireFormStepData({
        stepName: QuestionnaireForms.BufferStockStrategy,
        data: formBody,
      })
    );
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<BufferStockStrategyFormData> = (
    data
  ) => {
    // if api call is successful, make this form completed and show success message
    const isValidatedTableRows = validateBufferStockStrategyTableRows(rows);
    if (isValidatedTableRows) {
      saveChanges();
      /* dispatch(
        vendorManagementActions.setQuestionnaireCompletedInfoForFormStep(true)
      ); */
      showCompletedMessage();
      dispatch(
        vendorManagementActions.setRouteNextStep({
          isLastStep: isLastStep,
        })
      );
    } else {
      showInvalidMessage();
    }
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    dispatch(
      vendorManagementActions.checkFieldChangeForFormStep({
        isFormCompleted: isFormCompleted,
        isThereUnsavedChanges: isThereUnsavedChanges,
      })
    );
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle
            title={"Buffer stock strategy"}
            isCompleted={isFormCompleted}
          />

          <FormFieldBox>
            <FormFieldLabel label="Buffer stock Strategy : Stock Management from B to A per spec" />

            <BufferStockStrategyFormTable rows={rows} setRows={setRows} />
          </FormFieldBox>

          <FormControl margin="none">
            <Controller
              name="additionalComment"
              control={control}
              defaultValue={formData.additionalComment}
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={saveChanges}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};

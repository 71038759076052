import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { FormTitle } from "../form-title/form-title";
import { selectStepsLoading } from "../../redux/selectors";
import { vendorManagementActions } from "@next/modules/vendor-management/redux";
import * as S from "./greeting-form.styled";

const textList = [
  "• You can navigate through each section independently.",
  "• All saved changes can be done on a section per section basis.",
  "• We will also consider your input final when you confirm step completion.",
  "• Once all sections have been completed, you shall receive a confirmation screen; meaning the questionnaire is complete.",
  "• The questionnaire can not be shared as it is a public secure link only accessible to you.",
];

export const GreetingForm: React.FC = () => {
  const dispatch = useDispatch();
  const requestStepsLoading = useSelector(selectStepsLoading);

  const handleClick = () => {
    if (!requestStepsLoading) {
      dispatch(vendorManagementActions.setIsShowGreetingPage(false));
    }
  };

  return (
    <>
      <S.ImageBox>
        <S.StyledFormBox>
          <FormTitle title={"Information"} isCompleted={null} />

          <S.StyledTypography variant="body1">
            {textList.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </S.StyledTypography>

          <S.StyledButton
            variant="contained"
            startIcon={<CheckIcon sx={{ width: "24px", height: "24px" }} />}
            onClick={handleClick}
          >
            <S.ButtonText variant="body2" tooltipTitle={null}>
              Start
            </S.ButtonText>
          </S.StyledButton>
        </S.StyledFormBox>
      </S.ImageBox>
    </>
  );
};

import { SpecificationTableRow } from "../redux";

export const removeMatchingItems = (
  options: SpecificationTableRow[],
  tableRowsToNotShow: SpecificationTableRow[],
  isModalForA2psForm: boolean
) => {
  if (isModalForA2psForm) {
    // just return specType 'A2ERD' or 'A2TS' with not matching items
    const specTypesToKeep = new Set(["A2ERD", "A2TS"]);
    return options.filter(
      (item) =>
        specTypesToKeep.has(item.specType) &&
        !tableRowsToNotShow.some(
          (toNotShow) => toNotShow.specification_id === item.specification_id
        )
    );
  } else {
    // just return not matching items
    const idsToRemove = new Set(
      tableRowsToNotShow.map((item) => item.specification_id)
    );
    return options.filter((item) => !idsToRemove.has(item.specification_id));
  }
};

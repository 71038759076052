import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { getFileExtension } from "../../utils/getFileExtension";
import { formatFileSize } from "@next/utils/fileUtils";
import { FileObj } from "../../redux";
import * as S from "./form-attached-file-box.styled";

type Props = {
  file: File | FileObj;
  handleClickRemove: () => void;
};

export const FormAttachedFileBox: React.FC<Props> = ({
  file,
  handleClickRemove,
}) => {
  return (
    <S.RootBox>
      <S.StyledAttachedBox>
        <S.StyledFileBox>
          <S.StyledFileTypography tooltipTitle={null} variant="caption">
            {file instanceof File
              ? getFileExtension(file.name)
              : file?.file_type}
          </S.StyledFileTypography>
        </S.StyledFileBox>

        <S.StyledFileNameTypography tooltipTitle={null} variant="body2">
          {file instanceof File ? file.name : file.file_name}
        </S.StyledFileNameTypography>

        <S.StyledAttachmentTypography tooltipTitle={null} variant="body2">
          {file instanceof File
            ? formatFileSize(file.size, 2)
            : formatFileSize(file.file_size, 2)}
        </S.StyledAttachmentTypography>
      </S.StyledAttachedBox>

      <S.StyledIconButton onClick={handleClickRemove}>
        <CloseIcon />
      </S.StyledIconButton>
    </S.RootBox>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { modalsActions } from "@next/redux/modalsSlices";
import { AirbusSpecificationTable } from "../../../table/airbus-specification-table/airbus-specification-table";
import { FormAddButton } from "../../../form-add-button/form-add-button";
import { FormFieldErrorTypography } from "../../../form-field-error-typography/form-field-error-typography";
import { AddSpecListModal } from "../../../modals/add-spec-list-modal/add-spec-list-modal";
import {
  FieldMandatoryText,
  Step,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import * as S from "./add-airbus-specification-field.styled";
import {
  selectActiveFormStepIndex,
  selectIsFormCompleted,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { Box } from "@mui/material";

type Props = {
  isShowTableErrorsIfExist: boolean;
};

export const AddAirbusSpecificationField: React.FC<Props> = ({
  isShowTableErrorsIfExist,
}) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const specTableData = useSelector(selectSpecsOfScope);
  const specTableRows = specTableData?.results || [];
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const specTableRowsLoading = useSelector(selectSpecsOfScopeLoading);
  const steps = useSelector(selectSteps);
  const stepIndex = useSelector(selectActiveFormStepIndex);
  const isFormCompleted = useSelector(selectIsFormCompleted);

  const onClickOpenAddSpecListModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.ADD_SPEC_LIST_MODAL,
      })
    );
  };

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId: companyId,
        isA2PSControl: true,
        pagination: { page: page, pageSize: pageSize },
      })
    );
  }, [page]);

  const handleAddSpecToScope = (specification_id: number) => {
    dispatch(
      vendorManagementActions.addSpecToScopeRequest({
        companyId: companyId,
        specificationId: specification_id,
      })
    );

    checkInputChange();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const updateStepBody: Step = {
      ...steps[stepIndex],
      [key]: value,
    };
    dispatch(vendorManagementActions.updateStepRequest(updateStepBody));
  };

  return (
    <>
      <S.StyledBox>
        <Box>
          <AirbusSpecificationTable setPage={setPage} pageSize={pageSize} />

          <S.StyledBtnBox>
            <FormAddButton
              btnText={"Add Airbus specification"}
              onClick={onClickOpenAddSpecListModal}
              disabled={specTableRowsLoading}
            />
          </S.StyledBtnBox>
        </Box>

        {isShowTableErrorsIfExist && specTableRows.length === 0 ? (
          <FormFieldErrorTypography text={FieldMandatoryText} />
        ) : null}
      </S.StyledBox>

      <AddSpecListModal
        rowsAlreadyAdded={specTableRows}
        handleAddSpecToFormTable={handleAddSpecToScope}
        title={"Spec list"}
      />
    </>
  );
};

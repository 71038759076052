import React, { useEffect } from "react";
import { AppBar, Avatar, Box, styled } from "@mui/material";
import AirbusLogo from "../../../assets/airbus-logo.png";
import { CustomFormStepper } from "../components/custom-form-stepper/custom-form-stepper";
import { Questionnaries } from "../components/questionnaires/questionnaires";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Questionnaire,
  QuestionnaireForms,
  QuestionnaireType,
  vendorManagementActions,
} from "../redux";
import { GreetingForm } from "../components/greeting-form/greeting-form";
import {
  selectActiveFormStepIndex,
  selectIsShowGreetingPage,
  selectSteps,
  selectStepsError,
  selectStepsLoading,
} from "../redux/selectors";
import { ErrorScreen } from "../components/error-screen/error-screen";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#00205B",
  width: "100%",
  height: "72px",
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  alignItems: "center",
  paddingLeft: "16px",
  position: "fixed",
});

const StyledBox = styled(Box)({
  backgroundColor: "#fafafa",
});

export const StyledLoadingBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

type Props = {
  questionnaireName: Questionnaire;
};

export const VendorManagement: React.FC<Props> = ({ questionnaireName }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isShowGreetingPage = useSelector(selectIsShowGreetingPage);
  const steps = useSelector(selectSteps);
  const formNames = Object.values(QuestionnaireForms);
  const activeFormStepIndex = useSelector(selectActiveFormStepIndex);
  const requestStepsLoading = useSelector(selectStepsLoading);
  const requestStepsError = useSelector(selectStepsError);

  // TO-DO: which step is active?, route to that step, add api call for that
  // TO-DO: if there is an active step, close the greeting page
  useEffect(() => {
    dispatch(vendorManagementActions.setQuestionnaireName(questionnaireName));

    dispatch(
      vendorManagementActions.fetchStepsRequest({
        companyId: companyId,
        questionnaireType: QuestionnaireType[questionnaireName],
      })
    );
  }, [questionnaireName, companyId]);

  const handleClickLogo = () => {
    if (!isShowGreetingPage) {
      dispatch(vendorManagementActions.setIsShowGreetingPage(true));
    }
  };

  if (requestStepsError) {
    return <ErrorScreen message={"Failed loading form steps. Try again."} />;
  }

  return (
    <StyledBox>
      <StyledAppBar>
        <Avatar
          onClick={handleClickLogo}
          sx={{ width: "100px", height: "40px", cursor: "pointer" }}
          src={AirbusLogo}
        />

        {steps ? <CustomFormStepper /> : null}
      </StyledAppBar>

      {isShowGreetingPage ? <GreetingForm /> : <Questionnaries />}
    </StyledBox>
  );
};

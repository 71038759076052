import React, { useEffect, useRef, useState } from "react";
import { Menu, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { CustomTypography } from "@next/components/custom-typography";
import { FormFileAttachmentButton } from "../../../form-file-attachment-button/form-file-attachment-button";
import { FormAttachedFileBox } from "../../../form-attached-file-box/form-attached-file-box";
import * as S from "./evidence-menu.styled";

type Props = {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  setRows?: React.Dispatch<React.SetStateAction<any[]>>;
  row: any;
  field: string;
  handleSaveCommentAndFile?: (comment: string, file: File) => void;
};

export const EvidenceMenu: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  row,
  field,
  handleSaveCommentAndFile,
}) => {
  const openMenu = Boolean(anchorEl);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | "">(""); // TO-DO: check here after api missing points is implemented
  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    if (openMenu) {
      setFile(row["evidence_file"] || row["action_plan_evidence_file"] || "");
      setComment(
        row["evidence_comment"] || row["action_plan_evidence_comment"] || ""
      );
    }
  }, [openMenu, row]);

  const handleCloseMenuItem = () => {
    setAnchorEl(null);
  };

  const handleAttachButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleSave = () => {
    handleSaveCommentAndFile(comment, file as any);

    setAnchorEl(null);
  };

  const handleClickRemove = () => {
    setFile("");
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleCloseMenuItem}
    >
      <S.StyledParentBox>
        {!file ? (
          <S.StyledBox>
            <FormFileAttachmentButton
              handleAttachButtonClick={handleAttachButtonClick}
              handleFileChange={handleFileChange}
              fileInputRef={fileInputRef}
              btnText={"File"}
            />

            <S.StyledInfoTypography variant="body2" tooltipTitle={null}>
              ZIP to attach multiple files.
            </S.StyledInfoTypography>
          </S.StyledBox>
        ) : (
          <S.StyledBox>
            <FormAttachedFileBox
              file={file}
              handleClickRemove={handleClickRemove}
            />
          </S.StyledBox>
        )}

        <TextField
          placeholder="Comment"
          value={comment}
          onChange={handleCommentChange}
          multiline
          minRows={2}
          maxRows={4}
        />

        <S.StyledFooterBox>
          <S.StyledSaveButton
            startIcon={<CheckIcon sx={{ width: "24px", height: "24px" }} />}
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={comment.length === 0 && !file ? true : false}
            disableElevation
          >
            <CustomTypography variant="body2" tooltipTitle={null}>
              Save
            </CustomTypography>
          </S.StyledSaveButton>
        </S.StyledFooterBox>
      </S.StyledParentBox>
    </Menu>
  );
};

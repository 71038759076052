import { RootState } from "store";
import { QuestionnaireForms } from "./types";

export const selectFormData = (state: RootState) =>
  state.vendorManagementNext.formData;
export const selectFormDataLoading = (state: RootState) =>
  state.vendorManagementNext.formDataLoading;
export const selectFormDataError = (state: RootState) =>
  state.vendorManagementNext.formDataError;

export const selectQuestionnaireName = (state: RootState) =>
  state.vendorManagementNext?.currentQuestionnaireName;
export const selectIsFormCompleted = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[
      state.vendorManagementNext.currentQuestionnaireName
    ];
  const activeFormStepIndex = currentQuestionnaire.activeFormStepIndex;
  return currentQuestionnaire.steps[activeFormStepIndex].is_completed;
};

export const selectSteps = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[
      state.vendorManagementNext.currentQuestionnaireName
    ];
  return currentQuestionnaire.steps;
};
export const selectStepsLoading = (state: RootState) =>
  state.vendorManagementNext.fetchStepsLoading;
export const selectStepsError = (state: RootState) =>
  state.vendorManagementNext.fetchStepsError;

export const selectProcessControlStandards = (state: RootState) =>
  state.vendorManagementNext.processControlStandardsData;
export const selectProcessControlStandardsLoading = (state: RootState) =>
  state.vendorManagementNext.processControlStandardsLoading;
export const selectProcessControlStandardsError = (state: RootState) =>
  state.vendorManagementNext.processControlStandardsError;

export const selectAllSpecifications = (state: RootState) =>
  state.vendorManagementNext.allSpecificationsData;
export const selectAllSpecificationsLoading = (state: RootState) =>
  state.vendorManagementNext.allSpecificationsLoading;
export const selectAllSpecificationsError = (state: RootState) =>
  state.vendorManagementNext.allSpecificationsError;

export const selectDeleteProcessControlStandardLoading = (state: RootState) =>
  state.vendorManagementNext.deleteProcessControlStandardLoading;

export const selectAclpClassificationsLoading = (state: RootState) =>
  state.vendorManagementNext.aclpClassificationsLoading;
export const selectAclpClassifications = (state: RootState) =>
  state.vendorManagementNext.aclpClassificationsData;

export const selectSupplierCategoriesLoading = (state: RootState) =>
  state.vendorManagementNext.supplierCategoriesLoading;
export const selectSupplierCategories = (state: RootState) =>
  state.vendorManagementNext.supplierCategoriesData;

export const selectSpecsOfScopeLoading = (state: RootState) =>
  state.vendorManagementNext.specsOfScopeLoading;
export const selectSpecsOfScopeError = (state: RootState) =>
  state.vendorManagementNext.specsOfScopeError;
export const selectSpecsOfScope = (state: RootState) =>
  state.vendorManagementNext.specsOfScopeData;

export const selectRemoveSpecFromScopeLoading = (state: RootState) =>
  state.vendorManagementNext.removeSpecFromScopeLoading;
export const selectAddSpecToScopeLoading = (state: RootState) =>
  state.vendorManagementNext.addSpecToScopeLoading;

export const selectSubtierCompaniesLoading = (state: RootState) =>
  state.vendorManagementNext.subtierCompaniesLoading;
export const selectSubtierCompanies = (state: RootState) =>
  state.vendorManagementNext.subtierCompaniesData?.results;
export const selectSpecsOfSubtierSupplierLoading = (state: RootState) =>
  state.vendorManagementNext.specsOfSubtierSupplierLoading;
export const selectSpecsOfSubtierSupplier =
  (supplier_id: string) => (state: RootState) => {
    return (
      state.vendorManagementNext?.specsOfSubtierSuppliersData?.find(
        (supplier) => supplier.supplier_id === supplier_id
      )?.rows || []
    );
  };
export const selectAllSpecsOfSubtierSupppliers = (state: RootState) =>
  state.vendorManagementNext.specsOfSubtierSuppliersData;
export const selectSubtierOnboardingTableDataLoading = (state: RootState) =>
  state.vendorManagementNext.subtierOnboardingTableDataLoading;
export const selectSubtierOnboardingTableData = (state: RootState) =>
  state.vendorManagementNext.subtierOnboardingTableData;
export const selectDesignAndBuiltDrawingsLoading = (state: RootState) =>
  state.vendorManagementNext.designAndBuiltDrawingsLoading;
export const selectDesignAndBuiltDrawings = (state: RootState) =>
  state.vendorManagementNext.designAndBuiltDrawingsData;

export const selectCompletionStatusesOfSteps = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[
      state.vendorManagementNext.currentQuestionnaireName
    ];
  return currentQuestionnaire?.steps.map((step) => step.is_completed);
};

export const selectActiveFormStepIndex = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[
      state.vendorManagementNext.currentQuestionnaireName
    ];

  return currentQuestionnaire?.activeFormStepIndex;
};

export const selectLastSavedDate = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[
      state.vendorManagementNext.currentQuestionnaireName
    ];
  return currentQuestionnaire.lastSavedDate;
};

export const selectFormStepData = (formStepName: QuestionnaireForms) => {
  return (state: RootState) => {
    const currentQuestionnaire =
      state.vendorManagementNext[
        state.vendorManagementNext.currentQuestionnaireName
      ];
    return currentQuestionnaire.formStepsData[formStepName];
  };
};

export const selectIsLastStep = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[
      state.vendorManagementNext.currentQuestionnaireName
    ];
  const activeFormStepIndex = currentQuestionnaire.activeFormStepIndex;
  const allSteps = currentQuestionnaire.steps;
  /* const allSteps = currentQuestionnaire.steps.filter((step) => step.is_visible); */ // TO-DO: use this after is_visible field is added to the backend
  return activeFormStepIndex === allSteps.length - 1;
};

export const selectIsAllStepsCompleted = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[
      state.vendorManagementNext.currentQuestionnaireName
    ];
  const stepsCompletionStatus = currentQuestionnaire?.steps.map(
    (step) => step.is_completed
  );
  const allSteps = currentQuestionnaire?.steps;
  /* const allSteps = currentQuestionnaire?.steps.filter( // TO-DO: use this after is_visible field is added to the backend
    (step) => step.is_visible
  ); */
  return allSteps?.every((_: any, i: number) => stepsCompletionStatus[i]);
};

export const selectIsThereUnsavedChangesInFormStep = (state: RootState) => {
  return state.vendorManagementNext.isThereUnsavedChangesInFormStep;
};
export const selectIsShowGreetingPage = (state: RootState) => {
  return state.vendorManagementNext.isShowGreetingPage;
};
